import type { MediaContent } from '../block-content';
import { validateMediaContent } from '../block-content';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Left] as const;
const VALID_SIZE_STYLES = [
  SizeStyle.Small,
  SizeStyle.Default,
  SizeStyle.Large,
] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];
type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

export interface MediaBlockComponent extends BlockComponent {
  readonly type: BlockComponentTypeValue<'Media'>;
  readonly align: ValidAlignStyle;
  readonly size: ValidSizeStyle;

  readonly media: MediaContent;
}

export function validateMediaBlockComponent(
  param: unknown
): param is MediaBlockComponent {
  return (
    // is block component
    validateBlockComponent(param) &&
    param.type === BlockComponentType.Media &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle) &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has media
    'media' in param &&
    validateMediaContent(param.media)
  );
}
