import { Flex, PrimitiveButton, Tag, Typography } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { Translation } from '@flex-packages/i18n';
import { Link } from '@flex-packages/router';
import { RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT } from '@flex-private-packages/recruiting-common/base';
import { useJobDescriptionRangeString } from '@flex-private-packages/recruiting-common/hooks';

import type { PublicJobDescription } from '../../../../base';
import { StaticUrlPagePath } from '../../../../base';
import { parseToInternalRelativeUrl } from '../../logics';
import { getMobileStyle } from '../../utils';

interface Props {
  disabled?: boolean;
  jobDescription: PublicJobDescription;
}

const ANCHOR_HORIZONTAL_OFFSET = 20;

export default function JobDescriptionListItem({
  disabled,
  jobDescription,
}: Props) {
  const range = useJobDescriptionRangeString({ ...jobDescription });

  return (
    <JobDescriptionListItemRoot>
      <Link
        passHref
        href={
          disabled
            ? '#'
            : parseToInternalRelativeUrl({
                urlPagePath: StaticUrlPagePath.JobDescriptionList,
                jobDescriptionIdHash: jobDescription.jobDescriptionIdHash,
              })
        }
      >
        <PrimitiveButton
          css={{
            paddingTop: 22,
            paddingBottom: 26,
            width: `calc(100% + ${2 * ANCHOR_HORIZONTAL_OFFSET}px)`,
            borderRadius: 8,

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            clickable: {
              intensity: 'default',
              options: {
                horizontalOffset: ANCHOR_HORIZONTAL_OFFSET,
              },
            },
            pointerEvents: disabled ? 'none' : 'unset',

            ...getMobileStyle({
              borderRadius: 0,
            }),
          }}
        >
          <Content>
            <Title color="black" weight={700}>
              {jobDescription.title}
            </Title>
            <TagJobDescriptionListItemRoot direction="row" align="center">
              <StyledTag size="large" variant="outline">
                {jobDescription.contractType && (
                  <Translation
                    tKey={
                      RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT[
                        jobDescription.contractType
                      ].i18n
                    }
                  />
                )}
              </StyledTag>
              <StyledTag size="large" variant="outline">
                {range}
              </StyledTag>
            </TagJobDescriptionListItemRoot>
          </Content>
        </PrimitiveButton>
      </Link>
    </JobDescriptionListItemRoot>
  );
}

const JobDescriptionListItemRoot = styled('li', {
  borderBottom: '1px solid $blackAlpha010',

  '& > a': {
    width: '100%',
  },
});

const Title = styled(Typography, {
  fontSize: 20,
  lineHeight: 1.5,

  ...getMobileStyle({
    fontSize: 16,
  }),
});

const Content = styled('div', {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  ...getMobileStyle({
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  }),
});

const TagJobDescriptionListItemRoot = styled(Flex, {
  paddingLeft: 10,
  flexShrink: 0,
  gap: 10,
  ...getMobileStyle({
    marginTop: 8,
  }),
});

const StyledTag = styled(Tag, {
  // Tag 의 default 사이즈 스타일인데
  // variant 를 responsive 하게 가져갈 수단이 없어서 직접 정의
  ...getMobileStyle({
    fontSize: '11px',
    height: '19px',
    px: 8,
  }),
});
