import { v4 as uuidV4 } from 'uuid';

import { validateBlockComponent as validateBlockComponent_V1 } from '../../block-v1-legacy';
import type { BlockComponent } from '../../block-v2';
import {
  AlignStyle,
  BackgroundType,
  generateDefaultAboutBlockComponent,
  generateDefaultBannerBlockComponent,
  generateDefaultColumnsBlockComponent,
  generateDefaultCoverBlockComponent,
  generateDefaultCoverHalfBlockComponent,
  generateDefaultErrorBlock,
  generateDefaultGalleryBlockComponent,
  generateDefaultListVerticalBlockComponent,
  generateDefaultParagraphBlockComponent,
  generateDefaultQuoteBlockComponent,
  migrateBlockComponentV1,
  SizeStyle,
  validateBlockComponent,
} from '../../block-v2';

import type { PageContent, PageContentTypeValue } from './page-content';
import { PageContentType } from './page-content';

export interface BlockComponentV2PageContent extends PageContent {
  readonly type: PageContentTypeValue<'BlocComponentVersion2'>;
  readonly content: Array<BlockComponent> | null;
}

export function isBlockComponentV2PageContent(
  pageContent: PageContent
): pageContent is BlockComponentV2PageContent {
  return pageContent.type === PageContentType.BlocComponentVersion2;
}

export function parseBlockComponentV2PageContentString(
  str: string
): BlockComponentV2PageContent {
  const { type, content } = JSON.parse(str);

  if (type === PageContentType.BlocComponentVersion2) {
    return {
      type: PageContentType.BlocComponentVersion2,
      content: parseContentOfBlockComponentV2PageContent(content),
    };
  }
  if (type === PageContentType.BlocComponentVersion1) {
    return {
      type: PageContentType.BlocComponentVersion2,
      content: migrateBlockComponentContent(content),
    };
  }

  throw new Error(
    '[parseBlockComponentV1PageContentString] 잘못된 version 입니다.'
  );
}

export function parseContentOfBlockComponentV2PageContent(
  content: unknown
): BlockComponentV2PageContent['content'] {
  if (content === null) {
    return null;
  }
  if (!Array.isArray(content)) {
    throw new Error('[parseBlockComponentContent] 잘못된 content 입니다.');
  }

  const contents = content.map(block =>
    validateBlockComponent(block) ? block : generateDefaultErrorBlock()
  );

  return parseToUniqueIdBlockComponents(contents);
}

function migrateBlockComponentContent(
  content: unknown
): BlockComponentV2PageContent['content'] {
  if (!Array.isArray(content)) {
    throw new Error('[parseBlockComponentContent] 잘못된 content 입니다.');
  }

  const contents = content.map(block =>
    validateBlockComponent_V1(block)
      ? migrateBlockComponentV1(block)
      : generateDefaultErrorBlock()
  );

  return parseToUniqueIdBlockComponents(contents);
}

function parseToUniqueIdBlockComponents(
  blockComponents: Array<BlockComponent>
): Array<BlockComponent> {
  const idSet = new Set();

  return blockComponents.map(blockComponent => {
    const uniqueBlockComponent = idSet.has(blockComponent.id)
      ? { ...blockComponent, id: uuidV4() }
      : blockComponent;

    idSet.add(uniqueBlockComponent.id);
    return uniqueBlockComponent;
  });
}

export function generateEmptyBlockComponentV2PageContent(): BlockComponentV2PageContent {
  return {
    type: PageContentType.BlocComponentVersion2,
    content: null,
  };
}

export function generateDefaultBlockComponentV2PageContent(): BlockComponentV2PageContent {
  return {
    type: PageContentType.BlocComponentVersion2,
    content: [
      generateDefaultCoverBlockComponent({
        hasLink: false,
        backgroundType: BackgroundType.BlackOverlayImage,
        size: SizeStyle.Large,
        align: AlignStyle.Center,
      }),
      generateDefaultCoverBlockComponent({
        hasLink: false,
        size: SizeStyle.Default,
        align: AlignStyle.Center,
      }),
      generateDefaultCoverHalfBlockComponent({
        hasLink: false,
        size: SizeStyle.Default,
        align: AlignStyle.Right,
      }),
      generateDefaultAboutBlockComponent({
        backgroundType: BackgroundType.WhiteOverlayImage,
        align: AlignStyle.Center,
      }),
      generateDefaultGalleryBlockComponent({
        mediaCount: 3,
        size: SizeStyle.Default,
        gap: true,
      }),
      generateDefaultBannerBlockComponent({
        backgroundType: BackgroundType.Color,
        hasSubText: false,
        align: AlignStyle.Side,
      }),
      generateDefaultQuoteBlockComponent({
        itemCount: 1,
        align: AlignStyle.Center,
      }),
      generateDefaultColumnsBlockComponent({
        itemCount: 2,
        hasTitle: true,
        align: AlignStyle.Left,
      }),
      generateDefaultParagraphBlockComponent({
        hasSubText: false,
        size: SizeStyle.Default,
        align: AlignStyle.Left,
      }),
      generateDefaultListVerticalBlockComponent({
        hasMedia: true,
        align: AlignStyle.Left,
      }),
      generateDefaultGalleryBlockComponent({
        mediaCount: 1,
        size: SizeStyle.Large,
        gap: false,
      }),
      generateDefaultListVerticalBlockComponent({
        hasMedia: false,
        align: AlignStyle.Left,
      }),
      generateDefaultParagraphBlockComponent({
        hasSubText: true,
        size: SizeStyle.Default,
        align: AlignStyle.Center,
      }),
      generateDefaultCoverBlockComponent({
        hasLink: true,
        backgroundType: BackgroundType.BlackOverlayImage,
        size: SizeStyle.Default,
        align: AlignStyle.Center,
      }),
    ],
  };
}
