import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { PropsWithChildren } from 'react';
import { useEffect, useRef, useState } from 'react';

import type { ListVerticalBlockComponent } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';
import { MEDIA_CONTENT_CLASS_NAME } from '../../../block-content-field';

interface Props {}

export function ListVerticalBlockComponentLayout({
  children,
}: PropsWithChildren<Props>) {
  return (
    <ListVerticalBlockComponentLayoutRoot>
      {children}
    </ListVerticalBlockComponentLayoutRoot>
  );
}

interface ItemProps {
  align: ListVerticalBlockComponent['align'];
  title: JSX.Element;
  subText: JSX.Element;
  media: JSX.Element | null;
}

function ListVerticalBlockComponentLayoutItem({
  align,
  title,
  subText,
  media,
}: ItemProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [hasMedia, setHasMedia] = useState(media !== null);

  useEffect(() => {
    setHasMedia(
      Boolean(
        rootRef.current?.querySelectorAll(`.${MEDIA_CONTENT_CLASS_NAME}`).length
      )
    );
  }, [media]);

  return (
    <ListVerticalBlockComponentLayoutItemRoot
      ref={rootRef}
      alignStyle={align}
      hasMedia={hasMedia}
    >
      <TextContentWrapper
        hasMedia={hasMedia}
        direction="column"
        align="stretch"
      >
        {title}
        {subText}
      </TextContentWrapper>
      {hasMedia && (
        <MediaContentWrapper>
          <MediaContentAspectRatioContainer>
            <MediaContentAspectRatioInner>{media}</MediaContentAspectRatioInner>
          </MediaContentAspectRatioContainer>
        </MediaContentWrapper>
      )}
    </ListVerticalBlockComponentLayoutItemRoot>
  );
}

const TextContentWrapper = styled(Flex, {
  flex: 89,

  variants: {
    hasMedia: {
      true: {
        paddingTop: 24,
        gap: 16,
        ...getMobileStyle({
          paddingTop: 0,
          gap: 8,
        }),
      },
      false: {
        gap: 24,
        ...getMobileStyle({
          gap: 8,
        }),
      },
    },
  },
});

const MediaContentAspectRatioInner = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',

  borderRadius: 10,
  overflow: 'hidden',
});

const MediaContentAspectRatioContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '87.5%',
});

const MediaContentWrapper = styled('div', {
  flex: 45,
});

const ListVerticalBlockComponentLayoutItemRoot = styled(Flex, {
  '&&': {
    gap: 48,
    alignItems: 'start',
  },

  ...getMobileStyle({
    '&&&': {
      gap: 20,
      alignItems: 'stretch',
    },
  }),

  variants: {
    alignStyle: {
      [AlignStyle.Left]: {
        '&&&': {
          flexDirection: 'row-reverse',
          ...getMobileStyle({
            flexDirection: 'column-reverse',
          }),
        },
      },
      [AlignStyle.Right]: {
        '&&&': {
          flexDirection: 'row',
          ...getMobileStyle({
            flexDirection: 'column',
          }),
        },
      },
    },
    hasMedia: {
      true: {
        '&:not(:first-child)': {
          marginTop: 50,
        },
      },
      false: {
        '&:not(:first-child)': {
          marginTop: 60,
        },
      },
    },
  },
});

const ListVerticalBlockComponentLayoutRoot = styled('div', {
  py: 60,
  ...getMobileStyle({
    py: 20,
  }),
});

ListVerticalBlockComponentLayout.Item = ListVerticalBlockComponentLayoutItem;
