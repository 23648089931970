import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import type { BannerBlockComponent } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

interface Props {
  align: BannerBlockComponent['align'];
  title: JSX.Element;
  subText: JSX.Element | null;
  link: JSX.Element;
}

export function BannerBlockComponentLayout({
  align,
  title,
  subText,
  link,
}: Props) {
  return (
    <BannerBlockComponentLayoutRoot alignStyle={align}>
      <TitleContentWrapper align="stretch" direction="column">
        {title}
        {subText}
      </TitleContentWrapper>
      {link}
    </BannerBlockComponentLayoutRoot>
  );
}

const TitleContentWrapper = styled(Flex, {
  width: '100%',
  gap: 8,
  ...getMobileStyle({
    gap: 12,
  }),
});

const BannerBlockComponentLayoutRoot = styled('div', {
  display: 'flex',
  flexWrap: 'nowrap',

  variants: {
    alignStyle: {
      [AlignStyle.Center]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 24,
        py: 60,
        ...getMobileStyle({
          alignItems: 'stretch',
          gap: 50,
        }),
      },
      [AlignStyle.Side]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 24,
        py: 80,
        ...getMobileStyle({
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'stretch',
          gap: 50,
          py: 60,
        }),
      },
    },
  },
});
