import { CandidateDocumentFileType } from '@flex-apis/recruiting';
import type { SegmentedControlRootProps } from '@flex-design-system/fx';
import { SegmentedControl } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';

import { ATTACHMENT_TYPE_LOKALISE_KEY } from './constants/attachment-type';

function assertExhaustive(value: never): never {
  throw new Error(`Unhandled value: ${value}`);
}

function assertCandidateDocumentFileType(
  value: CandidateDocumentFileType
): value is CandidateDocumentFileType {
  switch (value) {
    case CandidateDocumentFileType.File:
    case CandidateDocumentFileType.Url:
      return true;
    default:
      assertExhaustive(value);
  }
}

interface Props
  extends Omit<SegmentedControlRootProps, 'value' | 'onValueChange' | 'size'> {
  value: CandidateDocumentFileType;
  onValueChange: (value: CandidateDocumentFileType) => void;
}

function DocumentTypeSegmentControl({
  value,
  onValueChange,

  ...props
}: Props) {
  const onChangeSegmentControlValue = (type: CandidateDocumentFileType) => {
    assertCandidateDocumentFileType(type);
    onValueChange(type);
  };

  return (
    <SegmentedControl
      {...props}
      size="small"
      value={value}
      onValueChange={onChangeSegmentControlValue}
    >
      <SegmentedControl.Item value={CandidateDocumentFileType.File}>
        <Translation
          tKey={ATTACHMENT_TYPE_LOKALISE_KEY[CandidateDocumentFileType.File]}
        />
      </SegmentedControl.Item>
      <SegmentedControl.Item value={CandidateDocumentFileType.Url}>
        <Translation
          tKey={ATTACHMENT_TYPE_LOKALISE_KEY[CandidateDocumentFileType.Url]}
        />
      </SegmentedControl.Item>
    </SegmentedControl>
  );
}

export default DocumentTypeSegmentControl;
