import type { MediaContent, TextContent } from '../block-content';
import { validateMediaContent, validateTextContent } from '../block-content';

import type { BlockComponent } from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_TYPES = [
  BlockComponentType.MediaTextMultiple,
  BlockComponentType.MediaTextTriple,
] as const;
const VALID_ALIGN_STYLES = [AlignStyle.Left] as const;
const VALID_SIZE_STYLES = [SizeStyle.Default] as const;

type ValidTypes = (typeof VALID_TYPES)[number];
type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];
type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

export interface MediaTextMultiBlockComponent extends BlockComponent {
  readonly type: ValidTypes;
  readonly align: ValidAlignStyle;
  readonly size: ValidSizeStyle;

  readonly contents: MediaTextMultiBlockContent[]; // 2 ~ 3개
}

export interface MediaTextMultiBlockContent {
  readonly id: string;
  readonly media: MediaContent;
  readonly title: TextContent;
  readonly subText: TextContent;
}

export function validateMediaTextMultiBlockComponent(
  param: unknown
): param is MediaTextMultiBlockComponent {
  return (
    // is block component
    validateBlockComponent(param) &&
    VALID_TYPES.includes(param.type as ValidTypes) &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle) &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has contents
    'contents' in param &&
    Array.isArray(param.contents) &&
    param.contents.length >= 2 &&
    param.contents.length <= 3 &&
    param.contents.every(validateMediaTextMultiBlockContent)
  );
}

export function validateMediaTextMultiBlockContent(
  param: unknown
): param is MediaTextMultiBlockContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has media
    'media' in param &&
    validateMediaContent(param.media) &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText)
  );
}
