import { Button, Typography } from '@flex-design-system/fx';
import { ArrowBackwardIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { Flex, Stack } from '@flex-design-system/utils-style';
import { StaticImage } from '@flex-packages/static-resource';
import { isNil } from '@flex-packages/utils';
import { FlexLogo } from '@flex-services/shared-ui/assets';
import type { PropsWithChildren } from 'react';

interface Props {
  onClickBackButton?: () => void;
}

function ErrorPageLayoutImp({
  children,
  onClickBackButton,
}: PropsWithChildren<Props>) {
  return (
    <PageWrapper align="stretch">
      <Main>
        {!isNil(onClickBackButton) && (
          <MainHeader>
            <Button
              variant="ghost"
              onClick={onClickBackButton}
              leftSlot={<ArrowBackwardIcon />}
              color="gray"
              size="large"
            />
          </MainHeader>
        )}
        <MainWrapper space={12} align="stretch" justify="center">
          {children}
        </MainWrapper>
      </Main>
      <Right>
        <Illust src="/v2/error/illust-error.svg" alt="" />
      </Right>
      <Logo size="small" color="grayLighter" />
    </PageWrapper>
  );
}

const MainWrapper = styled(Stack.Vertical, {
  margin: 'auto 0',
  width: '360px',
});

const PageWrapper = styled(Stack.Horizontal, {
  width: '100%',
  height: '100vh',

  backgroundColor: '$white',

  '> *': {
    height: '100%',
    width: '50%',
    flex: '1 1 50%',
  },
});

const Main = styled('main', {
  padding: '32px',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const MainHeader = styled('div', {
  width: '100%',

  display: 'flex',
  alignItems: 'center',
});

const Right = styled(Flex.Center, {
  height: '100%',
  backgroundColor: '#fcfaf7',
});

const Illust = styled(StaticImage, {
  width: 300,
});

const Logo = styled(FlexLogo, {
  position: 'fixed',
  bottom: 32,
  right: 32,
});

const Title = styled(Typography, {
  color: '$grayDarker',
  fontSize: 16,
  lineHeight: 1.4,
  paddingBottom: 12,
});

const Description = styled(Typography, {
  color: '$gray',
  fontSize: 13,
  lineHeight: 1.4,
  paddingBottom: 12,
});

export const ErrorPageLayout = Object.assign(ErrorPageLayoutImp, {
  Title,
  Description,
});
