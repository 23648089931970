import { isNil, values } from '@flex-packages/utils';

import type { BlockContentTypeValue as BlockContentTypeValue_V1 } from '../../block-v1-legacy';
import { BlockContentType as BlockContentType_V1 } from '../../block-v1-legacy';

export const BlockContentType = {
  Text: 'TEXT',
  Media: 'MEDIA',
  Link: 'LINK',
  Background: 'BACKGROUND',
} as const;

export type BlockContentTypeKey = keyof typeof BlockContentType;
export type BlockContentTypeValue<
  Key extends BlockContentTypeKey = BlockContentTypeKey,
> = (typeof BlockContentType)[Key];

export function migrateBlockContentTypeV1(
  blockContentType: BlockContentTypeValue_V1
): BlockContentTypeValue {
  switch (blockContentType) {
    case BlockContentType_V1.Text:
      return BlockContentType.Text;
    case BlockContentType_V1.Media:
      return BlockContentType.Media;
    case BlockContentType_V1.Link:
      return BlockContentType.Link;
  }
}

export interface BlockContent {
  readonly contentType: BlockContentTypeValue;
}

export function validateBlockContent(param: unknown): param is BlockContent {
  return (
    // is object
    typeof param === 'object' &&
    !isNil(param) &&
    // has content type
    'contentType' in param &&
    values(BlockContentType).includes(
      param.contentType as BlockContentTypeValue
    )
  );
}
