import type { ListVerticalBlockComponent } from '../../../../../../base';
import {
  MediaContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { ListVerticalBlockComponentLayout } from './ListVerticalBlockComponentLayout';
import { getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: ListVerticalBlockComponent;
}

export function ListVerticalBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <ListVerticalBlockComponentLayout>
          {model.items.map(item => (
            <ListVerticalBlockComponentLayout.Item
              key={item.id}
              align={model.align}
              title={
                <TextContentViewer
                  content={item.title}
                  {...getTitleTextStyle({ align: model.align, item })}
                />
              }
              subText={
                <TextContentViewer
                  content={item.subText}
                  {...getSubTextStyle({ align: model.align, item })}
                />
              }
              media={item.media && <MediaContentViewer content={item.media} />}
            />
          ))}
        </ListVerticalBlockComponentLayout>
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
