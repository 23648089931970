import { EducationExperienceInstitutionLevel } from '@flex-apis/recruiting';
import { Button, Legacy_Select } from '@flex-design-system/fx';
import type { Legacy_SelectProps } from '@flex-design-system/fx';
import { ChevronDownMiniIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation } from '@flex-packages/i18n';

import { EDUCATIONAL_BACKGROUND_INSTITUTION_TYPE_CONTENT } from '../../../base';

type EducationInstitutionTypeSelectProps = Omit<
  Legacy_SelectProps<EducationExperienceInstitutionLevel>,
  'children'
>;

const EDUCATION_INSTITUTION_TYPES: Array<EducationExperienceInstitutionLevel> =
  [
    EducationExperienceInstitutionLevel.BachelorCollege,
    EducationExperienceInstitutionLevel.BachelorUniversity,
    EducationExperienceInstitutionLevel.HighSchool,
    EducationExperienceInstitutionLevel.MasterUniversity,
    EducationExperienceInstitutionLevel.PhdUniversity,
    EducationExperienceInstitutionLevel.Unknown,
  ];

function EducationInstitutionTypeSelect(
  props: EducationInstitutionTypeSelectProps
) {
  return (
    <Legacy_Select<EducationExperienceInstitutionLevel> {...props}>
      <Legacy_Select.Trigger asChild>
        <Button
          type="button"
          variant="outline"
          size="xsmall"
          color="gray"
          rightSlot={<ChevronDownMiniIcon size={16} color="grayLight" />}
        >
          {props.value !== undefined ? (
            <Translation
              tKey={
                EDUCATIONAL_BACKGROUND_INSTITUTION_TYPE_CONTENT[props.value]
                  .i18n
              }
            />
          ) : (
            <Translation tKey="recruiting.background.status.placeholder" />
          )}
        </Button>
      </Legacy_Select.Trigger>
      <Legacy_Select.Content>
        {EDUCATION_INSTITUTION_TYPES.map(type => (
          <Legacy_Select.Option key={type} value={type}>
            <Legacy_Select.OptionTitle>
              <Translation
                tKey={
                  EDUCATIONAL_BACKGROUND_INSTITUTION_TYPE_CONTENT[type].i18n
                }
              />
            </Legacy_Select.OptionTitle>
          </Legacy_Select.Option>
        ))}
      </Legacy_Select.Content>
    </Legacy_Select>
  );
}

export default EducationInstitutionTypeSelect;
