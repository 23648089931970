import { EducationExperienceGraduateType } from '@flex-apis/recruiting';
import {
  ClickPopover,
  DatePicker,
  FieldBox,
  FormField,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  TodayIcon,
} from '@flex-design-system/legacy-flex-icons';
import { isBeforeDay } from '@flex-packages/date';
import { TimeV2 } from '@flex-packages/datetime-react/components';
import { Translation, useLanguage, useTranslation } from '@flex-packages/i18n';
import { isNonNil } from '@flex-packages/utils';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

const GraduatedTypes: Array<Partial<EducationExperienceGraduateType>> = [
  EducationExperienceGraduateType.Graduated,
  EducationExperienceGraduateType.PlanToGraduate,
];

export function EducationGraduationYearMonthInputFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();
  const { getValues } = useFormContext<JobDescriptionFormState>();
  const locale = useLanguage();

  const { educationalBackgrounds = [] } = getValues();

  const graduationStatus =
    educationalBackgrounds[currentIndex]?.graduationStatus;
  const requiredGraduationDate =
    graduationStatus && GraduatedTypes.includes(graduationStatus);

  return (
    <Controller<
      JobDescriptionFormState,
      `educationalBackgrounds.${typeof currentIndex}.graduationDate`
    >
      name={`educationalBackgrounds.${currentIndex}.graduationDate`}
      rules={{
        required:
          requiredGraduationDate &&
          t(
            'recruiting.educational_background.graduation_date.edit.helper.required'
          ),
        validate: graduationDate => {
          const admissionDate =
            educationalBackgrounds[currentIndex]!.admissionDate;

          if (
            isNonNil(graduationDate) &&
            isNonNil(admissionDate) &&
            isBeforeDay(graduationDate, admissionDate)
          ) {
            return t(
              'recruiting.educational_background.graduation_date.edit.helper.validate'
            );
          }
        },
      }}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => {
        const graduationDate = value ?? null;

        return (
          <FormField
            required={requiredGraduationDate}
            state={error ? 'error' : 'default'}
            {...field}
          >
            <FormField.Label leftSlot={<TodayIcon />}>
              <Translation tKey="recruiting.educational_background.graduation_date" />
            </FormField.Label>
            <DatePicker
              mode="single"
              selectedDate={graduationDate}
              onDateChange={onChange}
            >
              <ClickPopover>
                <ClickPopover.Trigger asChild>
                  <FormField.FieldBox asChild>
                    <PrimitiveButton css={{ width: '100%' }}>
                      <FieldBox.Content>
                        {graduationDate !== null ? (
                          <TimeV2
                            date={graduationDate}
                            presetName="year_month"
                            locale={locale}
                          />
                        ) : (
                          <Typography color="grayLight" asChild>
                            <Translation tKey="recruiting.educational_background.graduation_date.edit.helper.placeholder" />
                          </Typography>
                        )}
                      </FieldBox.Content>
                      <FieldBox.RightSlotRoot>
                        <ChevronDownMiniIcon size={22} color="grayLight" />
                      </FieldBox.RightSlotRoot>
                    </PrimitiveButton>
                  </FormField.FieldBox>
                </ClickPopover.Trigger>
                <ClickPopover.Content_Legacy>
                  <DatePicker.Content css={{ paddingTop: '6px' }}>
                    <DatePicker.MonthInput />
                    <DatePicker.MonthController />
                    <DatePicker.MonthCalendar />
                  </DatePicker.Content>
                </ClickPopover.Content_Legacy>
              </ClickPopover>
            </DatePicker>
            {error?.message && (
              <FormField.HelperText>{error.message}</FormField.HelperText>
            )}
          </FormField>
        );
      }}
    />
  );
}
