import type { SiteDesignLinkResponse } from '@flex-apis/recruiting';

export interface SiteDesignLink {
  readonly title: string;
  readonly href: string;
}

export function generateSiteDesignLink(params: {
  siteDesignLinkResponse: SiteDesignLinkResponse;
}): SiteDesignLink {
  const { siteDesignLinkResponse } = params;

  return {
    title: siteDesignLinkResponse.title,
    href: siteDesignLinkResponse.href,
  };
}

export function generateDefaultSiteDesignLink() {
  return { title: '', href: '' };
}

export function validateSiteDesignLink(
  siteDesignLink: SiteDesignLink
): boolean {
  return Boolean(siteDesignLink.title && siteDesignLink.href);
}
