import {
  Button,
  Divider,
  Flex,
  PrimitiveAnchor,
  useToast,
} from '@flex-design-system/fx';
import {
  ArrowBackwardIcon,
  ConnectIcon,
  SendIcon,
} from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { Translation } from '@flex-packages/i18n';
import { Link } from '@flex-packages/router';
import { copyToClipBoard } from '@flex-packages/utils';
import type { ReactNode } from 'react';
import { lazy } from 'react';

import type { PublicJobDescriptionDetail } from '../../../../base';
import { StaticUrlPagePath } from '../../../../base';
import {
  makeJDUrl,
  parseToInternalRelativeUrl,
  validateSubmitApply,
} from '../../logics';
import { getMobileStyle } from '../../utils';

interface Props {
  jobDescription: PublicJobDescriptionDetail;
  now: number;
  onMoveToApply: VoidFunction;
}

/**
 * SSR시 window 참조 에러가 발생하므로, 동적으로 import 하도록 변경
 * next/dynamic 를 쓰면 suspense 를 상위로 전파할 수 없어서 react lazy 사용
 * 왜 전파? FlexicalEditor 가 보여지기 전에, Footer 등이 먼저 보여지면 화면 전환이 깜빡거림
 */
const FlexicalEditor = lazy(async () => {
  const module = await import('./FlexicalEditor');
  return { default: module.FlexicalEditor };
});

export function JobDescriptionContent({
  jobDescription,
  now,
  onMoveToApply,
}: Props) {
  const toast = useToast();

  const copyJobDescriptionListPageLink = async () => {
    await copyToClipBoard(
      makeJDUrl({
        origin: window.origin,
        jobDescription,
      })
    );
    toast.success(
      <Translation tKey="recruiting.site_builder.job_description.link.copy.success" />
    );
  };

  const submitApplyValidation = validateSubmitApply(jobDescription, now);
  const applyButtonContent = ((): {
    icon: ReactNode;
    disabled: boolean;
    text: ReactNode;
  } => {
    if (submitApplyValidation.valid) {
      return {
        icon: <SendIcon />,
        disabled: false,
        text: (
          <Translation tKey="recruiting.site_builder.job_description.apply" />
        ),
      };
    }

    switch (submitApplyValidation.reason) {
      case 'early':
        return {
          icon: null,
          disabled: true,
          text: (
            <Translation tKey="recruiting.site_builder.job_description.apply.disable.early" />
          ),
        };
      case 'late':
        return {
          icon: null,
          disabled: true,
          text: (
            <Translation tKey="recruiting.site_builder.job_description.apply.disabled.late" />
          ),
        };
    }
  })();

  // TODO: 스키마에 따른 분기가 필요해지면, jobDescription.content.schema 값에 따라 분기 처리
  return (
    <Wrapper direction="column" align="stretch">
      <FlexicalEditor initialState={jobDescription.content.data} />
      <Flex direction="column" align="stretch" css={{ gap: 36 }}>
        <Buttons>
          <Button
            css={{ flex: '0 0 auto' }}
            variant="outline"
            size="xlarge"
            color="gray"
            leftSlot={<ConnectIcon />}
            onClick={copyJobDescriptionListPageLink}
          >
            <Translation tKey="recruiting.site_builder.job_description.link.share" />
          </Button>
          <Button
            css={{ flex: '1 1 auto' }}
            variant="solid"
            size="xlarge"
            color="grayDarker"
            leftSlot={applyButtonContent.icon}
            onClick={onMoveToApply}
            disabled={applyButtonContent.disabled}
          >
            {applyButtonContent.text}
          </Button>
        </Buttons>
        <PCOnlyDivider />
        <Link
          style={{ alignSelf: 'start', marginLeft: -14 }}
          href={parseToInternalRelativeUrl({
            urlPagePath: StaticUrlPagePath.JobDescriptionList,
          })}
          passHref
        >
          <Button variant="ghost" leftSlot={<ArrowBackwardIcon />} color="gray">
            <Translation tKey="recruiting.site_builder.job_description.back" />
          </Button>
        </Link>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(Flex, {
  paddingTop: 60,
  paddingBottom: 100,
  ...getMobileStyle({
    paddingTop: 40,
    paddingBottom: 60,
  }),

  gap: 60,
});

const Buttons = styled('div', {
  display: 'flex',
  alignItems: 'stretch',

  '& > *:not(:first-child)': {
    marginLeft: 12,
  },

  ...getMobileStyle({
    flexDirection: 'column-reverse',

    '& > *:not(:first-child)': {
      marginLeft: 0,
      marginBottom: 10,
    },
  }),
});

const PCOnlyDivider = styled(Divider, {
  ...getMobileStyle({ display: 'none' }),
});
