import type {
  MediaContent as MediaContent_V1,
  MediaTypeValue as MediaTypeValue_V1,
} from '../../block-v1-legacy';
import { MediaType as MediaType_V1 } from '../../block-v1-legacy';
import { getRandomImageUrl } from '../logics';

import type { BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export const MediaType = {
  None: 'NONE',
  Image: 'IMAGE',
  Video: 'VIDEO',
} as const;

export type MediaTypeKey = keyof typeof MediaType;
export type MediaTypeValue<Key extends MediaTypeKey = MediaTypeKey> =
  (typeof MediaType)[Key];

export function migrateMediaTypeV1(
  mediaType: MediaTypeValue_V1
): MediaTypeValue {
  switch (mediaType) {
    case MediaType_V1.Image:
      return MediaType.Image;
    case MediaType_V1.Video:
      return MediaType.Video;
    case MediaType_V1.None:
      return MediaType.None;
  }
}

export interface MediaContent {
  readonly contentType: BlockContentTypeValue<'Media'>;

  readonly mediaType: MediaTypeValue;
  readonly src: string;
  readonly alt?: string;
}

export function generateDefaultMediaContent(): MediaContent {
  return {
    contentType: BlockContentType.Media,
    mediaType: MediaType.Image,
    src: getRandomImageUrl(),
  };
}

export function migrateMediaContentV1(
  mediaContent: MediaContent_V1
): MediaContent {
  return {
    contentType: BlockContentType.Media,
    mediaType: migrateMediaTypeV1(mediaContent.mediaType),
    src: mediaContent.src,
    alt: mediaContent.alt,
  };
}

export function validateMediaContent(param: unknown): param is MediaContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Media &&
    // has media type
    'mediaType' in param &&
    Object.values(MediaType).includes(param.mediaType as MediaTypeValue) &&
    // has src
    'src' in param &&
    typeof param.src === 'string'
  );
}
