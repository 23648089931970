import type {
  CandidateHandicapCase,
  CandidateMilitaryServiceStatus,
} from '@flex-apis/recruiting';
import { CandidateDocumentFileType } from '@flex-apis/recruiting';
import type {
  CandidateDocumentFieldModel,
  CandidateEducationalBackground,
  CandidateEducationalBackgroundJSON,
  CandidateWorkExperienceBackground,
  CandidateWorkExperienceBackgroundJSON,
} from '@flex-private-packages/recruiting-common/base';
import {
  generateCandidateDocumentFieldModel,
  parseCandidateEducationalBackgroundToJSON,
  parseCandidateWorkExperienceBackgroundToJSON,
  parseJSONToCandidateEducationalBackground,
  parseJSONToCandidateWorkExperienceBackground,
  validateLinkTypeAttachmentUrl,
} from '@flex-private-packages/recruiting-common/base';

import type { PublicJobDescriptionDetail } from '../../../../../base';

export interface JobDescriptionFormState {
  name: string;
  email: string;
  phoneNumber: string;
  applicationPath: string;
  documents: CandidateDocumentFieldModel[];
  militaryServiceStatus?: CandidateMilitaryServiceStatus;
  hasVeteransCompensation?: string;
  handicapCase?: CandidateHandicapCase;
  preQuestionAnswers?: string[];
  educationalBackgrounds?: CandidateEducationalBackground[];
  noEducationalBackgrounds: boolean;
  workExperienceBackgrounds?: CandidateWorkExperienceBackground[];
  noWorkExperience: boolean;
  agreedToTerms: boolean;
  agreedToSensitiveInformationTerms: boolean;
}

export function generateDefaultJobDescriptionFormState(
  jobDescriptionPublic: PublicJobDescriptionDetail
): Partial<JobDescriptionFormState> {
  return {
    documents: jobDescriptionPublic.documentList.map(document =>
      generateCandidateDocumentFieldModel({
        attachmentDocumentIdHash: document.idHash,
        requiredDocumentType: document.type,
        title: document.title,
        isRequired: document.isRequired,
      })
    ),
  };
}

export function validateDocumentsField(
  document: JobDescriptionFormState['documents'][number]
): boolean {
  switch (document.registeredType) {
    case CandidateDocumentFileType.File:
      if (!document.isRequired) {
        return true;
      }

      return Boolean(
        document.file &&
          document.file.fileKey &&
          document.file.status === 'idle'
      );
    case CandidateDocumentFileType.Url:
      if (
        !document.isRequired &&
        (document.url === undefined || document.url === '')
      ) {
        return true;
      }

      return Boolean(
        document.url && validateLinkTypeAttachmentUrl(document.url)
      );
    default:
      return false;
  }
}

export interface JobDescriptionFormStateJSON
  extends Omit<
    JobDescriptionFormState,
    'documents' | 'educationalBackgrounds' | 'workExperienceBackgrounds'
  > {
  documents: [];
  educationalBackgrounds?: CandidateEducationalBackgroundJSON[];
  workExperienceBackgrounds?: CandidateWorkExperienceBackgroundJSON[];
}

export function parseJobDescriptionFormStateToJSON(
  form: Partial<JobDescriptionFormState>
): Partial<JobDescriptionFormStateJSON> {
  return {
    ...form,
    documents: [],
    educationalBackgrounds: form.educationalBackgrounds?.map(
      parseCandidateEducationalBackgroundToJSON
    ),
    workExperienceBackgrounds: form.workExperienceBackgrounds?.map(
      workExperienceBackground =>
        parseCandidateWorkExperienceBackgroundToJSON(workExperienceBackground)
    ),
  };
}

export function parseJSONToJobDescriptionFormState(
  json: Partial<JobDescriptionFormStateJSON>
): Partial<JobDescriptionFormState> {
  return {
    ...json,
    documents: [],
    educationalBackgrounds: json.educationalBackgrounds?.map(
      parseJSONToCandidateEducationalBackground
    ),
    workExperienceBackgrounds: json.workExperienceBackgrounds?.map(
      parseJSONToCandidateWorkExperienceBackground
    ),
  };
}
