import { WorkExperienceContractType } from '@flex-apis/recruiting';
import {
  FieldBox,
  FormField,
  Legacy_Select,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  SignatureIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { WORK_EXPERIENCE_CONTRACT_TYPE_CONTENT } from '@flex-private-packages/recruiting-common/base';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

const WORK_EXPERIENCE_STATUS_TYPES: Array<WorkExperienceContractType> = [
  WorkExperienceContractType.Regular,
  WorkExperienceContractType.Contract,
  WorkExperienceContractType.Freelancer,
  WorkExperienceContractType.ShortTerm,
  WorkExperienceContractType.Intern,
  WorkExperienceContractType.Dispatch,
];

export function WorkExperienceContractTypeSelectFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();

  const [triggerRef, setTriggerRef] = useState<HTMLButtonElement | null>(null);

  return (
    <Controller<
      JobDescriptionFormState,
      `workExperienceBackgrounds.${number}.contractType`
    >
      name={`workExperienceBackgrounds.${currentIndex}.contractType`}
      rules={{
        required: t(
          'recruiting.work_experience.contract_status.edit.placeholder'
        ),
      }}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => (
        <FormField required state={error ? 'error' : 'default'}>
          <FormField.Label_Legacy leftSlot={<SignatureIcon />}>
            <Translation tKey="recruiting.work_experience.contract_status" />
          </FormField.Label_Legacy>
          <Legacy_Select<WorkExperienceContractType>
            value={value}
            onValueChange={onChange}
            {...field}
          >
            <Legacy_Select.Trigger ref={setTriggerRef} asChild>
              <FormField.FieldBox asChild>
                <PrimitiveButton css={{ width: '100%' }}>
                  <FieldBox.Content>
                    {value ? (
                      <Translation
                        tKey={WORK_EXPERIENCE_CONTRACT_TYPE_CONTENT[value].i18n}
                      />
                    ) : (
                      <Typography color="gray" asChild>
                        <Translation tKey="recruiting.work_experience.contract_status.edit.placeholder" />
                      </Typography>
                    )}
                  </FieldBox.Content>
                  <FieldBox.RightSlotRoot>
                    <ChevronDownMiniIcon size={22} color="grayLight" />
                  </FieldBox.RightSlotRoot>
                </PrimitiveButton>
              </FormField.FieldBox>
            </Legacy_Select.Trigger>
            <Legacy_Select.Content css={{ width: triggerRef?.offsetWidth }}>
              {WORK_EXPERIENCE_STATUS_TYPES.map(statusType => (
                <Legacy_Select.Option key={statusType} value={statusType}>
                  <Legacy_Select.OptionTitle>
                    <Translation
                      tKey={
                        WORK_EXPERIENCE_CONTRACT_TYPE_CONTENT[statusType].i18n
                      }
                    />
                  </Legacy_Select.OptionTitle>
                </Legacy_Select.Option>
              ))}
            </Legacy_Select.Content>
          </Legacy_Select>
          {error?.message && (
            <FormField.HelperText>{error.message}</FormField.HelperText>
          )}
        </FormField>
      )}
    />
  );
}
