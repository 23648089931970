import { SiteDesignPageLinkType } from '@flex-apis/recruiting';
import {
  Flex,
  IconButton,
  Primitive,
  PrimitiveAnchor,
  Typography,
} from '@flex-design-system/fx';
import { OverflowTextTooltip } from '@flex-design-system/fx-extension';
import { ListIcon, XmarkIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { Link } from '@flex-packages/router';
import { createScopedContext } from '@flex-packages/shared-react';
import type { Nullable } from '@flex-packages/types';
import type { ComponentProps, ReactNode } from 'react';
import { forwardRef } from 'react';

import {
  CONTENT_HORIZONTAL_PADDING,
  CONTENT_HORIZONTAL_PADDING_MOBILE,
  CONTENT_MAX_WIDTH,
  PAGE_HEADER_HEIGHT,
  PAGE_HEADER_Z_INDEX,
} from '../../../../configs/style';
import { getMobileStyle } from '../../../../utils';

const LOGO_MAX_WIDTH = 160;

interface PageLayoutHeaderContext {
  isExpanded: boolean;
  onExpandChange?: (isExpand: boolean) => void;
}

const PageLayoutHeaderContext = createScopedContext<PageLayoutHeaderContext>({
  rootComponentName: 'PageLayoutHeader',
  displayName: 'PageLayoutHeaderContext',
});

export interface Props
  extends Omit<
      ComponentProps<typeof PageLayoutHeaderRoot>,
      'as' | 'asChild' | 'isExpanded'
    >,
    PageLayoutHeaderContext {}

const PageLayoutHeaderImpl = forwardRef<HTMLDivElement, Props>(
  ({ isExpanded, onExpandChange, children, ...rootProps }, ref) => {
    return (
      <PageLayoutHeaderRoot
        {...rootProps}
        ref={ref}
        as="header"
        isExpanded={isExpanded}
      >
        <PageLayoutHeaderContext.Provider
          value={{ isExpanded, onExpandChange }}
        >
          {children}
        </PageLayoutHeaderContext.Provider>
      </PageLayoutHeaderRoot>
    );
  }
);

export interface ContentProps
  extends Omit<
    ComponentProps<typeof PageLayoutHeaderContentRoot>,
    'title' | 'isExpanded'
  > {
  navigation: ReactNode;
}

function PageLayoutHeaderContent({
  navigation,
  children,
  ...rootProps
}: ContentProps) {
  const { isExpanded, onExpandChange } =
    PageLayoutHeaderContext.useContextValue();

  return (
    <PageLayoutHeaderContentRoot {...rootProps}>
      <PageLayoutHeaderContentTitleWrapper
        direction="row"
        align="center"
        justify="between"
        isExpanded={isExpanded}
      >
        {children}
        <MobileExpandButton
          onClick={() => onExpandChange?.(!isExpanded)}
          variant="ghost"
          icon={isExpanded ? <XmarkIcon /> : <ListIcon />}
        />
      </PageLayoutHeaderContentTitleWrapper>
      {navigation}
    </PageLayoutHeaderContentRoot>
  );
}

export interface PageLayoutHeaderTitleProps
  extends ComponentProps<typeof PageLayoutHeaderTitleRoot> {
  logoImageUrl?: string;
}

function PageLayoutHeaderTitle({
  logoImageUrl,
  children,
  ...rootProps
}: PageLayoutHeaderTitleProps) {
  return (
    <PageLayoutHeaderTitleRoot direction="row" align="center" {...rootProps}>
      {logoImageUrl && <LogoImg src={logoImageUrl} />}
      <OverflowTextTooltip tolerance={2}>
        <OverflowTextTooltip.Trigger asChild>
          <OverflowTextTooltip.Text
            size={22}
            weight={700}
            lineHeight="24px"
            color="grayDark"
            style={{ letterSpacing: '-4%' }}
          >
            {children}
          </OverflowTextTooltip.Text>
        </OverflowTextTooltip.Trigger>
        <OverflowTextTooltip.Content />
      </OverflowTextTooltip>
    </PageLayoutHeaderTitleRoot>
  );
}

function PageLayoutHeaderNavigation({
  children,
  ...rootProps
}: Omit<
  ComponentProps<typeof PageLayoutHeaderNavigationRoot>,
  'as' | 'asChild'
>) {
  const { isExpanded } = PageLayoutHeaderContext.useContextValue();

  return (
    <PageLayoutHeaderNavigationRoot {...rootProps} as="nav">
      <PageLayoutHeaderNavigationWrapper as="ul" isExpanded={isExpanded}>
        {children}
      </PageLayoutHeaderNavigationWrapper>
    </PageLayoutHeaderNavigationRoot>
  );
}

export interface NavigationItemProps
  extends ComponentProps<typeof PageLayoutHeaderMenuRoot> {
  siteDesignPageLinkType?: SiteDesignPageLinkType;
  link?: Nullable<string>;
}

function PageLayoutHeaderMenu({
  siteDesignPageLinkType,
  link,
  children,
  ...rootProps
}: NavigationItemProps): JSX.Element {
  const { onExpandChange } = PageLayoutHeaderContext.useContextValue();

  if (!link || !siteDesignPageLinkType) {
    return (
      <Typography ellipsis asChild>
        <PageLayoutHeaderMenuRoot {...rootProps}>
          {children}
        </PageLayoutHeaderMenuRoot>
      </Typography>
    );
  }

  switch (siteDesignPageLinkType) {
    case SiteDesignPageLinkType.Internal: {
      return (
        <PageLayoutHeaderMenuRoot {...rootProps}>
          <Typography ellipsis asChild>
            <Link href={link} onClick={() => onExpandChange?.(false)}>
              {children}
            </Link>
          </Typography>
        </PageLayoutHeaderMenuRoot>
      );
    }
    case SiteDesignPageLinkType.External: {
      /**
       * Https로 시작하지 않은 것을 의도했을 수도 있지만, 오히려 잠재적인 보안공격을 막을 수 있음.
       * 이것은 site 설정 페이지의 link 입력 단계에서 cleansing을 잘 해주는 방식을 고민중
       * https://flex-cv82520.slack.com/archives/C01SEAZV737/p1691664922213879?thread_ts=1691651118.889059&cid=C01SEAZV737
       */
      const startWithHttp =
        link.startsWith('https://') || link.startsWith('http://');

      return (
        <PageLayoutHeaderMenuRoot {...rootProps}>
          <Typography ellipsis asChild>
            <PageLayoutHeaderMenuAnchor
              target="_blank"
              href={startWithHttp ? link : `https://${link}`}
              onClick={() => onExpandChange?.(false)}
            >
              {children}
            </PageLayoutHeaderMenuAnchor>
          </Typography>
        </PageLayoutHeaderMenuRoot>
      );
    }
  }
}

const PageLayoutHeaderMenuAnchor = styled(PrimitiveAnchor, {
  cursor: 'pointer',
});

const PageLayoutHeaderMenuRoot = styled(Primitive.li, {
  '&&&': {
    color: '$gray',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.4,

    ...getMobileStyle({
      maxWidth: 'none',
      fontSize: 22,
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: '-3%',
      color: '$grayLight',
    }),
  },

  variants: {
    isActive: {
      true: {
        '&&&': {
          fontWeight: 700,
          ...getMobileStyle({
            color: '$grayDarker',
          }),
        },
      },
    },
  },
});

const PageLayoutHeaderNavigationWrapper = styled('ul', {
  '&&': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 16,
  },

  variants: {
    isExpanded: {
      true: {
        ...getMobileStyle({
          '&&': {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            gap: 24,
          },
        }),
      },
      false: {
        ...getMobileStyle({
          '&&': {
            display: 'none',
          },
        }),
      },
    },
  },
});

const PageLayoutHeaderNavigationRoot = styled(Primitive.div, {
  flexShrink: 1,
  overflow: 'hidden',

  ...getMobileStyle({
    px: CONTENT_HORIZONTAL_PADDING_MOBILE,
  }),
});

const LogoImg = styled(Primitive.img, {
  flexShrink: 0,
  flexGrow: 0,

  objectFit: 'contain',
  height: 40,
  maxWidth: LOGO_MAX_WIDTH,
  borderRadius: 3,
});

const MobileExpandButton = styled(IconButton, {
  '&&&&': {
    flexGrow: 0,
    flexShrink: 0,
  },

  '&&': {
    display: 'none',
  },
  ...getMobileStyle({
    '&&': {
      display: 'flex',
    },
  }),
});

const PageLayoutHeaderTitleRoot = styled(Flex, {
  gap: 8,

  ...getMobileStyle({
    gap: 14,
  }),
});

const PageLayoutHeaderContentTitleWrapper = styled(Flex, {
  flexShrink: 0,
  maxWidth: '100%',
  height: PAGE_HEADER_HEIGHT,
  gap: 24,

  ...getMobileStyle({
    px: CONTENT_HORIZONTAL_PADDING_MOBILE,
    fontSize: 18,
    lineHeight: 1.4,
    letterSpacing: 'unset',
  }),

  variants: {
    isExpanded: {
      true: {
        ...getMobileStyle({
          boxShadow:
            '0px 2px 6px rgba(0, 0, 0, 0.02), inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',
        }),
      },
    },
  },
});

const PageLayoutHeaderContentRoot = styled(Primitive.div, {
  minHeight: PAGE_HEADER_HEIGHT,
  maxWidth: CONTENT_MAX_WIDTH,
  mx: 'auto',
  px: CONTENT_HORIZONTAL_PADDING,

  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: 40,

  ...getMobileStyle({
    px: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    gap: 0,
  }),
});

const PageLayoutHeaderRoot = styled(Primitive.div, {
  background: '$white',
  boxShadow:
    '0px 2px 6px rgba(0, 0, 0, 0.02), inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.08)',

  position: 'sticky',
  zIndex: PAGE_HEADER_Z_INDEX,
  top: 0,

  variants: {
    isExpanded: {
      true: {
        ...getMobileStyle({
          '&&': {
            boxShadow: 'none',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }),
      },
    },
  },
});

export const PageLayoutHeader = Object.assign(PageLayoutHeaderImpl, {
  Content: PageLayoutHeaderContent,
  Navigation: PageLayoutHeaderNavigation,
  Menu: PageLayoutHeaderMenu,
  Title: PageLayoutHeaderTitle,
});
