import { Primitive } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { ComponentProps } from 'react';

import {
  CONTENT_HORIZONTAL_PADDING,
  CONTENT_HORIZONTAL_PADDING_MOBILE,
  CONTENT_MAX_WIDTH,
  CONTENT_MAX_WIDTH_MOBILE,
} from '../../../configs/style';
import { getMobileStyle } from '../../../utils';

interface Props
  extends Omit<
    ComponentProps<typeof BlockComponentSectionRoot>,
    'as' | 'asChild'
  > {}

export function BlockComponentSection({ children, ...rootProps }: Props) {
  return (
    <BlockComponentSectionRoot {...rootProps} as="section">
      {children}
    </BlockComponentSectionRoot>
  );
}

interface ContentProps
  extends ComponentProps<typeof BlockComponentSectionContentRoot> {
  fullWidth?: boolean;
}

function BlockComponentSectionContent({
  fullWidth = false,
  children,
  ...rootProps
}: ContentProps) {
  return (
    <BlockComponentSectionContentRoot fullWidth={fullWidth} {...rootProps}>
      {children}
    </BlockComponentSectionContentRoot>
  );
}

const BlockComponentSectionContentRoot = styled(Primitive.div, {
  position: 'relative',
  mx: 'auto',
  maxWidth: CONTENT_MAX_WIDTH,
  ...getMobileStyle({
    maxWidth: CONTENT_MAX_WIDTH_MOBILE,
  }),

  variants: {
    fullWidth: {
      false: {
        px: CONTENT_HORIZONTAL_PADDING,
        ...getMobileStyle({
          px: CONTENT_HORIZONTAL_PADDING_MOBILE,
        }),
      },
    },
  },
});

const BlockComponentSectionRoot = styled(Primitive.div, {
  position: 'relative',
});

BlockComponentSection.Content = BlockComponentSectionContent;
