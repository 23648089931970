import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import { getMobileStyle } from '../../../../utils';

interface Props {
  title: JSX.Element;
  subText: JSX.Element;
  link: JSX.Element;
}

export default function CoverCompatibleBlockComponentLayout({
  title,
  subText,
  link,
}: Props) {
  return (
    <CoverCompatibleComponentLayoutRoot direction="column" align="center">
      <TextContentWrapper direction="column" align="stretch">
        {subText}
        {title}
      </TextContentWrapper>
      {link}
    </CoverCompatibleComponentLayoutRoot>
  );
}

const TextContentWrapper = styled(Flex, {
  width: '100%',
  gap: 24,
});

const CoverCompatibleComponentLayoutRoot = styled(Flex, {
  gap: 52,
  py: 80,
  ...getMobileStyle({
    py: 60,
  }),
});
