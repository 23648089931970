import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { createScopedContext } from '@flex-packages/shared-react';
import { Children, type PropsWithChildren } from 'react';

import type { ListHorizontalBlockComponent } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

type ListHorizontalLayoutType = 'one_item' | 'two_items' | 'three_items';
interface ListHorizontalBlockComponentLayoutContext {
  listHorizontalLayoutType: ListHorizontalLayoutType;
}

const ListHorizontalBlockComponentLayoutContext =
  createScopedContext<ListHorizontalBlockComponentLayoutContext>({
    rootComponentName: 'ListHorizontalBlockComponentLayout',
    displayName: 'ListHorizontalBlockComponentLayoutContext',
  });

interface Props {}

export function ListHorizontalBlockComponentLayout({
  children,
}: PropsWithChildren<Props>) {
  const childArray = Children.toArray(children);
  const listHorizontalLayoutType = getListHorizontalLayoutType(
    childArray.length
  );

  return (
    <ListHorizontalBlockComponentLayoutContext.Provider
      value={{ listHorizontalLayoutType }}
    >
      <ListHorizontalBlockComponentLayoutRoot
        isSingleItem={listHorizontalLayoutType === 'one_item'}
      >
        {children}
      </ListHorizontalBlockComponentLayoutRoot>
    </ListHorizontalBlockComponentLayoutContext.Provider>
  );
}

interface ItemProps {
  align: ListHorizontalBlockComponent['align'];
  title: JSX.Element;
  subText: JSX.Element;
  media: JSX.Element;
}

function ListHorizontalBlockComponentLayoutItem({
  align,
  title,
  subText,
  media,
}: ItemProps) {
  const { listHorizontalLayoutType } =
    ListHorizontalBlockComponentLayoutContext.useContextValue();
  const isTextContentFirst =
    listHorizontalLayoutType === 'one_item' && align === AlignStyle.Right;

  const textContent = (
    <TextContentWrapper key="text-content" direction="column" align="stretch">
      {title}
      {subText}
    </TextContentWrapper>
  );

  const mediaContent = (
    <MediaSideContentWrapper key="media-content">
      <MediaContentAspectRatioContainer
        listHorizontalLayoutType={listHorizontalLayoutType}
      >
        <MediaContentAspectRatioInner>{media}</MediaContentAspectRatioInner>
      </MediaContentAspectRatioContainer>
    </MediaSideContentWrapper>
  );

  return (
    <ListHorizontalBlockComponentLayoutItemRoot
      isSingleItem={listHorizontalLayoutType === 'one_item'}
    >
      {isTextContentFirst
        ? [textContent, mediaContent]
        : [mediaContent, textContent]}
    </ListHorizontalBlockComponentLayoutItemRoot>
  );
}

const TextContentWrapper = styled(Flex, {
  flex: 49,
  gap: 16,
  paddingTop: 24,

  ...getMobileStyle({
    paddingTop: 20,
    gap: 8,
  }),
});

const MediaContentAspectRatioInner = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',

  borderRadius: 10,
  overflow: 'hidden',
});

const MediaContentAspectRatioContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: 0,
  paddingBottom: '87.5%',

  variants: {
    listHorizontalLayoutType: {
      one_item: {
        paddingBottom: '56.25%',
      },
      two_items: {
        paddingBottom: '77.5%',
      },
      three_items: {
        paddingBottom: '126.88%',
      },
    },
  },
});

const MediaSideContentWrapper = styled(Flex, {
  flex: 80,
});

const ListHorizontalBlockComponentLayoutItemRoot = styled(Flex, {
  flex: 1,

  variants: {
    isSingleItem: {
      true: {
        '&&': {
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: 48,
          ...getMobileStyle({
            gap: 0,
          }),
        },
      },
      false: {
        '&&': {
          flexDirection: 'column',
          alignItems: 'stretch',
        },
      },
    },
  },

  ...getMobileStyle({
    '&&&': {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  }),
});

const ListHorizontalBlockComponentLayoutRoot = styled(Flex, {
  '&&': {
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 20,
    ...getMobileStyle({
      flexDirection: 'column',
      alignItems: 'stretch',
    }),
  },

  variants: {
    isSingleItem: {
      true: {
        py: 60,
      },
      false: {
        py: 40,
      },
    },
  },

  ...getMobileStyle({
    '&&': {
      py: 20,
    },
  }),
});

function getListHorizontalLayoutType(
  itemCount: number
): ListHorizontalLayoutType {
  if (itemCount < 2) {
    return 'one_item';
  }
  if (itemCount < 3) {
    return 'two_items';
  }
  return 'three_items';
}

ListHorizontalBlockComponentLayout.Item =
  ListHorizontalBlockComponentLayoutItem;
