import { styled } from '@flex-design-system/stitches-react';

import { SizeStyle, type GalleryBlockComponent } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

type MediaLayoutType = 'one_item' | 'three_items' | 'four_items' | 'six_items';

interface Props {
  size: GalleryBlockComponent['size'];
  gap: GalleryBlockComponent['gap'];
  medias: Array<JSX.Element>;
}

export function GalleryBlockComponentLayout({ size, gap, medias }: Props) {
  const mediaLayoutType = getMediaLayoutType(medias.length);
  const isSingleItem = mediaLayoutType === 'one_item';

  return (
    <GalleryBlockComponentLayoutRoot isSingleItem={isSingleItem}>
      {isSingleItem ? (
        <SingleMediaContent size={size} media={medias[0]} />
      ) : (
        <MultiMediaContent
          gap={gap}
          mediaLayoutType={mediaLayoutType}
          medias={medias}
        />
      )}
    </GalleryBlockComponentLayoutRoot>
  );
}

interface SingleMediaContentWrapperProps {
  size: GalleryBlockComponent['size'];
  media?: JSX.Element;
}

function SingleMediaContent({ size, media }: SingleMediaContentWrapperProps) {
  return (
    <SingleMediaContentRoot sizeStyle={size}>
      <SingleMediaContentWrapper>{media}</SingleMediaContentWrapper>
    </SingleMediaContentRoot>
  );
}

const SingleMediaContentWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  borderRadius: 10,
});

const SingleMediaContentRoot = styled('div', {
  position: 'relative',
  width: '100%',
  height: 0,

  variants: {
    sizeStyle: {
      [SizeStyle.Small]: {
        paddingBottom: '33.33%',
      },
      [SizeStyle.Default]: {
        paddingBottom: '37.04%',
      },
      [SizeStyle.Large]: {
        paddingBottom: '56.3%',
      },
    },
  },
});

interface MultiMediaContentWrapperProps {
  gap: GalleryBlockComponent['gap'];
  mediaLayoutType: Exclude<MediaLayoutType, 'one_item'>;
  medias: Array<JSX.Element>;
}

function MultiMediaContent({
  gap,
  mediaLayoutType,
  medias,
}: MultiMediaContentWrapperProps) {
  return (
    <MultiMediaContentRoot mediaLayoutType={mediaLayoutType}>
      <MultiMediaContentWrapper mediaLayoutType={mediaLayoutType} gap={gap}>
        {medias.map(media => (
          <MultiMediaItemRoot key={media.key} mediaLayoutType={mediaLayoutType}>
            <MultiMediaItemWrapper gap={gap}>{media}</MultiMediaItemWrapper>
          </MultiMediaItemRoot>
        ))}
      </MultiMediaContentWrapper>
    </MultiMediaContentRoot>
  );
}

const MultiMediaItemWrapper = styled('div', {
  position: 'static',
  width: '100%',
  height: '100%',

  variants: {
    gap: {
      true: {
        overflow: 'hidden',
        borderRadius: 10,
      },
    },
  },

  ...getMobileStyle({
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: 10,
  }),
});

const MultiMediaItemRoot = styled('div', {
  overflow: 'hidden',
  position: 'static',
  width: '100%',
  height: '100%',

  variants: {
    mediaLayoutType: {
      three_items: {
        ...getMobileStyle({
          position: 'relative',
          height: 0,
          paddingBottom: '103.13%',
        }),
      },
      four_items: {
        ...getMobileStyle({
          position: 'relative',
          height: 0,
          paddingBottom: '75%',
        }),
      },
      six_items: {
        ...getMobileStyle({
          position: 'relative',
          height: 0,
          paddingBottom: '75%',
        }),
      },
    },
  },
});

const MultiMediaContentWrapper = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',

  display: 'grid',
  variants: {
    mediaLayoutType: {
      three_items: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(1, 1fr)',
      },
      four_items: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      six_items: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
      },
    },
    gap: {
      true: {
        gap: 10,
      },
    },
  },

  ...getMobileStyle({
    position: 'static',
    height: 'auto',

    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
});

const MultiMediaContentRoot = styled('div', {
  position: 'relative',
  width: '100%',
  height: 0,

  variants: {
    mediaLayoutType: {
      three_items: {
        paddingBottom: '35.19%',
      },
      four_items: {
        paddingBottom: '71.3%',
      },
      six_items: {
        paddingBottom: '52.77%',
      },
    },
  },

  ...getMobileStyle({
    '&&': {
      position: 'static',
      height: 'auto',
      paddingBottom: 0,
    },
  }),
});

const GalleryBlockComponentLayoutRoot = styled('div', {
  variants: {
    isSingleItem: {
      true: {
        py: 80,
        ...getMobileStyle({
          py: 60,
        }),
      },
      false: {
        py: 100,
        ...getMobileStyle({
          py: 40,
        }),
      },
    },
  },
});

function getMediaLayoutType(itemCount: number): MediaLayoutType {
  if (itemCount <= 1) {
    return 'one_item';
  }
  if (itemCount <= 3) {
    return 'three_items';
  }
  if (itemCount <= 4) {
    return 'four_items';
  }
  return 'six_items';
}
