import type { EducationExperienceGraduateType } from '@flex-apis/recruiting';
import { Flex, Tag, Typography } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';
import { EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT } from '@flex-private-packages/recruiting-common/base';

interface HeaderInformationProps {
  educationInstitutionName?: string;
  graduationStatus?: EducationExperienceGraduateType;
  isLastEducationalBackground?: boolean;
}

function HeaderInformation({
  educationInstitutionName,
  graduationStatus,
  isLastEducationalBackground = false,
}: HeaderInformationProps) {
  return (
    <Flex direction="column" align="baseline" css={{ gap: 2 }}>
      {educationInstitutionName ? (
        <>
          <Flex direction="row" align="center" css={{ gap: 6 }}>
            <Typography size={14} color="grayDarker" weight={500} ellipsis>
              {educationInstitutionName}
            </Typography>
            <LastEducationalBackgroundWhetherTag
              isLastEducationalBackground={isLastEducationalBackground}
            />
          </Flex>
          {graduationStatus && (
            <Typography size={12} color="grayLight" weight={400}>
              <Translation
                tKey={
                  EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT[
                    graduationStatus
                  ].i18n
                }
              />
            </Typography>
          )}
        </>
      ) : (
        <HeaderInformationPlaceholder />
      )}
    </Flex>
  );
}

function HeaderInformationPlaceholder() {
  return (
    <Typography size={14} color="grayLighter" weight={500}>
      <Translation tKey="recruiting.educational_background.placeholder" />
    </Typography>
  );
}

function LastEducationalBackgroundWhetherTag({
  isLastEducationalBackground,
}: {
  isLastEducationalBackground: boolean;
}) {
  if (!isLastEducationalBackground) {
    return null;
  }

  return (
    <Tag size="small" variant="weak" color="gray" css={{ minWidth: '45px' }}>
      <Translation tKey="recruiting.educational_background.last" />
    </Tag>
  );
}

export default HeaderInformation;
