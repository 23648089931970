import type { SiteDesignPageResponse } from '@flex-apis/recruiting';
import {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';

import type { BlockComponentV2PageContent } from '../page-content';
import { parseBlockComponentV2PageContentString } from '../page-content';

import type { PageComponent } from './page-component';

export interface InternalSubPageComponent extends PageComponent {
  readonly type: typeof SiteDesignPageType.Custom;
  readonly linkType: typeof SiteDesignPageLinkType.Internal;
  readonly blockComponentPageContent: BlockComponentV2PageContent;
  readonly jobDescriptionPageContent: null;
}

export function isInternalSubPageComponent(
  pageComponent: PageComponent
): pageComponent is InternalSubPageComponent {
  return (
    pageComponent.type === SiteDesignPageType.Custom &&
    pageComponent.linkType === SiteDesignPageLinkType.Internal
  );
}

export function generateInternalSubPageComponent(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): InternalSubPageComponent {
  const { siteDesignPageResponse } = params;
  return {
    type: SiteDesignPageType.Custom,
    linkType: SiteDesignPageLinkType.Internal,
    title: siteDesignPageResponse.title,
    urlPagePath: siteDesignPageResponse.urlPagePath ?? null,
    href: siteDesignPageResponse.href ?? null,
    enabled: siteDesignPageResponse.enabled,
    blockComponentPageContent: parseBlockComponentV2PageContentString(
      siteDesignPageResponse.content?.content ?? ''
    ),
    jobDescriptionPageContent: null,
  };
}
