import { v4 as uuidV4 } from 'uuid';

import type {
  BlockComponentTypeValue as BlockComponentTypeValue_V1,
  TextButtonBlockComponent,
} from '../../block-v1-legacy';
import type {
  BackgroundContent,
  BackgroundTypeValue,
  LinkContent,
  TextContent,
} from '../block-content';
import {
  generateDefaultBackgroundContent,
  generateDefaultLinkContent,
  generateDefaultTextContent,
  migrateLinkContentV1,
  migrateTextContentV1,
  validateBackgroundContent,
  validateLinkContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  migrateAlignStyleV1,
  migrateBlockComponentV1,
  migrateSizeStyleV1,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_SIZE_STYLES = [SizeStyle.Default, SizeStyle.Large] as const;
const VALID_ALIGN_STYLES = [
  AlignStyle.Left,
  AlignStyle.Center,
  AlignStyle.Right,
] as const;

type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];
type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateSizeStyle(param: unknown): param is ValidSizeStyle {
  return VALID_SIZE_STYLES.includes(param as ValidSizeStyle);
}
function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface CoverBlockComponentContent {
  readonly title: TextContent;
  readonly subText: TextContent;
  readonly link: LinkContent | null;
  readonly background: BackgroundContent | null;
}

export function updateCoverBlockComponentContent(
  origin: CoverBlockComponent,
  content: CoverBlockComponentContent
): CoverBlockComponent;
export function updateCoverBlockComponentContent(
  origin: CoverBlockComponent,
  content: unknown
): CoverBlockComponent;
export function updateCoverBlockComponentContent(
  origin: CoverBlockComponent,
  content: unknown
): CoverBlockComponent {
  if (!validateCoverBlockComponentContent(content)) {
    throw new Error('[updateCoverBlockComponentContent]: Invalid content');
  }

  const newContent: CoverBlockComponentContent = {
    title: { ...content.title },
    subText: { ...content.subText },
    link: content.link && { ...content.link },
    background: content.background && { ...content.background },
  };

  return { ...origin, ...newContent };
}

export function validateCoverBlockComponentContent(
  param: unknown
): param is CoverBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has link
    'link' in param &&
    (param.link === null || validateLinkContent(param.link)) &&
    // has background
    'background' in param &&
    (param.background === null || validateBackgroundContent(param.background))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface CoverBlockComponentStyle {
  readonly size: ValidSizeStyle;
  readonly align: ValidAlignStyle;
}

export function updateCoverBlockComponentRandomStyle(
  origin: CoverBlockComponent
): CoverBlockComponent {
  const newStyle: CoverBlockComponentStyle = {
    size: getRandomElementFromArray([...VALID_SIZE_STYLES], {
      defaultValue: origin.size,
      except: origin.size,
    }),
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateCoverBlockComponentStyle(
  param: unknown
): param is CoverBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has size
    'size' in param &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has align
    'align' in param &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface CoverBlockComponent
  extends BlockComponent,
    CoverBlockComponentContent,
    CoverBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'Cover'>;
}

export function generateDefaultCoverBlockComponent(param: {
  size: ValidSizeStyle;
  align: ValidAlignStyle;
  hasLink: boolean;
  backgroundType?: BackgroundTypeValue;
}): CoverBlockComponent {
  const { size, align, backgroundType, hasLink } = param;

  return {
    componentType: BlockComponentType.Cover,
    id: uuidV4(),
    title: generateDefaultTextContent(),
    subText: generateDefaultTextContent(),
    link: hasLink ? generateDefaultLinkContent() : null,
    background: backgroundType
      ? generateDefaultBackgroundContent({ backgroundType })
      : null,
    size,
    align,
  };
}

export function migrateTextButtonLeftBlockComponentV1(
  textButtonBlockComponent: TextButtonBlockComponent & {
    type: BlockComponentTypeValue_V1<'TextButtonLeft'>;
  }
): CoverBlockComponent {
  const { title, subText, link, size, align } = textButtonBlockComponent;

  const migratedSize = migrateSizeStyleV1(size);
  const migratedAlign = migrateAlignStyleV1(align);

  return {
    ...migrateBlockComponentV1(textButtonBlockComponent),
    componentType: BlockComponentType.Cover,
    title: migrateTextContentV1(title),
    subText: migrateTextContentV1(subText),
    link: migrateLinkContentV1(link),
    background: null,
    size: validateSizeStyle(migratedSize) ? migratedSize : SizeStyle.Default,
    align: validateAlginStyle(migratedAlign) ? migratedAlign : AlignStyle.Left,
  };
}

export function validateCoverBlockComponent(
  param: unknown
): param is CoverBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Cover &&
    validateCoverBlockComponentContent(param) &&
    validateCoverBlockComponentStyle(param)
  );
}
