import type {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';
import type { Nullable } from '@flex-packages/types';

import type {
  BlockComponentV2PageContent,
  JobDescriptionV1PageContent,
} from '../page-content';

/**
 * @exports
 * @enum {string}
 */
export const StaticUrlPagePath = {
  Main: '/',
  JobDescriptionList: '/job-descriptions',
} as const;
export type StaticUrlPagePath =
  (typeof StaticUrlPagePath)[keyof typeof StaticUrlPagePath];

export interface PageComponent {
  readonly type: SiteDesignPageType;
  readonly linkType: SiteDesignPageLinkType;
  readonly title: string;
  readonly urlPagePath: Nullable<string>;
  readonly href: Nullable<string>;
  readonly enabled: boolean;
  readonly blockComponentPageContent: Nullable<BlockComponentV2PageContent>;
  readonly jobDescriptionPageContent: Nullable<JobDescriptionV1PageContent>;
}
