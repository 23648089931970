import type { CoverCompatibleBlockComponent } from '../../../../../../base';
import {
  LinkContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import CoverCompatibleBlockComponentLayout from './CoverCompatibleBlockComponentLayout';
import { getLinkStyle, getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: CoverCompatibleBlockComponent;
}

export function CoverCompatibleBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <CoverCompatibleBlockComponentLayout
          title={
            <TextContentViewer content={model.title} {...getTitleTextStyle()} />
          }
          subText={
            <TextContentViewer content={model.subText} {...getSubTextStyle()} />
          }
          link={<LinkContentViewer content={model.link} {...getLinkStyle()} />}
        />
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
