import {
  CandidateHandicapCase,
  CandidateMilitaryServiceStatus,
  CandidateWorkExperienceStatus,
  EducationExperienceGraduateType,
  EducationExperienceInstitutionLevel,
  WorkExperienceContractType,
} from '@flex-apis/recruiting';
import type { TranslationKey } from '@flex-packages/i18n';
import { trans } from '@flex-packages/i18n-base';

export const EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT: Record<
  EducationExperienceGraduateType,
  { i18n: TranslationKey }
> = {
  [EducationExperienceGraduateType.Candidate]: {
    i18n: trans('recruiting.educational_background.graduate_status.unknown'),
  },
  [EducationExperienceGraduateType.Absence]: {
    i18n: trans('recruiting.educational_background.graduate_status.absence'),
  },
  [EducationExperienceGraduateType.Attending]: {
    i18n: trans('recruiting.educational_background.graduate_status.attending'),
  },
  [EducationExperienceGraduateType.DropOut]: {
    i18n: trans('recruiting.educational_background.graduate_status.drop_out'),
  },
  [EducationExperienceGraduateType.Graduated]: {
    i18n: trans('recruiting.educational_background.graduate_status.graduated'),
  },
  [EducationExperienceGraduateType.PlanToGraduate]: {
    i18n: trans(
      'recruiting.educational_background.graduate_status.plan_to_graduate'
    ),
  },
  [EducationExperienceGraduateType.Unknown]: {
    i18n: trans('recruiting.educational_background.graduate_status.unknown'),
  },
};

export const EDUCATIONAL_BACKGROUND_INSTITUTION_TYPE_CONTENT: Record<
  EducationExperienceInstitutionLevel,
  { i18n: TranslationKey }
> = {
  [EducationExperienceInstitutionLevel.BachelorCollege]: {
    i18n: trans(
      'recruiting.educational_background.institution_level.bachelor_college'
    ),
  },
  [EducationExperienceInstitutionLevel.BachelorUniversity]: {
    i18n: trans(
      'recruiting.educational_background.institution_level.bachelor_university'
    ),
  },
  [EducationExperienceInstitutionLevel.HighSchool]: {
    i18n: trans(
      'recruiting.educational_background.institution_level.highschool'
    ),
  },
  [EducationExperienceInstitutionLevel.MasterUniversity]: {
    i18n: trans(
      'recruiting.educational_background.institution_level.master_university'
    ),
  },
  [EducationExperienceInstitutionLevel.PhdUniversity]: {
    i18n: trans(
      'recruiting.educational_background.institution_level.phd_university'
    ),
  },
  [EducationExperienceInstitutionLevel.Unknown]: {
    i18n: trans('recruiting.educational_background.institution_level.unknown'),
  },
};

export const MILITARY_SERVICE_CONTENT: Record<
  CandidateMilitaryServiceStatus,
  { i18n: TranslationKey }
> = {
  [CandidateMilitaryServiceStatus.Exempted]: {
    i18n: trans('recruiting.military_service.exempted'),
  },
  [CandidateMilitaryServiceStatus.ExpirationTermOfService]: {
    i18n: trans('recruiting.military_service.expiration_term_of_service'),
  },
  [CandidateMilitaryServiceStatus.InProgressTermOfService]: {
    i18n: trans(
      'recruiting.military_service.exception_in_progress_specialized'
    ),
  },
  [CandidateMilitaryServiceStatus.Unfulfilled]: {
    i18n: trans('recruiting.military_service.unfulfilled'),
  },
};

export const DISABLED_TYPE_CONTENT: Record<
  CandidateHandicapCase,
  { i18n: TranslationKey }
> = {
  [CandidateHandicapCase.Etc]: {
    i18n: trans('recruiting.handicap.etc'),
  },
  [CandidateHandicapCase.IndustrialAccident]: {
    i18n: trans('recruiting.handicap.industrial_accident'),
  },
  [CandidateHandicapCase.Normal]: {
    i18n: trans('recruiting.handicap.normal'),
  },
  [CandidateHandicapCase.Unknown]: {
    i18n: trans('recruiting.handicap.unknown'),
  },
  [CandidateHandicapCase.VeteransDisability]: {
    i18n: trans('recruiting.handicap.veteran'),
  },
};

export const WORK_EXPERIENCE_STATUS_CONTENT: Record<
  CandidateWorkExperienceStatus,
  { i18n: TranslationKey }
> = {
  [CandidateWorkExperienceStatus.InEmploy]: {
    i18n: trans('recruiting.work_experience.status.in_employ'),
  },
  [CandidateWorkExperienceStatus.Resignation]: {
    i18n: trans('recruiting.work_experience.status.resignation'),
  },
};

export const WORK_EXPERIENCE_CONTRACT_TYPE_CONTENT: Record<
  WorkExperienceContractType,
  { i18n: TranslationKey }
> = {
  [WorkExperienceContractType.Regular]: {
    i18n: trans('recruiting.work_experience.contract_status.regular'),
  },
  [WorkExperienceContractType.Contract]: {
    i18n: trans('recruiting.work_experience.contract_status.contract'),
  },
  [WorkExperienceContractType.Freelancer]: {
    i18n: trans('recruiting.work_experience.contract_status.freelancer'),
  },
  [WorkExperienceContractType.ShortTerm]: {
    i18n: trans('recruiting.work_experience.contract_status.short_term'),
  },
  [WorkExperienceContractType.Intern]: {
    i18n: trans('recruiting.work_experience.contract_status.intern'),
  },
  [WorkExperienceContractType.Dispatch]: {
    i18n: trans('recruiting.work_experience.contract_status.dispatch'),
  },
  [WorkExperienceContractType.Executive]: {
    i18n: trans('recruiting.work_experience.contract_status.executive'),
  },
  [WorkExperienceContractType.Unknown]: {
    i18n: trans('recruiting.work_experience.contract_status.unknown'),
  },
};

export enum VeteranWhether {
  Included = 'Included',
  NotIncluded = 'NotIncluded',
}
