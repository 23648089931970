import type { PrimitiveButtonProps } from '@flex-design-system/fx';
import {
  FieldBox,
  PrimitiveButton,
  Spinner,
  Typography,
} from '@flex-design-system/fx';
import { ChevronDownMiniIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { forwardRef } from 'react';

import { getMobileStyle } from '../../../utils';

interface Props extends Omit<PrimitiveButtonProps, 'clickableStyle'> {
  isLoading?: boolean;
}

const SelectTriggerFieldBox = forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, children, ...props }, buttonRef) => {
    const disabled = props.disabled || isLoading;
    const loading = !props.disabled && isLoading;

    return (
      <FieldBox
        asChild
        variant="solid"
        css={isLoading ? {} : { clickable: 'default' }}
      >
        <StyledPrimitiveButton
          {...props}
          clickableStyle="none"
          disabled={disabled}
          ref={buttonRef}
        >
          {loading && (
            <FieldBox.LeftSlotRoot>
              <Spinner />
            </FieldBox.LeftSlotRoot>
          )}
          <FieldBox.Content>
            <Typography color="grayDarker" weight={500} size={14} ellipsis>
              {children}
            </Typography>
          </FieldBox.Content>
          <FieldBox.RightSlotRoot>
            <ChevronDownMiniIcon color="gray" />
          </FieldBox.RightSlotRoot>
        </StyledPrimitiveButton>
      </FieldBox>
    );
  }
);

const StyledPrimitiveButton = styled(PrimitiveButton, {
  '&&': {
    display: 'flex',
    alignItems: 'center',

    flexGrow: 1,
    flexShrink: 1,

    height: 48,
    maxWidth: 220,
    cursor: 'pointer',

    ...getMobileStyle({
      width: '100%',
      maxWidth: 'none',
    }),
  },
});

export default SelectTriggerFieldBox;
