import { v4 as uuidV4 } from 'uuid';

import type { MediaContent, TextContent } from '../block-content';
import {
  generateDefaultMediaContent,
  generateDefaultTextContent,
  validateMediaContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [
  AlignStyle.Left,
  AlignStyle.Center,
  AlignStyle.Right,
] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface QuoteBlockComponentContent {
  readonly items: Array<QuoteBlockItem>; // 1 ~ 3개
}

export interface QuoteBlockItem {
  readonly id: string;
  readonly image: MediaContent;
  readonly title: TextContent;
  readonly subText: TextContent;
}

export function updateQuoteBlockComponentContent(
  origin: QuoteBlockComponent,
  content: unknown
): QuoteBlockComponent;
export function updateQuoteBlockComponentContent(
  origin: QuoteBlockComponent,
  content: QuoteBlockComponentContent
): QuoteBlockComponent;
export function updateQuoteBlockComponentContent(
  origin: QuoteBlockComponent,
  content: unknown
): QuoteBlockComponent {
  if (!validateQuoteBlockComponentContent(content)) {
    throw new Error('[updateQuoteBlockComponentContent]: Invalid content');
  }

  const newContent: QuoteBlockComponentContent = {
    items: content.items.map(item => ({ ...item })),
  };

  return { ...origin, ...newContent };
}

export function validateQuoteBlockComponentContent(
  param: unknown
): param is QuoteBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has items
    'items' in param &&
    Array.isArray(param.items) &&
    validateQuoteBlockItems(param.items)
  );
}

export function validateQuoteBlockItems(
  param: Array<unknown>
): param is Array<QuoteBlockItem> {
  return (
    param.length >= 1 &&
    param.length <= 3 &&
    param.every(validateQuoteBlockItem)
  );
}

export function validateQuoteBlockItem(
  param: unknown
): param is QuoteBlockItem {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has image
    'image' in param &&
    validateMediaContent(param.image) &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText)
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface QuoteBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateQuoteBlockComponentRandomStyle(
  origin: QuoteBlockComponent
): QuoteBlockComponent {
  const newStyle: QuoteBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateQuoteBlockComponentStyle(
  param: unknown
): param is QuoteBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface QuoteBlockComponent
  extends BlockComponent,
    QuoteBlockComponentContent,
    QuoteBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'Quote'>;
}

export function generateDefaultQuoteBlockComponent(param: {
  itemCount: 1 | 2 | 3;
  align: ValidAlignStyle;
}): QuoteBlockComponent {
  const { itemCount, align } = param;

  return {
    componentType: BlockComponentType.Quote,
    id: uuidV4(),
    items: Array.from({ length: itemCount }, () => ({
      id: uuidV4(),
      image: generateDefaultMediaContent(),
      title: generateDefaultTextContent(),
      subText: generateDefaultTextContent(),
    })),
    align,
  };
}

export function validateQuoteBlockComponent(
  param: unknown
): param is QuoteBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Quote &&
    validateQuoteBlockComponentContent(param) &&
    validateQuoteBlockComponentStyle(param)
  );
}
