import { values } from '@flex-packages/utils';

import type { BlockComponent, BlockComponentTypeKey } from '../block-component';
import {
  validateAboutBlockComponent,
  validateBannerBlockComponent,
  validateColumnsBlockComponent,
  validateCoverBlockComponent,
  validateCoverCompatibleBlockComponent,
  validateCoverHalfBlockComponent,
  validateErrorBlockComponent,
  validateGalleryBlockComponent,
  validateListHorizontalBlockComponent,
  validateListVerticalBlockComponent,
  validateParagraphBlockComponent,
  validateQuoteBlockComponent,
} from '../block-component';

// NOTE: IDE에서 validator가 누란된 Block을 찾아내기 위해 Record 사용.
const VALIDATOR_MAP: Record<
  BlockComponentTypeKey,
  (param: unknown) => param is BlockComponent
> = {
  About: validateAboutBlockComponent,
  Banner: validateBannerBlockComponent,
  Columns: validateColumnsBlockComponent,
  Cover: validateCoverBlockComponent,
  CoverHalf: validateCoverHalfBlockComponent,
  CoverCompatible: validateCoverCompatibleBlockComponent,
  Gallery: validateGalleryBlockComponent,
  ListHorizontal: validateListHorizontalBlockComponent,
  ListVertical: validateListVerticalBlockComponent,
  Paragraph: validateParagraphBlockComponent,
  Quote: validateQuoteBlockComponent,
  Error: validateErrorBlockComponent,
};

const VALIDATORS = [...values(VALIDATOR_MAP)] as const;
export function validateBlockComponent(
  param: unknown
): param is BlockComponent {
  return VALIDATORS.some(validator => validator(param));
}
