import { useGetCustomerJobGroupsAsPublicRemote } from '@flex-apis/recruiting-hooks';
import { useMemo } from 'react';

import { compareJobGroupName, generateJobGroup } from '../../base';

interface Params {
  customerIdHash: string;
}

interface Options {
  suspense?: boolean;
  enabled?: boolean;
}

export function usePublicJobGroupList(params: Params, options?: Options) {
  const { data: jobGroupResponseList = [], isLoading } =
    useGetCustomerJobGroupsAsPublicRemote(params, options);

  const jobGroupList = useMemo(() => {
    return jobGroupResponseList.map(generateJobGroup).sort(compareJobGroupName);
  }, [jobGroupResponseList]);

  return {
    jobGroupList,
    isLoading,
  };
}
