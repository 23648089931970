import type { CoverHalfBlockComponent } from '../../../../../../base';
import {
  LinkContentViewer,
  MediaContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { CoverHalfBlockComponentLayout } from './CoverHalfBlockComponentLayout';
import { getLinkStyle, getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: CoverHalfBlockComponent;
}

export function CoverHalfBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content fullWidth>
        <CoverHalfBlockComponentLayout
          align={model.align}
          size={model.size}
          title={
            <TextContentViewer
              content={model.title}
              {...getTitleTextStyle(model)}
            />
          }
          subText={
            <TextContentViewer
              content={model.subText}
              {...getSubTextStyle(model)}
            />
          }
          link={
            model.link && (
              <LinkContentViewer content={model.link} {...getLinkStyle()} />
            )
          }
          media={<MediaContentViewer content={model.media} />}
        />
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
