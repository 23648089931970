import { EducationExperienceGraduateType } from '@flex-apis/recruiting';
import {
  FieldBox,
  FormField,
  Legacy_Select,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  SchoolAltIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT } from '@flex-private-packages/recruiting-common/base';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

const EDUCATION_GRADUATION_STATUS_TYPES: Array<EducationExperienceGraduateType> =
  [
    EducationExperienceGraduateType.Absence,
    EducationExperienceGraduateType.Attending,
    EducationExperienceGraduateType.DropOut,
    EducationExperienceGraduateType.Graduated,
    EducationExperienceGraduateType.PlanToGraduate,
    EducationExperienceGraduateType.Unknown,
  ];

export function EducationGraduateStatusTypeSelectFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();
  const [triggerRef, setTriggerRef] = useState<HTMLButtonElement | null>(null);

  return (
    <Controller<
      JobDescriptionFormState,
      `educationalBackgrounds.${typeof currentIndex}.graduationStatus`
    >
      name={`educationalBackgrounds.${currentIndex}.graduationStatus`}
      rules={{
        required: t(
          'recruiting.educational_background.graduate_status.edit.helper.required'
        ),
      }}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => (
        <FormField required state={error ? 'error' : 'default'}>
          <FormField.Label leftSlot={<SchoolAltIcon />}>
            <Translation tKey="recruiting.educational_background.graduate_status" />
          </FormField.Label>
          <Legacy_Select<EducationExperienceGraduateType>
            value={value}
            onValueChange={onChange}
            {...field}
          >
            <Legacy_Select.Trigger ref={setTriggerRef} asChild>
              <FormField.FieldBox asChild>
                <PrimitiveButton css={{ width: '100%' }}>
                  <FieldBox.Content>
                    {value ? (
                      <Translation
                        tKey={
                          EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT[
                            value
                          ].i18n
                        }
                      />
                    ) : (
                      <Typography color="gray" asChild>
                        <Translation tKey="recruiting.educational_background.graduate_status.edit.placeholder" />
                      </Typography>
                    )}
                  </FieldBox.Content>
                  <FieldBox.RightSlotRoot>
                    <ChevronDownMiniIcon size={22} color="grayLight" />
                  </FieldBox.RightSlotRoot>
                </PrimitiveButton>
              </FormField.FieldBox>
            </Legacy_Select.Trigger>
            <Legacy_Select.Content css={{ width: triggerRef?.offsetWidth }}>
              {EDUCATION_GRADUATION_STATUS_TYPES.map(statusType => (
                <Legacy_Select.Option key={statusType} value={statusType}>
                  <Legacy_Select.OptionTitle>
                    <Translation
                      tKey={
                        EDUCATIONAL_BACKGROUND_GRADUATION_STATUS_CONTENT[
                          statusType
                        ].i18n
                      }
                    />
                  </Legacy_Select.OptionTitle>
                </Legacy_Select.Option>
              ))}
            </Legacy_Select.Content>
          </Legacy_Select>
          {error?.message && (
            <FormField.HelperText>{error.message}</FormField.HelperText>
          )}
        </FormField>
      )}
    />
  );
}
