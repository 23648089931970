import { v4 as uuidV4 } from 'uuid';

import type { LinkContent, MediaContent, TextContent } from '../block-content';
import {
  generateDefaultLinkContent,
  generateDefaultMediaContent,
  generateDefaultTextContent,
  validateLinkContent,
  validateMediaContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_SIZE_STYLES = [
  SizeStyle.Small,
  SizeStyle.Default,
  SizeStyle.Large,
] as const;
const VALID_ALIGN_STYLES = [AlignStyle.Left, AlignStyle.Right] as const;

type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];
type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateSizeStyle(param: unknown): param is ValidSizeStyle {
  return VALID_SIZE_STYLES.includes(param as ValidSizeStyle);
}
function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

interface CoverHalfBlockComponentContent {
  readonly title: TextContent;
  readonly subText: TextContent;
  readonly media: MediaContent;
  readonly link: LinkContent | null;
}

export function updateCoverHalfBlockComponentContent(
  origin: CoverHalfBlockComponent,
  content: CoverHalfBlockComponentContent
): CoverHalfBlockComponent;
export function updateCoverHalfBlockComponentContent(
  origin: CoverHalfBlockComponent,
  content: unknown
): CoverHalfBlockComponent;
export function updateCoverHalfBlockComponentContent(
  origin: CoverHalfBlockComponent,
  content: unknown
): CoverHalfBlockComponent {
  if (!validateCoverHalfBlockComponentContent(content)) {
    throw new Error('[updateCoverHalfBlockComponentContent]: Invalid content');
  }

  const newContent: CoverHalfBlockComponentContent = {
    title: { ...content.title },
    subText: { ...content.subText },
    media: { ...content.media },
    link: content.link && { ...content.link },
  };

  return { ...origin, ...newContent };
}

export function validateCoverHalfBlockComponentContent(
  param: unknown
): param is CoverHalfBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has media
    'media' in param &&
    validateMediaContent(param.media) &&
    // has link
    'link' in param &&
    (param.link === null || validateLinkContent(param.link))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

interface CoverHalfBlockComponentStyle {
  readonly size: ValidSizeStyle;
  readonly align: ValidAlignStyle;
}

export function updateCoverHalfBlockComponentRandomStyle(
  origin: CoverHalfBlockComponent
): CoverHalfBlockComponent {
  const newStyle: CoverHalfBlockComponentStyle = {
    size: getRandomElementFromArray([...VALID_SIZE_STYLES], {
      defaultValue: origin.size,
      except: origin.size,
    }),
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateCoverHalfBlockComponentStyle(
  param: unknown
): param is CoverHalfBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has size
    'size' in param &&
    validateSizeStyle(param.size) &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface CoverHalfBlockComponent
  extends BlockComponent,
    CoverHalfBlockComponentContent,
    CoverHalfBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'CoverHalf'>;
}

export function generateDefaultCoverHalfBlockComponent(param: {
  hasLink: boolean;
  size: ValidSizeStyle;
  align: ValidAlignStyle;
}): CoverHalfBlockComponent {
  const { hasLink, size, align } = param;

  return {
    componentType: BlockComponentType.CoverHalf,
    id: uuidV4(),
    title: generateDefaultTextContent(),
    subText: generateDefaultTextContent(),
    media: generateDefaultMediaContent(),
    link: hasLink ? generateDefaultLinkContent() : null,
    size,
    align,
  };
}

export function validateCoverHalfBlockComponent(
  param: unknown
): param is CoverHalfBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.CoverHalf &&
    validateCoverHalfBlockComponentContent(param) &&
    validateCoverHalfBlockComponentStyle(param)
  );
}
