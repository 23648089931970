import type {
  RecruitingEmploymentContractType,
  SiteJobDescriptionPublicResponse,
} from '@flex-apis/recruiting';
import { isoDatePartToDate } from '@flex-packages/date';
import { RecruitingPeriodType } from '@flex-private-packages/recruiting-common/base';

export interface PublicJobDescription {
  readonly title: string;
  readonly jobDescriptionIdHash: string;
  readonly contractType: RecruitingEmploymentContractType | null;
  readonly periodType: RecruitingPeriodType;
  readonly startDate: Date | null;
  readonly endDate: Date | null;
  readonly jobGroupIdHash: string | null;
}

export function generatePublicJobDescription(
  response: SiteJobDescriptionPublicResponse
): PublicJobDescription {
  return {
    title: response.title,
    jobDescriptionIdHash: response.jobDescriptionIdHash,
    contractType: response.recruitingEmploymentContractType,
    periodType: response.isOccasionalRecruitment
      ? RecruitingPeriodType.Occasional
      : RecruitingPeriodType.Periodic,
    startDate: response.recruitingStartDate
      ? isoDatePartToDate(response.recruitingStartDate)
      : null,
    endDate: response.recruitingEndDate
      ? isoDatePartToDate(response.recruitingEndDate)
      : null,
    jobGroupIdHash: response.customerJobGroupIdHash ?? null,
  };
}
