import type { RecruitingEmploymentContractType } from '@flex-apis/recruiting';
import { TextField } from '@flex-design-system/fx';
import { MagnifyingglassIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { useDebounce } from '@flex-packages/hooks-common';
import { Translation, useTranslation } from '@flex-packages/i18n';
import type { JobGroup } from '@flex-private-packages/recruiting-common/base';
import { RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT } from '@flex-private-packages/recruiting-common/base';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect, useMemo, useState } from 'react';

import type { PublicJobDescriptionFilter } from '../../../../../base';
import { getMobileStyle } from '../../../utils';

import JobGroupListController from './logics/JobGroupListController';
import SelectWithEmptyValue from './SelectWithEmptyValue';

interface Props {
  isLoading?: boolean;
  disabled?: boolean;
  jobGroupList: JobGroup[];
  contractTypes: RecruitingEmploymentContractType[];
  filter: PublicJobDescriptionFilter;
  onChangeFilter?: Dispatch<SetStateAction<PublicJobDescriptionFilter>>;
}

export default function Filter({
  isLoading,
  disabled,
  jobGroupList,
  contractTypes,
  filter,
  onChangeFilter,
}: Props) {
  const { t } = useTranslation();
  const viewOnly = disabled || isLoading;

  const jobGroupListController = useMemo(
    () => new JobGroupListController({ jobGroupList }),
    [jobGroupList]
  );
  const [editingSearchText, setEditingSearchText] = useState<string>(
    filter.titleContains ?? ''
  );

  const debouncedEditingSearchText = useDebounce(editingSearchText, 200);
  useEffect(() => {
    onChangeFilter?.(prev => ({
      ...prev,
      titleContains: debouncedEditingSearchText,
    }));
  }, [debouncedEditingSearchText, onChangeFilter]);

  return (
    <Wrapper>
      <SelectWithEmptyValue
        isLoading={isLoading}
        disabled={viewOnly}
        value={filter.jobGroupIdHash}
        itemList={jobGroupListController.getIdHashList()}
        onValueChange={jobGroupIdHash => {
          onChangeFilter?.(prev => ({
            ...prev,
            jobGroupIdHash,
          }));
        }}
        emptyValueLabel={
          <>
            <Translation tKey="recruiting.job_group.total" />
            {` (${jobGroupListController.getSize()})`}
          </>
        }
        optionItemLabelTransfer={idHash =>
          jobGroupListController.getName({
            idHash,
            defaultValue: t('recruiting.name.empty'),
          })
        }
      />
      <SelectWithEmptyValue
        isLoading={isLoading}
        disabled={viewOnly}
        value={filter.contractType}
        itemList={contractTypes}
        onValueChange={newValue => {
          onChangeFilter?.(prev => ({
            ...prev,
            contractType: newValue,
          }));
        }}
        optionItemLabelTransfer={item =>
          t(RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT[item].i18n)
        }
        emptyValueLabel={<Translation tKey="recruiting.contract_type.total" />}
      />
      <SearchTextWrapper>
        <TextField
          disabled={viewOnly}
          size="large"
          variant="solid"
          value={editingSearchText}
          leftSlot={<MagnifyingglassIcon />}
          placeholder={t('recruiting.job_description.search.placeholder')}
          onChange={e => setEditingSearchText(e.target.value)}
        />
      </SearchTextWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',

  '& > *': {
    '&:not(:first-child)': {
      marginLeft: 8,
    },
  },

  ...getMobileStyle({
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',

    '& > *': {
      width: '100%',
      '&:not(:first-child)': {
        marginLeft: 0,
        marginTop: 8,
      },
    },
  }),
});

const SearchTextWrapper = styled('div', {
  width: 240,
  flex: '0 0 240px',
  '&&': {
    marginLeft: 'auto',
  },

  ...getMobileStyle({
    width: '100%',
    flex: '0 0 auto',
    marginLeft: '0',
  }),
});
