import { useTranslation } from '@flex-packages/i18n';
import {
  EducationInstitutionNameInput,
  EducationInstitutionTypeSelect,
} from '@flex-private-packages/recruiting-common/components';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function EducationInstitutionNameAndTypeFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();

  return (
    <Controller<
      JobDescriptionFormState,
      `educationalBackgrounds.${typeof currentIndex}.name`
    >
      name={`educationalBackgrounds.${currentIndex}.name`}
      rules={{
        required: t(
          'recruiting.educational_background.institution_name.edit.helper.required'
        ),
      }}
      render={({ field: nameField, fieldState: { error: nameError } }) => (
        <Controller<
          JobDescriptionFormState,
          `educationalBackgrounds.${typeof currentIndex}.institutionType`
        >
          name={`educationalBackgrounds.${currentIndex}.institutionType`}
          rules={{
            required: t(
              'recruiting.educational_background.institution_level.edit.helper.required'
            ),
          }}
          render={({
            field: {
              onChange: onEducationInstitutionTypeChange,
              ...educationInstitutionTypeSelectField
            },
            fieldState: { error: educationInstitutionTypeError },
          }) => {
            const errorState =
              nameError || educationInstitutionTypeError ? 'error' : 'default';
            const helperText =
              nameError?.message ?? educationInstitutionTypeError?.message;

            return (
              <EducationInstitutionNameInput
                required
                placeholder={t(
                  'recruiting.educational_background.institution_name.edit.placeholder'
                )}
                state={errorState}
                helperText={helperText}
                {...nameField}
              >
                <EducationInstitutionTypeSelect
                  onValueChange={onEducationInstitutionTypeChange}
                  {...educationInstitutionTypeSelectField}
                />
              </EducationInstitutionNameInput>
            );
          }}
        />
      )}
    />
  );
}
