import type { DialogProps } from '@flex-design-system/fx';
import { Button, Dialog } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';

interface Props extends DialogProps {
  isSubmitting: boolean;
  onSubmit: VoidFunction;
}

export function ConfirmApplyDialog({
  onSubmit,
  isSubmitting,
  ...props
}: Props) {
  return (
    <Dialog {...props}>
      <Dialog.Content>
        <Dialog.Body>
          <Dialog.UnbalancedTitle>
            <Translation tKey="recruiting.site_builder.job_description.apply.confirm.title" />
          </Dialog.UnbalancedTitle>
        </Dialog.Body>
        <Dialog.Footer orientation="vertical">
          <Button
            type="submit"
            size="large"
            color="grayDarker"
            loading={isSubmitting}
            onClick={onSubmit}
          >
            <Translation tKey="recruiting.site_builder.job_description.apply.short" />
          </Button>
          <Dialog.Cancel asChild>
            <Button size="large" color="grayDarker" variant="outline">
              <Translation tKey="recruiting.cancel.short" />
            </Button>
          </Dialog.Cancel>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
