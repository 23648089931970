import type { PresetColorType } from '@flex-design-system/fx-colors';

import type { AboutBlockComponent } from '../../../../../../base';
import { BackgroundType } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<AboutBlockComponent, 'align' | 'background'>
): TextStyle {
  return {
    align: model.align,
    color: getTitleTextColor(model),
    size: 36,
    mobileSize: 24,
    weight: 700,
    lineHeight: 1.3,
    letterSpacing: '-1.08px',
    mobileLetterSpacing: '-0.72px',
  };
}

export function getSubTitleStyle(
  model: Pick<AboutBlockComponent, 'align' | 'background'>
): TextStyle {
  return {
    align: model.align,
    color: getTitleTextColor(model),
    size: 22,
    mobileSize: 20,
    weight: 700,
    lineHeight: 1.5,
    letterSpacing: '-0.22px',
    mobileLetterSpacing: '-0.6px',
  };
}

export function getDescriptionTextStyle(
  model: Pick<AboutBlockComponent, 'align' | 'background'>
): TextStyle {
  return {
    align: model.align,
    color: getDescriptionTextColor(model),
    size: 17,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.5,
    mobileLineHeight: 1.6,
    letterSpacing: '-0.17px',
    mobileLetterSpacing: '-0.16px',
  };
}

function getTitleTextColor(
  model: Pick<AboutBlockComponent, 'background'>
): PresetColorType {
  if (model.background?.backgroundType === BackgroundType.BlackOverlayImage) {
    return 'grayLightest';
  }
  return 'grayDarker';
}

function getDescriptionTextColor(
  model: Pick<AboutBlockComponent, 'background'>
): PresetColorType {
  if (model.background?.backgroundType === BackgroundType.BlackOverlayImage) {
    return 'grayLightest';
  }
  return 'grayLight';
}
