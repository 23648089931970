// 모바일 뷰 강제로 보이게 하는 클래스
export const FORCE_MOBILE_VIEW_CLASS_NAME = 'force-mobile';

// 화면 최대 넓이
export const CONTAINER_MAX_WIDTH = 1920;
// 화면 최소 넓이
export const CONTAINER_MIN_WIDTH = 1280;

// 헤더 높이
export const HEADER_HEIGHT = 64;

// 네비게이션 에디터 (로고 업데이트 영역) 높이
export const NAVIGATION_EDITOR_HEIGHT = 72;

// 네비게이션 넓이
export const NAVIGATION_WIDTH = 280;
// 네비게이션 좌우 패딩
export const NAVIGATION_PX = 20;

// 콘텐츠 최대 넓이
export const CONTENT_MAX_WIDTH = 1280;
export const CONTENT_MAX_WIDTH_MOBILE = 360;
// 콘텐츠 좌우 패딩
export const CONTENT_HORIZONTAL_PADDING = 100;
export const CONTENT_HORIZONTAL_PADDING_MOBILE = 20;
// 콘텐츠 최소 넓이
export const CONTENT_MIN_WIDTH = 441;
// 모바일 지원 해상도
export const CONTENT_MOBILE_WIDTH = 600;

// 콘텐츠 좌우 패딩
export const CONTENT_PX = 80;
// 콘텐츠 상하 패딩
export const CONTENT_PY = 50;

// 디자인 페이지 헤더 높이
export const PAGE_HEADER_HEIGHT = 70;
// 디자인 페이지 헤더 z-index
export const PAGE_HEADER_Z_INDEX = 100;

// 콘텐츠 Radius
export const CONTENT_RADIUS = 8;
