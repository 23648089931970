import { Primitive } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { ComponentProps } from 'react';
import { forwardRef } from 'react';

import type { PublicJobDescriptionDetail } from '../../base';

import { PageRenderer } from './components/page-editor';
import type { RendererProps } from './contexts';
import { RendererPropsProvider } from './contexts';

interface Props
  extends RendererProps,
    Omit<ComponentProps<typeof SiteDesignViewerRoot>, 'children'> {
  jobDescription?: PublicJobDescriptionDetail;
}

export const SiteDesignRenderer = forwardRef<HTMLDivElement, Props>(
  (
    {
      jobDescription,
      siteDesign,
      urlPagePath,
      isHeaderExpended,
      setHeaderExpended,
      ...rootProps
    },
    ref
  ) => {
    return (
      <RendererPropsProvider
        siteDesign={siteDesign}
        urlPagePath={urlPagePath}
        isHeaderExpended={isHeaderExpended}
        setHeaderExpended={setHeaderExpended}
      >
        <SiteDesignViewerRoot {...rootProps} ref={ref}>
          <StyledPageRenderer jobDescription={jobDescription} />
        </SiteDesignViewerRoot>
      </RendererPropsProvider>
    );
  }
);

const SiteDesignViewerRoot = styled(Primitive.div, {});

const StyledPageRenderer = styled(PageRenderer, {
  minHeight: 'inherit',
  height: 'inherit',
});
