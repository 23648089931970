import { Flex } from '@flex-design-system/fx';
import { styled, type StitchesCSS } from '@flex-design-system/stitches-react';
import { useEffect, useRef, useState } from 'react';

import type { CoverHalfBlockComponent } from '../../../../../../base';
import { AlignStyle, SizeStyle } from '../../../../../../base';
import {
  CONTENT_HORIZONTAL_PADDING,
  CONTENT_HORIZONTAL_PADDING_MOBILE,
} from '../../../../configs/style';
import { getMobileStyle } from '../../../../utils';
import { LINK_CONTENT_CLASS_NAME } from '../../../block-content-field';

interface Props {
  align: CoverHalfBlockComponent['align'];
  size: CoverHalfBlockComponent['size'];

  title: JSX.Element;
  subText: JSX.Element;
  link: JSX.Element | null;
  media: JSX.Element;
}

export function CoverHalfBlockComponentLayout({
  align,
  size,
  title,
  subText,
  link,
  media,
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [hasLink, setHasLink] = useState(link !== null);

  useEffect(() => {
    setHasLink(
      Boolean(
        rootRef.current?.querySelectorAll(`.${LINK_CONTENT_CLASS_NAME}`).length
      )
    );
  }, [media]);

  const textSideContent = (
    <TextSideContentWrapper
      key="text-content"
      align="start"
      justify="center"
      alignStyle={align}
      css={getTextSideContentWrapperCSS({ size, hasLink })}
      ref={rootRef}
    >
      <TextContentWrapper align="stretch" sizeStyle={size}>
        {title}
        {subText}
      </TextContentWrapper>
      {link}
    </TextSideContentWrapper>
  );

  const mediaSideContent = (
    <MediaSideContentWrapper
      key="media-content"
      alignStyle={align}
      sizeStyle={size}
    >
      {media}
    </MediaSideContentWrapper>
  );

  return (
    <CoverHalfComponentLayoutRoot>
      {align === AlignStyle.Left
        ? [mediaSideContent, textSideContent]
        : [textSideContent, mediaSideContent]}
    </CoverHalfComponentLayoutRoot>
  );
}

const TextContentWrapper = styled(Flex, {
  '&&': {
    width: '100%',
    flexDirection: 'column',
  },

  variants: {
    sizeStyle: {
      [SizeStyle.Small]: {
        gap: 24,
      },
      [SizeStyle.Default]: {
        gap: 24,
        ...getMobileStyle({ gap: 16 }),
      },
      [SizeStyle.Large]: {
        gap: 24,
        ...getMobileStyle({ gap: 16 }),
      },
    },
  },
});

const TextSideContentWrapper = styled(Flex, {
  '&&': {
    flexDirection: 'column',
    width: '50%',
    gap: 50,
  },

  ...getMobileStyle({
    '&&': {
      width: '100%',
      gap: 30,
    },
  }),

  variants: {
    alignStyle: {
      [AlignStyle.Left]: {
        marginLeft: '50%',
        paddingLeft: 50,
        paddingRight: CONTENT_HORIZONTAL_PADDING,
        ...getMobileStyle({
          marginLeft: 'unset',
          paddingLeft: CONTENT_HORIZONTAL_PADDING_MOBILE,
          paddingRight: CONTENT_HORIZONTAL_PADDING_MOBILE,
        }),
      },
      [AlignStyle.Right]: {
        paddingLeft: CONTENT_HORIZONTAL_PADDING,
        paddingRight: 50,
        ...getMobileStyle({
          paddingLeft: CONTENT_HORIZONTAL_PADDING_MOBILE,
          paddingRight: CONTENT_HORIZONTAL_PADDING_MOBILE,
        }),
      },
    },
  },
});

/**
 * MediaSideContentWrapper는 TextSideContentWrapper의 높이에 맞춰 Media를 그리는 컴포넌트입니다.
 * - TextSideContentWrapper의 높이가 Media의 높이보다 작은 경우 MediaSideContentWrapper는 Media를 줄여 그려야합니다.
 * - TextSideContentWrapper의 높이가 Media의 높이보다 높은 경우 MediaSideContentWrapper는 Media를 늘려 그려야합니다.
 * 부모인 CoverHalfComponentLayoutRoot는 특정한 높이를 갖지 않고 자식인 TextSideContentWrapper의 높이를 따라가기 때문에
 * MediaSideContentWrapper에 height 혹은 max-height를 주는 것은 불가능합니다.
 * JS를 추가하지 않고 오직 CSS만으로 위 조건을 맞추기 위해서 position: absolute를 사용합니다.
 */
const MediaSideContentWrapper = styled('div', {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '50%',
  ...getMobileStyle({
    position: 'static',
    top: 'unset',
    bottom: 'unset',
    width: '100%',
  }),

  variants: {
    alignStyle: {
      [AlignStyle.Left]: {
        left: 0,
        ...getMobileStyle({
          left: 'unset',
        }),
      },
      [AlignStyle.Right]: {
        left: '50%',
        ...getMobileStyle({
          left: 'unset',
        }),
      },
    },
    sizeStyle: {
      [SizeStyle.Small]: {
        ...getMobileStyle({ height: 240 }),
      },
      [SizeStyle.Default]: {
        ...getMobileStyle({ height: 240 }),
      },
      [SizeStyle.Large]: {
        ...getMobileStyle({ height: 420 }),
      },
    },
  },
});

const CoverHalfComponentLayoutRoot = styled(Flex, {
  '&&': {
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'start',
  },

  ...getMobileStyle({
    '&&&': {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  }),
});

function getTextSideContentWrapperCSS(param: {
  size: CoverHalfBlockComponent['size'];
  hasLink: boolean;
}): StitchesCSS {
  const { size, hasLink } = param;

  switch (size) {
    case SizeStyle.Small:
      return hasLink
        ? { py: 80, ...getMobileStyle({ py: 50 }) }
        : { py: 80, ...getMobileStyle({ py: 50 }) };
    case SizeStyle.Default:
      return hasLink
        ? { py: 80, ...getMobileStyle({ py: 60 }) }
        : { py: 133, ...getMobileStyle({ py: 60 }) };
    case SizeStyle.Large:
      return hasLink
        ? { py: 227, ...getMobileStyle({ py: 60 }) }
        : { py: 280, ...getMobileStyle({ py: 60 }) };
  }
}
