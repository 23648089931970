import { Primitive } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { ComponentProps, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { BackgroundContent } from '../../../../../../base';
import { BackgroundType } from '../../../../../../base';

interface Props
  extends Omit<
    ComponentProps<typeof BackgroundContentViewerRoot>,
    'content' | 'css'
  > {
  content: BackgroundContent | null;
}

export function BackgroundContentViewer({
  content,
  children,
  ...rootProps
}: PropsWithChildren<Props>) {
  const style = useMemo((): React.CSSProperties | undefined => {
    switch (content?.backgroundType) {
      case BackgroundType.Color:
        return {
          backgroundColor: content.src,
        };
      case BackgroundType.BlackOverlayImage:
        return {
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), url(${content.src})`,
        };
      case BackgroundType.WhiteOverlayImage:
        return {
          backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), url(${content.src})`,
        };
      default:
        return undefined;
    }
  }, [content]);

  return (
    <BackgroundContentViewerRoot
      {...rootProps}
      style={{ ...style, ...rootProps.style }}
    >
      {children}
    </BackgroundContentViewerRoot>
  );
}

const BackgroundContentViewerRoot = styled(Primitive.div, {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50%',
});

BackgroundContentViewer.toString = () => BackgroundContentViewerRoot.toString();
