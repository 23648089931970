import { userAgentFromServerContext } from '@flex-packages/shared-react';
import type { ReactNode } from 'react';

interface Props {
  userAgent: string;
  children: ReactNode;
}

export default function UserAgentFromServerContextProvider({
  userAgent,
  children,
}: Props) {
  return (
    <userAgentFromServerContext.Provider value={userAgent}>
      {children}
    </userAgentFromServerContext.Provider>
  );
}
