import type { RecruitingEmploymentContractType } from '@flex-apis/recruiting';
import { getRegExp } from 'korean-regexp';

import type { PublicJobDescription } from './public-job-description';

export interface PublicJobDescriptionFilter {
  readonly titleContains?: string;
  readonly jobGroupIdHash?: string;
  readonly contractType?: RecruitingEmploymentContractType;
}

export function filterPublicJobDescription(
  publicJobDescription: PublicJobDescription,
  filter: PublicJobDescriptionFilter
): boolean {
  return (
    filterTitle(publicJobDescription, filter.titleContains) &&
    filterJobGroupIdHash(publicJobDescription, filter.jobGroupIdHash) &&
    filterContractType(publicJobDescription, filter.contractType)
  );
}

function filterTitle(
  publicJobDescription: PublicJobDescription,
  titleContains: PublicJobDescriptionFilter['titleContains']
) {
  return (
    titleContains === undefined ||
    titleContains === '' ||
    getRegExp(titleContains).test(publicJobDescription.title)
  );
}

function filterJobGroupIdHash(
  publicJobDescription: PublicJobDescription,
  jobGroupIdHash: PublicJobDescriptionFilter['jobGroupIdHash']
) {
  return (
    jobGroupIdHash === undefined ||
    publicJobDescription.jobGroupIdHash === jobGroupIdHash
  );
}

function filterContractType(
  publicJobDescription: PublicJobDescription,
  contractType: PublicJobDescriptionFilter['contractType']
) {
  return (
    contractType === undefined ||
    publicJobDescription.contractType === contractType
  );
}
