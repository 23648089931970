import { useFlexRouter } from '@flex-packages/router';

import { NextHead } from '../../head';

import { ErrorPageLayout } from './ErrorPageLayout';

export function NotFoundPage() {
  const { back } = useFlexRouter();

  return (
    <>
      <NextHead />
      <ErrorPageLayout onClickBackButton={back}>
        <ErrorPageLayout.Title>
          {/* <Translation tKey="global.error.404.title" /> */}
          페이지를 찾을 수 없습니다
        </ErrorPageLayout.Title>
        <ErrorPageLayout.Description>
          {/* <Translation tKey="global.error.404.description" /> */}
          올바르지 않은 주소를 입력했거나,
          <br />
          해당 페이지에 접근권한이 없습니다.
        </ErrorPageLayout.Description>
      </ErrorPageLayout>
    </>
  );
}
