import {
  ClickPopover,
  DatePicker,
  FieldBox,
  FormField,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  TodayIcon,
} from '@flex-design-system/legacy-flex-icons';
import { TimeV2 } from '@flex-packages/datetime-react/components';
import { Translation, useLanguage, useTranslation } from '@flex-packages/i18n';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function EducationAdmissionYearMonthInputFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();
  const locale = useLanguage();

  return (
    <Controller<
      JobDescriptionFormState,
      `educationalBackgrounds.${typeof currentIndex}.admissionDate`
    >
      name={`educationalBackgrounds.${currentIndex}.admissionDate`}
      rules={{
        required: t(
          'recruiting.educational_background.admission_date.edit.helper.required'
        ),
      }}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => {
        const admissionDate = value ?? null;

        return (
          <FormField required state={error ? 'error' : 'default'} {...field}>
            <FormField.Label leftSlot={<TodayIcon />}>
              <Translation tKey="recruiting.educational_background.admission_date" />
            </FormField.Label>
            <DatePicker
              mode="single"
              selectedDate={value}
              onDateChange={onChange}
            >
              <ClickPopover>
                <ClickPopover.Trigger asChild>
                  <FormField.FieldBox asChild>
                    <PrimitiveButton css={{ width: '100%' }}>
                      <FieldBox.Content>
                        {admissionDate !== null ? (
                          <TimeV2
                            date={admissionDate}
                            presetName="year_month"
                            locale={locale}
                          />
                        ) : (
                          <Typography color="grayLight" asChild>
                            <Translation tKey="recruiting.educational_background.admission_date.edit.placeholder" />
                          </Typography>
                        )}
                      </FieldBox.Content>
                      <FieldBox.RightSlotRoot>
                        <ChevronDownMiniIcon size={22} color="grayLight" />
                      </FieldBox.RightSlotRoot>
                    </PrimitiveButton>
                  </FormField.FieldBox>
                </ClickPopover.Trigger>
                <ClickPopover.Content_Legacy>
                  <DatePicker.Content css={{ paddingTop: '6px' }}>
                    <DatePicker.MonthInput />
                    <DatePicker.MonthController />
                    <DatePicker.MonthCalendar />
                  </DatePicker.Content>
                </ClickPopover.Content_Legacy>
              </ClickPopover>
            </DatePicker>
            {error?.message && (
              <FormField.HelperText>{error.message}</FormField.HelperText>
            )}
          </FormField>
        );
      }}
    />
  );
}
