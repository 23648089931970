import type { StitchesCSS } from '@flex-design-system/stitches-react';

import { FORCE_MOBILE_VIEW_CLASS_NAME } from './configs/style';

export const getMobileStyle = (style: StitchesCSS) => {
  return {
    '@mobile': style,
    [`.${FORCE_MOBILE_VIEW_CLASS_NAME} &`]: style,
  };
};

export const pcOnly = {
  ...getMobileStyle({
    display: 'none',
  }),
} satisfies StitchesCSS;

export const mobileOnly = {
  display: 'none',

  ...getMobileStyle({
    display: 'block',
  }),
} satisfies StitchesCSS;
