import type { AboutBlockComponent } from '../../../../../../base';
import {
  BackgroundContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { AboutBlockComponentLayout } from './AboutBlockComponentLayout';
import {
  getDescriptionTextStyle,
  getSubTitleStyle,
  getTitleTextStyle,
} from './style';

interface Props {
  model: AboutBlockComponent;
}

export function AboutBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BackgroundContentViewer content={model.background}>
        <BlockComponentSection.Content>
          <AboutBlockComponentLayout
            title={
              <TextContentViewer
                content={model.title}
                {...getTitleTextStyle(model)}
              />
            }
            subTitle={
              <TextContentViewer
                content={model.subTitle}
                {...getSubTitleStyle(model)}
              />
            }
            description={
              <TextContentViewer
                content={model.description}
                {...getDescriptionTextStyle(model)}
              />
            }
          />
        </BlockComponentSection.Content>
      </BackgroundContentViewer>
    </BlockComponentSection>
  );
}
