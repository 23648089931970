import type { SiteDesignPageResponse } from '@flex-apis/recruiting';
import {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';

import type { JobDescriptionV1PageContent } from '../page-content';
import {
  generateDefaultJobDescriptionV1PageContent,
  parseJobDescriptionV1PageContentString,
} from '../page-content';

import { StaticUrlPagePath } from './page-component';
import type { PageComponent } from './page-component';

const JOB_DESCRIPTION_LIST_PAGE_CONTENT_DEFAULT_TITLE = '채용공고';

export interface JobDescriptionListPageComponent extends PageComponent {
  readonly type: typeof SiteDesignPageType.JobDescription;
  readonly linkType: typeof SiteDesignPageLinkType.Internal;
  readonly blockComponentPageContent: null;
  readonly jobDescriptionPageContent: JobDescriptionV1PageContent;
}

export function isJobDescriptionListPageComponent(
  pageComponent: PageComponent
): pageComponent is JobDescriptionListPageComponent {
  return pageComponent.type === SiteDesignPageType.JobDescription;
}

export function generateJobDescriptionListPageComponent(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): JobDescriptionListPageComponent {
  const { siteDesignPageResponse } = params;
  return {
    type: SiteDesignPageType.JobDescription,
    linkType: SiteDesignPageLinkType.Internal,
    title: siteDesignPageResponse.title,
    urlPagePath: siteDesignPageResponse.urlPagePath ?? null,
    href: siteDesignPageResponse.href ?? null,
    enabled: siteDesignPageResponse.enabled,
    blockComponentPageContent: null,
    // NOTE: Job Description content가 없는 경우 default Job Description을 생성합니다.
    jobDescriptionPageContent: siteDesignPageResponse.content?.content
      ? parseJobDescriptionV1PageContentString(
          siteDesignPageResponse.content.content
        )
      : generateDefaultJobDescriptionV1PageContent(),
  };
}

export function getDefaultJobDescriptionListPageComponent(): JobDescriptionListPageComponent {
  return {
    type: SiteDesignPageType.JobDescription,
    linkType: SiteDesignPageLinkType.Internal,
    title: JOB_DESCRIPTION_LIST_PAGE_CONTENT_DEFAULT_TITLE,
    urlPagePath: StaticUrlPagePath.JobDescriptionList,
    href: null,
    enabled: true,
    blockComponentPageContent: null,
    jobDescriptionPageContent: generateDefaultJobDescriptionV1PageContent(),
  };
}
