import { TextField } from '@flex-design-system/fx';
import { PhoneIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { AsYouType } from 'libphonenumber-js/max';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

const DEFAULT_LOCALE = 'KR';

export function PhoneNumberField() {
  const { t } = useTranslation();
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();

  return (
    <Controller
      control={control}
      name="phoneNumber"
      rules={{
        validate: (value = '') => {
          const asYouType = new AsYouType(DEFAULT_LOCALE);
          asYouType.input(value);
          if (asYouType.isValid()) {
            return true;
          }
          return t('recruiting.phone_number.edit.helper.validate');
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            variant="solid"
            onChange={e => {
              field.onChange(e);
              clearErrors('phoneNumber');
            }}
            id="phoneNumber"
            type="tel"
            required
            label={
              <TextField.Label leftSlot={<PhoneIcon />}>
                <Translation tKey="recruiting.phone_number" />
              </TextField.Label>
            }
            placeholder={t('recruiting.phone_number.edit.placeholder')}
            state={error !== undefined ? 'error' : 'default'}
            helperText={
              <TextField.HelperText>{error?.message}</TextField.HelperText>
            }
          />
        );
      }}
    />
  );
}
