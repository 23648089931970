import type { CustomerJobGroupResponse } from '@flex-apis/recruiting';

// TODO: web-pages의 도메인과 중복 제거
export interface JobGroup {
  name: string;
  idHash: string;
}

export function generateJobGroup(from: CustomerJobGroupResponse): JobGroup {
  return {
    name: from.name,
    idHash: from.customerJobGroupIdHash,
  };
}
