import { TextField } from '@flex-design-system/fx';
import type { TextFieldProps } from '@flex-design-system/fx';
import { PencilRulerIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation } from '@flex-packages/i18n';

type EducationMajorNameInputProps = Omit<
  TextFieldProps,
  'type' | 'variant' | 'label'
>;

function EducationMajorNameInput({
  helperText,
  ...props
}: EducationMajorNameInputProps) {
  return (
    <TextField
      type="text"
      variant="solid"
      label={
        <TextField.Label_Legacy leftSlot={<PencilRulerIcon />}>
          <Translation tKey="recruiting.educational_background.major" />
        </TextField.Label_Legacy>
      }
      helperText={<TextField.HelperText>{helperText}</TextField.HelperText>}
      {...props}
    />
  );
}

export default EducationMajorNameInput;
