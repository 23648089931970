import type { ColumnsBlockComponent } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<ColumnsBlockComponent, 'align' | 'items'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayDarker',
    size: isTripleItem(model) ? 24 : 32,
    mobileSize: isTripleItem(model) ? 20 : 24,
    weight: 700,
    lineHeight: 1.3,
    mobileLineHeight: isTripleItem(model) ? 1.3 : 1.5,
    letterSpacing: isTripleItem(model) ? '-0.72px' : '-0.96px',
    mobileLetterSpacing: isTripleItem(model) ? '-0.6px' : '-0.72px',
  };
}

export function getSubTextStyle(
  model: Pick<ColumnsBlockComponent, 'align'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayLight',
    size: 17,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.7,
    letterSpacing: '-0.17px',
    mobileLetterSpacing: '-0.16px',
  };
}

function isTripleItem(model: Pick<ColumnsBlockComponent, 'items'>): boolean {
  return model.items.length >= 3;
}
