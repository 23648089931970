import { Avatar, Spinner, Typography } from '@flex-design-system/fx';
import { PdfIcon } from '@flex-design-system/legacy-flex-icons';
import { byteToMebibyte } from '@flex-packages/utils';
import type { FileType } from '@flex-services/file/hooks';

import { Container, FileContentInfo } from './style';

interface FilePreviewProps {
  file: FileType;
}

export const FilePreview = ({ file }: FilePreviewProps) => {
  const isLoading = file.status === 'loading';

  return (
    <Container align="center">
      {isLoading ? (
        <Spinner size={22} />
      ) : (
        <Avatar size="xsmall" color="presetGray">
          <PdfIcon type="fill" />
        </Avatar>
      )}
      <FileContentInfo
        weight={500}
        size={14}
        color="grayDarker"
        ellipsis={true}
      >
        {`${file.name} `}
        {file.file && (
          <Typography size={11} color="grayLight">
            {byteToMebibyte(file.file.size).toFixed(1)}MB
          </Typography>
        )}
      </FileContentInfo>
    </Container>
  );
};
