import { v4 as uuidV4 } from 'uuid';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import { BlockComponentType, validateBlockComponent } from './block-component';

/* --------------- */
/* Block Component */
/* --------------- */

export interface ErrorBlockComponent extends BlockComponent {
  readonly componentType: BlockComponentTypeValue<'Error'>;
}

export function generateDefaultErrorBlock(): ErrorBlockComponent {
  return { id: uuidV4(), componentType: BlockComponentType.Error };
}

export function validateErrorBlockComponent(
  param: unknown
): param is ErrorBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Error
  );
}
