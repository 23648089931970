import type {
  PublicJobDescriptionDetail,
  SiteDesign,
} from '@flex-private-packages/recruiting-site-builder/base';

export function getSEOTitle(siteDesign: SiteDesign): string | undefined {
  if (siteDesign.seoTitle) {
    return siteDesign.seoTitle;
  }
  if (siteDesign.customerName) {
    return `${siteDesign.customerName} 채용`;
  }
}

export function getSEOTitleWithJobDescription(
  siteDesign: SiteDesign,
  jobDescription: PublicJobDescriptionDetail
): string | undefined {
  if (siteDesign.seoTitle) {
    return siteDesign.seoTitle;
  }
  if (siteDesign.customerName) {
    return `${siteDesign.customerName} 채용 - ${jobDescription.title}`;
  }
}

export function getSEODescription(siteDesign: SiteDesign): string | undefined {
  if (siteDesign.seoDescription) {
    return siteDesign.seoDescription;
  }
  if (siteDesign.customerName) {
    return `${siteDesign.customerName}의 채용사이트 입니다.`;
  }
}
