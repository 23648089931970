import { values } from '@flex-packages/utils';

import type { BlockComponent, BlockComponentTypeKey } from '../block-component';
import {
  validateMediaBlockComponent,
  validateMediaTextMultiBlockComponent,
  validateMediaTextSingleBlockComponent,
  validateTextBlockComponent,
  validateTextButtonBlockComponent,
  validateTextDoubleBlockComponent,
} from '../block-component';

// NOTE: IDE에서 validator가 누란된 Block을 찾아내기 위해 Record 사용.
const VALIDATOR_MAP: Record<
  BlockComponentTypeKey,
  (param: unknown) => param is BlockComponent
> = {
  Media: validateMediaBlockComponent,
  MediaTextTriple: validateMediaTextMultiBlockComponent,
  MediaTextMultiple: validateMediaTextMultiBlockComponent,
  MediaTextSingle: validateMediaTextSingleBlockComponent,
  Text: validateTextBlockComponent,
  TextButtonCenter: validateTextButtonBlockComponent,
  TextButtonLeft: validateTextButtonBlockComponent,
  TextDouble: validateTextDoubleBlockComponent,
};

const VALIDATORS = [...values(VALIDATOR_MAP)] as const;

export function validateBlockComponent(
  param: unknown
): param is BlockComponent {
  return VALIDATORS.some(validator => validator(param));
}
