import { styled } from '@flex-design-system/stitches-react';
import { Stack } from '@flex-design-system/utils-style';
import type { ReactNode } from 'react';

import ItemHeaderControlButtonGroup from './ControlButtonGroup';
import ItemHeaderDragButton from './DragButton';

export interface CandidateBackgroundListItemHeaderProps {
  leftSlot?: ReactNode;
  centerSlot?: ReactNode;
  rightSlot?: ReactNode;
  onClick?: VoidFunction;
}

function CandidateBackgroundListItemHeader({
  leftSlot,
  centerSlot,
  rightSlot,
  onClick,
}: CandidateBackgroundListItemHeaderProps) {
  return (
    <ItemHeaderRoot align="center" justify="space-between" onClick={onClick}>
      <Stack.Horizontal align="center" space={10}>
        {leftSlot}
        {centerSlot}
      </Stack.Horizontal>
      {rightSlot}
    </ItemHeaderRoot>
  );
}

const ItemHeaderRoot = styled(Stack.Horizontal, {
  px: '14px',
  py: '12px',

  borderRadius: '8px',

  clickable: 'default',
});

CandidateBackgroundListItemHeader.ControlButtonGroup =
  ItemHeaderControlButtonGroup;
CandidateBackgroundListItemHeader.DragButton = ItemHeaderDragButton;

export default CandidateBackgroundListItemHeader;
