import { styled } from '@flex-design-system/stitches-react';
import YouTube from 'react-youtube';

import type { MediaContent } from '../../../../../../../base';
import { MediaType } from '../../../../../../../base';
import { MEDIA_CONTENT_CLASS_NAME } from '../../../constants';

interface Props {
  content: MediaContent;
}

export function MediaContentViewer({ content }: Props) {
  switch (content.mediaType) {
    case MediaType.Image:
      return (
        <ImageViewer
          src={content.src}
          alt={content.alt}
          className={MEDIA_CONTENT_CLASS_NAME}
        />
      );
    case MediaType.Video:
      return <VideoPlayer src={content.src} />;
    default:
      return null;
  }
}

const ImageViewer = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

function VideoPlayer({ src }: Pick<MediaContent, 'src'>) {
  return (
    <YouTube
      videoId={src}
      opts={{
        width: '100%',
        height: '100%',
        playerVars: {
          modestbranding: 1,
          autoplay: 0,
        },
      }}
      style={{ width: '100%', height: '100%' }}
      className={MEDIA_CONTENT_CLASS_NAME}
    />
  );
}
