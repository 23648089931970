import type { ComponentType } from 'react';

import type { ErrorSubdomainValue } from '../../../constants';
import { ErrorSubdomain } from '../../../constants';
import { InternalServerErrorPage } from '../InternalServerErrorPage';
import { NotFoundPage } from '../NotFoundPage';

export interface ErrorProps {
  errorSubdomain: ErrorSubdomainValue;
}

export type WrappedComponentProps = Record<string, any> & {
  errorSubdomain?: never;
};

export type WithErrorProps<P extends WrappedComponentProps> = P | ErrorProps;

export function withErrorPage<P extends WrappedComponentProps>(
  WrappedComponent: ComponentType<P>
) {
  return (props: WithErrorProps<P>) => {
    if (isErrorProps(props)) {
      switch (props.errorSubdomain) {
        case ErrorSubdomain.NotFoundSubdomain:
          return <NotFoundPage />;
        case ErrorSubdomain.InternalServerErrorSubdomain:
          return <InternalServerErrorPage />;
      }
    }

    return <WrappedComponent {...props} />;
  };
}

function isErrorProps<P extends WrappedComponentProps>(
  props: WithErrorProps<P>
): props is ErrorProps {
  return 'errorSubdomain' in props;
}
