import {
  CandidateWorkExperienceStatus,
  WorkExperienceContractType,
  type CandidateWorkExperienceRequest,
  type CandidateWorkExperienceResponse,
} from '@flex-apis/recruiting';
import { dateToISODatePart, isoDatePartToDate } from '@flex-packages/date';
import { isNonNil } from '@flex-packages/utils';
import { z } from 'zod';

export const candidateWorkExperienceBackgroundSchema = z.object({
  name: z.string(),
  workExperienceStatus: z.enum([
    CandidateWorkExperienceStatus.InEmploy,
    CandidateWorkExperienceStatus.Resignation,
  ]),
  contractType: z.enum([
    WorkExperienceContractType.Unknown,
    WorkExperienceContractType.Regular,
    WorkExperienceContractType.Contract,
    WorkExperienceContractType.Dispatch,
    WorkExperienceContractType.Intern,
    WorkExperienceContractType.ShortTerm,
    WorkExperienceContractType.Freelancer,
    WorkExperienceContractType.Executive,
  ]),
  jobRoleName: z.string().optional(),
  joinDate: z.date().nullable(),
  retirementDate: z.date().nullable().optional(),
});

export type CandidateWorkExperienceBackground = z.infer<
  typeof candidateWorkExperienceBackgroundSchema
>;

export const generateWithCandidateWorkExperienceBackgroundResponse = (
  res: CandidateWorkExperienceResponse
): CandidateWorkExperienceBackground => ({
  name: res.name,
  workExperienceStatus: res.status,
  contractType: res.contractType,
  jobRoleName: res.jobRoleName ?? undefined,
  joinDate: isoDatePartToDate(res.beginDate),
  retirementDate: isNonNil(res.endDate) ? isoDatePartToDate(res.endDate) : null,
});

export const parseToWorkExperienceBackgroundPatchRequest = (
  candidateWorkExperienceBackground: CandidateWorkExperienceBackground
): CandidateWorkExperienceRequest => {
  if (candidateWorkExperienceBackground.joinDate === null) {
    throw new Error(
      '[parseToWorkExperienceBackgroundPatchRequest] 입사연월이 선택되지 않았어요.'
    );
  }

  return {
    name: candidateWorkExperienceBackground.name,
    status: candidateWorkExperienceBackground.workExperienceStatus,
    contractType: candidateWorkExperienceBackground.contractType,
    jobRoleName: candidateWorkExperienceBackground.jobRoleName,
    beginDate: dateToISODatePart(candidateWorkExperienceBackground.joinDate),
    endDate: isNonNil(candidateWorkExperienceBackground.retirementDate)
      ? dateToISODatePart(candidateWorkExperienceBackground.retirementDate)
      : undefined,
  };
};

export interface CandidateWorkExperienceBackgroundJSON
  extends Omit<
    CandidateWorkExperienceBackground,
    'joinDate' | 'retirementDate'
  > {
  joinDate: string | null;
  retirementDate?: string | null;
}

export function parseCandidateWorkExperienceBackgroundToJSON(
  model: CandidateWorkExperienceBackground
): CandidateWorkExperienceBackgroundJSON {
  return {
    ...model,
    joinDate: model.joinDate && dateToISODatePart(model.joinDate),
    retirementDate:
      model.retirementDate && dateToISODatePart(model.retirementDate),
  };
}

export function parseJSONToCandidateWorkExperienceBackground(
  json: CandidateWorkExperienceBackgroundJSON
): CandidateWorkExperienceBackground {
  return {
    ...json,
    joinDate:
      typeof json.joinDate === 'string'
        ? isoDatePartToDate(json.joinDate)
        : json.joinDate,
    retirementDate:
      typeof json.retirementDate === 'string'
        ? isoDatePartToDate(json.retirementDate)
        : json.retirementDate,
  };
}

export default CandidateWorkExperienceBackground;
