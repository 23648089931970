import type { TextContent as TextContent_V1 } from '../../block-v1-legacy';

import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export interface TextContent extends BlockContent {
  readonly contentType: BlockContentTypeValue<'Text'>;

  readonly text: string;
}

export function generateDefaultTextContent(): TextContent {
  return {
    contentType: BlockContentType.Text,
    text: '',
  };
}

export function migrateTextContentV1(textContent: TextContent_V1): TextContent {
  return {
    contentType: BlockContentType.Text,
    text: textContent.text,
  };
}

export function validateTextContent(param: unknown): param is TextContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Text &&
    // has text
    'text' in param &&
    typeof param.text === 'string'
  );
}
