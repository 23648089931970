import {
  LocaleProvider as LinearLocaleProvider,
  ToastProvider as LinearToastProvider,
  TooltipProvider as LinearTooltipProvider,
} from '@flex-design-system/fx';
import { NowFromServerContextProvider } from '@flex-packages/datetime-react/hooks';
import type { Locale } from '@flex-packages/i18n-base';
import type { ReactNode } from 'react';

import UserAgentFromServerContextProvider from './UserAgentFromServerContextProvider';

interface Props {
  locale: Locale;
  now: number;
  userAgent: string;
  children: ReactNode;
}

export function CommonsProvider({ locale, now, userAgent, children }: Props) {
  return (
    <NowFromServerContextProvider nowFromServer={now}>
      <UserAgentFromServerContextProvider userAgent={userAgent}>
        <LinearLocaleProvider locale={locale}>
          <LinearToastProvider>
            <LinearTooltipProvider>{children}</LinearTooltipProvider>
          </LinearToastProvider>
        </LinearLocaleProvider>
      </UserAgentFromServerContextProvider>
    </NowFromServerContextProvider>
  );
}
