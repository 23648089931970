import type { CoverBlockComponent } from '../../../../../../base';
import {
  BackgroundContentViewer,
  LinkContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { CoverBlockComponentLayout } from './CoverBlockComponentLayout';
import { getLinkStyle, getSubTextStyle, getTitleTextStyle } from './styles';

interface Props {
  model: CoverBlockComponent;
}

export function CoverBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BackgroundContentViewer content={model.background}>
        <BlockComponentSection.Content>
          <CoverBlockComponentLayout
            align={model.align}
            size={model.size}
            title={
              <TextContentViewer
                content={model.title}
                {...getTitleTextStyle(model)}
              />
            }
            subText={
              <TextContentViewer
                content={model.subText}
                {...getSubTextStyle(model)}
              />
            }
            link={
              model.link && (
                <LinkContentViewer
                  content={model.link}
                  {...getLinkStyle(model)}
                />
              )
            }
          />
        </BlockComponentSection.Content>
      </BackgroundContentViewer>
    </BlockComponentSection>
  );
}
