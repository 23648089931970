import { v4 as uuidV4 } from 'uuid';

import type {
  MediaTextMultiBlockComponent,
  MediaTextSingleBlockComponent,
} from '../../block-v1-legacy';
import type { MediaContent, TextContent } from '../block-content';
import {
  generateDefaultMediaContent,
  generateDefaultTextContent,
  migrateMediaContentV1,
  migrateTextContentV1,
  validateMediaContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  migrateBlockComponentV1,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Left, AlignStyle.Right] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface ListHorizontalBlockComponentContent {
  readonly items: Array<ListHorizontalBlockItem>; // 1 ~ 3개
}

export interface ListHorizontalBlockItem {
  readonly id: string;
  readonly title: TextContent;
  readonly subText: TextContent;
  readonly media: MediaContent;
}

export function updateListHorizontalBlockComponentContent(
  origin: ListHorizontalBlockComponent,
  content: unknown
): ListHorizontalBlockComponent;
export function updateListHorizontalBlockComponentContent(
  origin: ListHorizontalBlockComponent,
  content: ListHorizontalBlockComponentContent
): ListHorizontalBlockComponent;
export function updateListHorizontalBlockComponentContent(
  origin: ListHorizontalBlockComponent,
  content: unknown
): ListHorizontalBlockComponent {
  if (!validateListHorizontalBlockComponentContent(content)) {
    throw new Error(
      '[updateListHorizontalBlockComponentContent]: Invalid content'
    );
  }

  const newContent: ListHorizontalBlockComponentContent = {
    items: content.items.map(item => ({ ...item })),
  };

  return { ...origin, ...newContent };
}

export function validateListHorizontalBlockComponentContent(
  param: unknown
): param is ListHorizontalBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has items
    'items' in param &&
    Array.isArray(param.items) &&
    validateListHorizontalBlockItems(param.items)
  );
}

export function validateListHorizontalBlockItems(
  param: Array<unknown>
): param is Array<ListHorizontalBlockItem> {
  return (
    param.length >= 1 &&
    param.length <= 3 &&
    param.every(validateListHorizontalBlockItem)
  );
}

export function validateListHorizontalBlockItem(
  param: unknown
): param is ListHorizontalBlockItem {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has media
    'media' in param &&
    validateMediaContent(param.media)
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface ListHorizontalBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateListHorizontalBlockComponentRandomStyle(
  origin: ListHorizontalBlockComponent
): ListHorizontalBlockComponent {
  const newStyle: ListHorizontalBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateListHorizontalBlockComponentStyle(
  param: unknown
): param is ListHorizontalBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface ListHorizontalBlockComponent
  extends BlockComponent,
    ListHorizontalBlockComponentContent,
    ListHorizontalBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'ListHorizontal'>;
}

export function generateDefaultListHorizontalBlockComponent(param: {
  itemCount: 1 | 2 | 3;
  align: ValidAlignStyle;
}): ListHorizontalBlockComponent {
  const { itemCount, align } = param;

  return {
    componentType: BlockComponentType.ListHorizontal,
    id: uuidV4(),
    items: Array.from({ length: itemCount }, () => ({
      id: uuidV4(),
      title: generateDefaultTextContent(),
      subText: generateDefaultTextContent(),
      media: generateDefaultMediaContent(),
    })),
    align,
  };
}

export function migrateMediaTextSingleBlockComponentV1(
  mediaTextSingleBlockComponent: MediaTextSingleBlockComponent
): ListHorizontalBlockComponent {
  const { title, subText, media } = mediaTextSingleBlockComponent;

  return {
    ...migrateBlockComponentV1(mediaTextSingleBlockComponent),
    componentType: BlockComponentType.ListHorizontal,
    items: [
      {
        id: uuidV4(),
        title: migrateTextContentV1(title),
        subText: migrateTextContentV1(subText),
        media: migrateMediaContentV1(media),
      },
    ],
    align: AlignStyle.Left,
  };
}

export function migrateMediaTextMultiBlockComponentV1(
  mediaTextMultiBlockComponent: MediaTextMultiBlockComponent
): ListHorizontalBlockComponent {
  const { contents } = mediaTextMultiBlockComponent;

  return {
    ...migrateBlockComponentV1(mediaTextMultiBlockComponent),
    componentType: BlockComponentType.ListHorizontal,
    items: contents.map(item => ({
      id: item.id,
      title: migrateTextContentV1(item.title),
      subText: migrateTextContentV1(item.subText),
      media: migrateMediaContentV1(item.media),
    })),
    align: AlignStyle.Left,
  };
}

export function validateListHorizontalBlockComponent(
  param: unknown
): param is ListHorizontalBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.ListHorizontal &&
    validateListHorizontalBlockComponentContent(param) &&
    validateListHorizontalBlockComponentStyle(param)
  );
}
