import type { CandidateWorkExperienceStatus } from '@flex-apis/recruiting';
import { Flex, Tag, Typography } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';
import { WORK_EXPERIENCE_STATUS_CONTENT } from '@flex-private-packages/recruiting-common/base';

interface HeaderInformationProps {
  companyName?: string;
  workExperienceStatus?: CandidateWorkExperienceStatus;
  isLastBackground?: boolean;
}

function HeaderInformation({
  companyName,
  workExperienceStatus,
  isLastBackground = false,
}: HeaderInformationProps) {
  return (
    <Flex direction="column" align="baseline" css={{ gap: 2 }}>
      {companyName ? (
        <>
          <Flex direction="row" align="center" css={{ gap: 6 }}>
            <Typography size={14} color="grayDarker" weight={500} ellipsis>
              {companyName}
            </Typography>
            <LastBackgroundWhetherTag isLastBackground={isLastBackground} />
          </Flex>
          {workExperienceStatus && (
            <Typography size={12} color="grayLight" weight={400} asChild>
              <Translation
                tKey={WORK_EXPERIENCE_STATUS_CONTENT[workExperienceStatus].i18n}
              />
            </Typography>
          )}
        </>
      ) : (
        <HeaderInformationPlaceholder />
      )}
    </Flex>
  );
}

function HeaderInformationPlaceholder() {
  return (
    <Typography size={14} color="grayLighter" weight={500} asChild>
      <Translation tKey="recruiting.work_experience.placeholder" />
    </Typography>
  );
}

function LastBackgroundWhetherTag({
  isLastBackground,
}: {
  isLastBackground: boolean;
}) {
  if (!isLastBackground) {
    return null;
  }

  return (
    <Tag size="small" variant="weak" color="gray" css={{ minWidth: '45px' }}>
      <Translation tKey="recruiting.work_experience.last" />
    </Tag>
  );
}

export default HeaderInformation;
