import type { QuoteBlockComponent } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<QuoteBlockComponent, 'align'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayLight',
    size: 18,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.5,
    letterSpacing: '-1.8px',
    mobileLetterSpacing: '-0.16px',
  };
}

export function getSubTextStyle(
  model: Pick<QuoteBlockComponent, 'align'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayLight',
    size: 16,
    mobileSize: 14,
    weight: 500,
    lineHeight: 1.5,
  };
}
