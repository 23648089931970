import type { JobGroup } from '../models';

export function compareJobGroupName(a: JobGroup, b: JobGroup) {
  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
}
