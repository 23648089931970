import { Checkbox, Flex } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../models';
import { FormSection } from '../../shared';

import {
  get_개인정보_및_민감정보,
  get_개인정보처리업무_위탁,
  get_민감정보,
} from './constants';
import { TermsGroupContainer } from './TermsGroupContainer';

interface Props {
  customerName: string;
  requiredSensitiveInformation: boolean;
}

export function Terms({ customerName, requiredSensitiveInformation }: Props) {
  const { control, setValue, clearErrors } =
    useFormContext<JobDescriptionFormState>();

  return (
    <FormSection
      title={
        <FormSection.Title>
          <Translation tKey="recruiting.site_builder.job_description.apply.terms" />
        </FormSection.Title>
      }
    >
      <Flex direction="column" css={{ gap: 40 }}>
        <Flex direction="column" css={{ gap: 16 }}>
          <TermsGroupContainer
            termGroup={get_개인정보_및_민감정보({ customerName })}
          />
          <Controller
            control={control}
            name="agreedToTerms"
            rules={{
              required: true,
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => {
              return (
                <Checkbox.Label
                  css={{
                    color: error !== undefined ? '$orangeDark' : '$grayDark',
                  }}
                >
                  <Checkbox
                    {...rest}
                    checked={value}
                    onCheckedChange={newChecked => {
                      if (typeof newChecked === 'boolean') {
                        setValue('agreedToTerms', newChecked);
                      }

                      clearErrors('agreedToTerms');
                    }}
                  />
                  <Translation tKey="recruiting.site_builder.job_description.apply.terms.개인정보 수집 및 이용 동의" />
                </Checkbox.Label>
              );
            }}
          />
        </Flex>
        {requiredSensitiveInformation ? (
          <Flex direction="column" css={{ gap: 16 }}>
            <TermsGroupContainer termGroup={get_민감정보()} />
            <Controller
              control={control}
              name="agreedToSensitiveInformationTerms"
              rules={{
                required: true,
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => {
                return (
                  <Checkbox.Label
                    css={{
                      color: error !== undefined ? '$orangeDark' : '$grayDark',
                    }}
                  >
                    <Checkbox
                      {...rest}
                      checked={value}
                      onCheckedChange={newChecked => {
                        if (typeof newChecked === 'boolean') {
                          setValue(
                            'agreedToSensitiveInformationTerms',
                            newChecked
                          );
                        }

                        clearErrors('agreedToSensitiveInformationTerms');
                      }}
                    />
                    <Translation tKey="recruiting.site_builder.job_description.apply.terms.민감정보 수집 및 이용 동의" />
                  </Checkbox.Label>
                );
              }}
            />
          </Flex>
        ) : null}
        <TermsGroupContainer termGroup={get_개인정보처리업무_위탁()} />
      </Flex>
    </FormSection>
  );
}
