import { v4 as uuidV4 } from 'uuid';

import type {
  TextBlockComponent,
  TextDoubleBlockComponent,
} from '../../block-v1-legacy';
import type { TextContent } from '../block-content';
import {
  generateDefaultTextContent,
  migrateTextContentV1,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  migrateAlignStyleV1,
  migrateBlockComponentV1,
  migrateSizeStyleV1,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_SIZE_STYLES = [
  SizeStyle.Small,
  SizeStyle.Default,
  SizeStyle.Large,
] as const;
const VALID_ALIGN_STYLES = [
  AlignStyle.Left,
  AlignStyle.Center,
  AlignStyle.Right,
] as const;

type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];
type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateSizeStyle(param: unknown): param is ValidSizeStyle {
  return VALID_SIZE_STYLES.includes(param as ValidSizeStyle);
}
function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface ParagraphBlockComponentContent {
  title: TextContent;
  subText: TextContent | null;
}

export function updateParagraphBlockComponentContent(
  origin: ParagraphBlockComponent,
  content: unknown
): ParagraphBlockComponent;
export function updateParagraphBlockComponentContent(
  origin: ParagraphBlockComponent,
  content: ParagraphBlockComponentContent
): ParagraphBlockComponent;
export function updateParagraphBlockComponentContent(
  origin: ParagraphBlockComponent,
  content: unknown
): ParagraphBlockComponent {
  if (!validateParagraphBlockComponentContent(content)) {
    throw new Error('[updateParagraphBlockComponentContent]: Invalid content');
  }

  const newContent: ParagraphBlockComponentContent = {
    title: { ...content.title },
    subText: content.subText && { ...content.subText },
  };

  return { ...origin, ...newContent };
}

export function validateParagraphBlockComponentContent(
  param: unknown
): param is ParagraphBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    (param.subText === null || validateTextContent(param.subText))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface ParagraphBlockComponentStyle {
  size: ValidSizeStyle;
  align: ValidAlignStyle;
}

export function updateParagraphBlockComponentRandomStyle(
  origin: ParagraphBlockComponent
): ParagraphBlockComponent {
  const newStyle: ParagraphBlockComponentStyle = {
    size: getRandomElementFromArray([...VALID_SIZE_STYLES], {
      defaultValue: origin.size,
      except: origin.size,
    }),
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateParagraphBlockComponentStyle(
  param: unknown
): param is ParagraphBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has size
    'size' in param &&
    validateSizeStyle(param.size) &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface ParagraphBlockComponent
  extends BlockComponent,
    ParagraphBlockComponentContent,
    ParagraphBlockComponentStyle {
  componentType: BlockComponentTypeValue<'Paragraph'>;
}

export function generateDefaultParagraphBlockComponent(param: {
  hasSubText: boolean;
  size: ValidSizeStyle;
  align: ValidAlignStyle;
}): ParagraphBlockComponent {
  const { hasSubText, size, align } = param;

  return {
    componentType: BlockComponentType.Paragraph,
    id: uuidV4(),
    title: generateDefaultTextContent(),
    subText: hasSubText ? generateDefaultTextContent() : null,
    size,
    align,
  };
}

export function migrateTextBlockComponentV1(
  textBlockComponent: TextBlockComponent
): ParagraphBlockComponent {
  const { text, align, size } = textBlockComponent;

  const migratedSize = migrateSizeStyleV1(size);
  const migratedAlign = migrateAlignStyleV1(align);

  return {
    ...migrateBlockComponentV1(textBlockComponent),
    componentType: BlockComponentType.Paragraph,
    title: migrateTextContentV1(text),
    subText: null,
    size: validateSizeStyle(migratedSize) ? migratedSize : SizeStyle.Default,
    align: validateAlginStyle(migratedAlign) ? migratedAlign : AlignStyle.Left,
  };
}

export function migrateTextDoubleBlockComponentV1(
  textDoubleBlockComponent: TextDoubleBlockComponent
): ParagraphBlockComponent {
  const { title, subText, size, align } = textDoubleBlockComponent;

  const migratedSize = migrateSizeStyleV1(size);
  const migratedAlign = migrateAlignStyleV1(align);

  return {
    ...migrateBlockComponentV1(textDoubleBlockComponent),
    componentType: BlockComponentType.Paragraph,
    title: migrateTextContentV1(title),
    subText: migrateTextContentV1(subText),
    size: validateSizeStyle(migratedSize) ? migratedSize : SizeStyle.Default,
    align: validateAlginStyle(migratedAlign) ? migratedAlign : AlignStyle.Left,
  };
}

export function validateParagraphBlockComponent(
  param: unknown
): param is ParagraphBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Paragraph &&
    validateParagraphBlockComponentContent(param) &&
    validateParagraphBlockComponentStyle(param)
  );
}
