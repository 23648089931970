import { SiteDesignPageLinkType } from '@flex-apis/recruiting';
import { Link } from '@flex-packages/router';
import type { ComponentProps } from 'react';
import { useMemo } from 'react';

import type {
  PageComponent,
  PublicJobDescriptionDetail,
} from '../../../../base';
import {
  isInternalSubPageComponent,
  isJobDescriptionListPageComponent,
  isMainPageComponent,
  StaticUrlPagePath,
  validateSiteDesignLink,
} from '../../../../base';
import { isSameUrlPagePath } from '../../../../base/site-design/logics';
import { useRendererProps } from '../../contexts';
import {
  getCurrentPageComponent,
  isVisiblePageInNavigation,
  parseToInternalRelativeUrl,
} from '../../logics';

import { BlockComponentPageContentRenderer } from './block-component-page-content/BlockComponentPageContentRenderer';
import { JobDescriptionDetailContentRenderer } from './job-description-detail-page-content/JobDescriptionDetailContentRenderer';
import { JobDescriptionPageContentRenderer } from './job-description-page-content/JobDescriptionPageContentRenderer';
import { PageLayout } from './layout/page-layout';

interface Props
  extends Omit<ComponentProps<typeof PageLayout>, 'children' | 'css'> {
  jobDescription?: PublicJobDescriptionDetail;
}

export function PageRenderer({ jobDescription, ...pageLayoutProps }: Props) {
  const { siteDesign, urlPagePath, isHeaderExpended, setHeaderExpended } =
    useRendererProps();

  const currentPageComponent = getCurrentPageComponent(siteDesign, urlPagePath);
  const currentPageContentRenderer = useMemo(() => {
    if (
      currentPageComponent === undefined ||
      currentPageComponent.enabled === false
    ) {
      return null;
    }

    if (
      isMainPageComponent(currentPageComponent) ||
      isInternalSubPageComponent(currentPageComponent)
    ) {
      return (
        <BlockComponentPageContentRenderer
          blockComponentPageContent={
            currentPageComponent.blockComponentPageContent
          }
        />
      );
    }

    if (isJobDescriptionListPageComponent(currentPageComponent)) {
      return jobDescription ? (
        <JobDescriptionDetailContentRenderer jobDescription={jobDescription} />
      ) : (
        <JobDescriptionPageContentRenderer
          customerIdHash={siteDesign.customerIdHash}
          jobDescriptionPageContent={
            currentPageComponent.jobDescriptionPageContent
          }
        />
      );
    }
  }, [currentPageComponent, siteDesign.customerIdHash, jobDescription]);

  const isActiveMenu = (pageComponent: PageComponent) => {
    return isSameUrlPagePath(
      currentPageComponent?.urlPagePath,
      pageComponent.urlPagePath
    );
  };

  const getMenuLink = (pageComponent: PageComponent) => {
    switch (pageComponent.linkType) {
      case SiteDesignPageLinkType.Internal:
        return pageComponent.urlPagePath;
      case SiteDesignPageLinkType.External:
        return pageComponent.href;
    }
  };

  return (
    <PageLayout
      {...pageLayoutProps}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {/* Page Header */}
      <PageLayout.Header
        isExpanded={isHeaderExpended}
        onExpandChange={setHeaderExpended}
        css={{ flexGrow: 0, flexShrink: 0 }}
      >
        <PageLayout.Header.Content
          navigation={
            <PageLayout.Header.Navigation>
              {siteDesign.pages.filter(isVisiblePageInNavigation).map(page => (
                <PageLayout.Header.Menu
                  key={`${page.title}-${page.type}-${getMenuLink(page)}`}
                  isActive={isActiveMenu(page)}
                  siteDesignPageLinkType={page.linkType}
                  link={getMenuLink(page)}
                >
                  {page.title}
                </PageLayout.Header.Menu>
              ))}
            </PageLayout.Header.Navigation>
          }
        >
          <Link
            href={parseToInternalRelativeUrl({
              urlPagePath: StaticUrlPagePath.Main,
            })}
          >
            <PageLayout.Header.Title logoImageUrl={siteDesign.logoImgUrl}>
              {siteDesign.headerTitle}
            </PageLayout.Header.Title>
          </Link>
        </PageLayout.Header.Content>
      </PageLayout.Header>

      {/* Page Body */}
      <PageLayout.Body css={{ flexGrow: 1, flexShrink: 0 }}>
        {currentPageContentRenderer}
      </PageLayout.Body>

      {/* Page Footer */}
      <PageLayout.Footer css={{ flexGrow: 0, flexShrink: 0 }}>
        <PageLayout.Footer.Content
          customerName={siteDesign.customerName}
          customerAddress={siteDesign.customerAddress}
          customerTelephoneNumber={siteDesign.customerTelephoneNumber}
          representativeEmail={siteDesign.representativeEmail}
          footerLinks={siteDesign.footerLinks.filter(validateSiteDesignLink)}
          privacyPolicyUrl={siteDesign.privacyPolicyUrl}
        />
      </PageLayout.Footer>
    </PageLayout>
  );
}
