import {
  Button,
  Dialog,
  IconButton,
  Legacy_Dropdown,
} from '@flex-design-system/fx';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  MoreVerticalIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Stack } from '@flex-design-system/utils-style';
import { useBooleanState } from '@flex-packages/hooks-common';
import { Translation, useTranslation } from '@flex-packages/i18n';

interface ItemHeaderControlButtonGroupProps {
  title: string;
  expanded?: boolean;
  onItemDelete?: VoidFunction;
}

function ItemHeaderControlButtonGroup({
  title,
  expanded,
  onItemDelete,
}: ItemHeaderControlButtonGroupProps) {
  const { t } = useTranslation();

  const [isDialogOpen, handleOpenDialog, handleCloseDialog] =
    useBooleanState(false);

  const handleDeleteItem = () => {
    onItemDelete?.();
    handleCloseDialog();
  };

  return (
    <Dialog open={isDialogOpen}>
      <Dialog.Content>
        <Dialog.Body>
          <Dialog.UnbalancedTitle>{title}</Dialog.UnbalancedTitle>
        </Dialog.Body>
        <Dialog.Footer>
          <Dialog.Action asChild>
            <Button
              variant="outline"
              size="large"
              color="gray"
              onClick={handleCloseDialog}
            >
              <Translation tKey="global.취소" />
            </Button>
          </Dialog.Action>
          <Dialog.Action asChild>
            <Button
              variant="solid"
              size="large"
              danger
              onClick={handleDeleteItem}
            >
              <Translation tKey="global.삭제하기" />
            </Button>
          </Dialog.Action>
        </Dialog.Footer>
      </Dialog.Content>
      <Stack.Horizontal align="center" space={10}>
        <Legacy_Dropdown>
          <Legacy_Dropdown.Trigger asChild>
            <IconButton
              size="small"
              variant="ghost"
              description={t('global.icon_button.description.더_보기')}
              icon={<MoreVerticalIcon />}
            />
          </Legacy_Dropdown.Trigger>
          <Legacy_Dropdown.Content>
            <Legacy_Dropdown.Item danger onClick={handleOpenDialog}>
              <Legacy_Dropdown.ItemTitle leftSlot={<DeleteIcon />}>
                <Translation tKey="global.삭제하기" />
              </Legacy_Dropdown.ItemTitle>
            </Legacy_Dropdown.Item>
          </Legacy_Dropdown.Content>
        </Legacy_Dropdown>
        {expanded ? (
          <ChevronDownIcon size={22} color="gray" />
        ) : (
          <ChevronRightIcon size={22} color="gray" />
        )}
      </Stack.Horizontal>
    </Dialog>
  );
}

export default ItemHeaderControlButtonGroup;
