import { getSiteJobDescriptionsForPublicRemote } from '@flex-apis/recruiting';
import { useGetSiteJobDescriptionsForPublicRemote } from '@flex-apis/recruiting-hooks';
import { useQueryActionsFromRemote } from '@flex-packages/remote-hooks';
import { useMemo } from 'react';

import type { PublicJobDescriptionFilter } from '../../../../../base';
import {
  filterPublicJobDescription,
  generatePublicJobDescription,
} from '../../../../../base';

interface Props extends PublicJobDescriptionFilter {
  customerIdHash: string;
}

interface Options {
  suspense?: boolean;
}

export default function usePublicJobDescriptions(
  props: Props,
  options?: Options
) {
  const { customerIdHash, ...filter } = props;
  const { invalidate } = useQueryActionsFromRemote({
    remote: getSiteJobDescriptionsForPublicRemote,
    extraParams: { customerIdHash },
  });
  const { data, ...remain } = useGetSiteJobDescriptionsForPublicRemote(
    { customerIdHash },
    options
  );

  const { titleContains, jobGroupIdHash, contractType } = filter;
  const publicJobDescriptions = useMemo(
    () =>
      data?.jobDescriptions
        .map(generatePublicJobDescription)
        .filter(publicJobDescription =>
          filterPublicJobDescription(publicJobDescription, {
            titleContains,
            jobGroupIdHash,
            contractType,
          })
        ),
    [data, titleContains, jobGroupIdHash, contractType]
  );

  return { publicJobDescriptions, ...remain, invalidate };
}
