import { Typography } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import type { TextContent } from '../../../../../../base';

import type { TextStyle } from './style';
import { getTextCSS } from './style';

interface Props extends TextStyle {
  content: TextContent;
}

export function TextContentViewer({ content, ...testStyle }: Props) {
  return (
    <StyledTypography css={getTextCSS(testStyle)}>
      {content.text}
    </StyledTypography>
  );
}

const StyledTypography = styled(Typography, {
  whiteSpace: 'pre-wrap',
  wordBreak: 'keep-all',
  overflowWrap: 'break-word',
});
