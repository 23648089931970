import { mebibyteToByte } from '@flex-packages/utils';

const candidateAttachmentRules = {
  allowedMimeTypes: [
    'application/pdf',

    /**
     * https://flex-cv82520.slack.com/archives/C06EKU4BLSV/p1723519845581299?thread_ts=1723519800.215269&cid=C06EKU4BLSV
     */
    'application/haansoftpdf',
  ],
  maxFileSize: mebibyteToByte(100),
};

export { candidateAttachmentRules };
