import type { AxiosContextProps } from '@flex-packages/remote-hooks';
import { AxiosContext } from '@flex-packages/remote-hooks';
import type { PropsWithChildren } from 'react';

export function AxiosContextProvider({
  instances,
  children,
}: PropsWithChildren<AxiosContextProps>) {
  return (
    <AxiosContext.Provider value={{ instances }}>
      {children}
    </AxiosContext.Provider>
  );
}
