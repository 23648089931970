import { FileUpload } from '@flex-design-system/fx-extension';
import { CloudUploadIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation } from '@flex-packages/i18n';
import type { FileType } from '@flex-services/file/hooks';

import { candidateAttachmentRules } from '../../../../base';

import { DeleteButton } from './DeleteButton';
import { FileEmptyView } from './FileEmptyView';
import { FilePreview } from './FilePreview';
import { useFileDocumentField } from './hooks';
import { DropZone, OverlayFallback, Trigger } from './style';

interface FileDocumentFieldProps {
  file?: FileType;
  onFileChange?: (file?: FileType) => void;
}

export const FileDocumentField = ({
  file,
  onFileChange,
}: FileDocumentFieldProps) => {
  const { handleUpload } = useFileDocumentField({ file, onFileChange });

  return (
    <FileUpload
      accept={candidateAttachmentRules.allowedMimeTypes.join(',')}
      onFilesChange={handleUpload}
    >
      <DropZone>
        <Trigger asChild>
          {file ? <FilePreview file={file} /> : <FileEmptyView />}
        </Trigger>
        {file && <DeleteButton onClick={onFileChange} />}
        <FileUpload.DropOverlay css={{ borderRadius: '6px' }}>
          <OverlayFallback>
            <CloudUploadIcon size={22} />
            <Translation tKey="recruiting.attachment.file.overlay" />
          </OverlayFallback>
        </FileUpload.DropOverlay>
      </DropZone>
    </FileUpload>
  );
};
