import { Flex } from '@flex-design-system/fx';
import { CandidateDocumentField } from '@flex-private-packages/recruiting-common/components';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';
import { validateDocumentsField } from '../models';

import PublicFileDocumentField from './PublicFileDocumentField';

export function AttachmentDocumentField() {
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();
  const { fields } = useFieldArray({ control, name: 'documents' });

  return (
    <Flex direction="column" align="stretch" css={{ gap: 24 }}>
      {fields.map(({ id, isRequired }, index) => (
        <Controller
          key={id}
          control={control}
          name={`documents.${index}`}
          rules={{ validate: validateDocumentsField, required: isRequired }}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <CandidateDocumentField
              variant="solid"
              state={error ? 'error' : 'default'}
              id={id}
              value={value}
              onValueChange={newValue => {
                onChange(newValue);
                clearErrors(`documents.${index}`);
              }}
              FileDocumentFieldComponent={PublicFileDocumentField}
              ref={ref}
            />
          )}
        />
      ))}
    </Flex>
  );
}
