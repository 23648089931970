import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import type { ParagraphBlockComponent } from '../../../../../../base';
import { SizeStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

interface Props {
  size: ParagraphBlockComponent['size'];

  title: JSX.Element;
  subText: JSX.Element | null;
}

export function ParagraphBlockComponentLayout({ size, title, subText }: Props) {
  return (
    <ParagraphBlockComponentLayoutRoot
      sizeStyle={size}
      direction="column"
      align="stretch"
    >
      {title}
      {subText}
    </ParagraphBlockComponentLayoutRoot>
  );
}

const ParagraphBlockComponentLayoutRoot = styled(Flex, {
  variants: {
    sizeStyle: {
      [SizeStyle.Large]: {
        py: 80,
        gap: 24,
        ...getMobileStyle({
          py: 60,
          gap: 20,
        }),
      },
      [SizeStyle.Default]: {
        py: 60,
        gap: 24,
        ...getMobileStyle({
          py: 50,
          gap: 12,
        }),
      },
      [SizeStyle.Small]: {
        py: 40,
        gap: 14,
        ...getMobileStyle({
          py: 24,
          gap: 16,
        }),
      },
    },
  },
});
