import type { Legacy_SelectProps } from '@flex-design-system/fx';
import { Legacy_Select } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';

import { VeteranWhether } from '../../base';

interface VerteranSelectProps extends Legacy_SelectProps<VeteranWhether> {
  veteran?: VeteranWhether;
  onVeteranChange: (newVeteran: VeteranWhether) => void;
}

function VeteranSelect({
  children,
  veteran,
  onVeteranChange,
  ...props
}: VerteranSelectProps) {
  return (
    <Legacy_Select<VeteranWhether>
      {...props}
      value={veteran}
      onValueChange={onVeteranChange}
    >
      <Legacy_Select.Trigger asChild>{children}</Legacy_Select.Trigger>
      <Legacy_Select.Content onCloseAutoFocus={e => e.preventDefault()}>
        <Legacy_Select.Option value={VeteranWhether.Included}>
          <Legacy_Select.OptionTitle>
            <Translation tKey="recruiting.veteran.included" />
          </Legacy_Select.OptionTitle>
        </Legacy_Select.Option>
        <Legacy_Select.Option value={VeteranWhether.NotIncluded}>
          <Legacy_Select.OptionTitle>
            <Translation tKey="recruiting.veteran.not_included" />
          </Legacy_Select.OptionTitle>
        </Legacy_Select.Option>
      </Legacy_Select.Content>
    </Legacy_Select>
  );
}

export default VeteranSelect;
