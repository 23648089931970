import type { TextContent } from '../../block-v1-legacy';
import { BlockContentType, validateTextContent } from '../../block-v1-legacy';

import type { PageContent, PageContentTypeValue } from './page-content';
import { PageContentType } from './page-content';

export interface JobDescriptionV1PageContent extends PageContent {
  readonly type: PageContentTypeValue<'JobDescriptionListVersion1'>;
  readonly content: TextContent;
}

export function parseJobDescriptionV1PageContentString(
  str: string
): JobDescriptionV1PageContent {
  const { type, content } = JSON.parse(str);
  if (type !== PageContentType.JobDescriptionListVersion1) {
    throw new Error(
      '[parseJobDescriptionV1PageContentString] 잘못된 version 입니다.'
    );
  }
  if (!validateTextContent(content)) {
    throw new Error(
      '[parseJobDescriptionV1PageContentString] 잘못된 content 입니다.'
    );
  }

  return { type, content };
}

export function generateDefaultJobDescriptionV1PageContent(): JobDescriptionV1PageContent {
  return {
    type: PageContentType.JobDescriptionListVersion1,
    content: {
      contentType: BlockContentType.Text,
      text: '우리 팀에\n지금 합류하세요',
    },
  };
}
