import type {
  ListVerticalBlockComponent,
  ListVerticalBlockItem,
} from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(props: {
  align: ListVerticalBlockComponent['align'];
  item: Pick<ListVerticalBlockItem, 'media'>;
}): TextStyle {
  const { align, item } = props;

  return {
    align: item.media ? AlignStyle.Left : align,
    color: 'grayDarker',
    size: item.media ? 32 : 36,
    mobileSize: item.media ? 20 : 24,
    weight: 700,
    lineHeight: 1.3,
    mobileLineHeight: 1.5,
    letterSpacing: item.media ? '-0.96px' : '-1.08px',
    mobileLetterSpacing: item.media ? '-0.6px' : '-0.72px',
  };
}

export function getSubTextStyle(props: {
  align: ListVerticalBlockComponent['align'];
  item: Pick<ListVerticalBlockItem, 'media'>;
}): TextStyle {
  const { align, item } = props;

  return {
    align: item.media ? AlignStyle.Left : align,
    color: 'grayLight',
    size: 17,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.7,
    letterSpacing: '-0.17px',
    mobileLetterSpacing: '-0.16px',
  };
}
