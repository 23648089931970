import { Primitive } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import type {
  BlockComponent,
  BlockComponentV2PageContent,
} from '../../../../../base';
import { BlockComponentType } from '../../../../../base';
import {
  AboutBlockComponentRenderer,
  BannerBlockComponentRenderer,
  ColumnsBlockComponentRenderer,
  CoverBlockComponentRenderer,
  CoverCompatibleBlockComponentRenderer,
  CoverHalfBlockComponentRenderer,
  ErrorBlockComponentRenderer,
  GalleryBlockComponentRenderer,
  ListHorizontalBlockComponentRenderer,
  ListVerticalBlockComponentRenderer,
  ParagraphBlockComponentRenderer,
  QuoteBlockComponentRenderer,
} from '../../block-editor';

interface Props {
  blockComponentPageContent: BlockComponentV2PageContent;
}

export function BlockComponentPageContentRenderer({
  blockComponentPageContent,
}: Props) {
  return (
    <BlockComponentPageContentRendererRoot>
      {blockComponentPageContent.content?.map(blockComponent => (
        <BlockComponentRendererFactory
          key={blockComponent.id}
          blockComponent={blockComponent}
        />
      ))}
    </BlockComponentPageContentRendererRoot>
  );
}

interface FactoryProps {
  blockComponent: BlockComponent;
}

function BlockComponentRendererFactory({ blockComponent }: FactoryProps) {
  switch (blockComponent.componentType) {
    case BlockComponentType.About:
      return <AboutBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Banner:
      return <BannerBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Columns:
      return <ColumnsBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Cover:
      return <CoverBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.CoverHalf:
      return <CoverHalfBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.CoverCompatible:
      return <CoverCompatibleBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Gallery:
      return <GalleryBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.ListVertical:
      return <ListVerticalBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.ListHorizontal:
      return <ListHorizontalBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Paragraph:
      return <ParagraphBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Quote:
      return <QuoteBlockComponentRenderer model={blockComponent} />;
    case BlockComponentType.Error:
      return <ErrorBlockComponentRenderer />;
  }
}

const BlockComponentPageContentRendererRoot = styled(Primitive.div, {
  isolation: 'isolate',
});
