import { XmarkCircleIcon } from '@flex-design-system/legacy-flex-icons';
import { useTranslation } from '@flex-packages/i18n';
import type { FileType } from '@flex-services/file/hooks';

import { Container } from './style';

interface DeleteButtonProps {
  onClick?: (file?: FileType) => void;
}

export const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  const { t } = useTranslation();

  return (
    <Container
      size="small"
      variant="ghost"
      icon={<XmarkCircleIcon type="fill" color="grayLighter" />}
      description={t('recruiting.attachment.file.delete')}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }}
    />
  );
};
