import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { PropsWithChildren } from 'react';

import type { QuoteBlockComponent } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

interface Props {}

function QuoteBlockComponentLayout({ children }: PropsWithChildren<Props>) {
  return (
    <QuoteBlockComponentLayoutRoot align="stretch">
      {children}
    </QuoteBlockComponentLayoutRoot>
  );
}

interface ItemProps {
  align: QuoteBlockComponent['align'];
  media: JSX.Element;
  title: JSX.Element;
  subText: JSX.Element;
}

function QuoteBlockComponentLayoutItem({
  align,
  media,
  title,
  subText,
}: ItemProps) {
  return (
    <QuoteBlockComponentLayoutItemRoot alignStyle={align} direction="column">
      <MediaContentWrapper>
        <MediaContentWrapperInner>{media}</MediaContentWrapperInner>
      </MediaContentWrapper>
      <ItemTextWrapper direction="column" align="stretch">
        {title}
        {subText}
      </ItemTextWrapper>
    </QuoteBlockComponentLayoutItemRoot>
  );
}

const MediaContentWrapperInner = styled('div', {
  position: 'absolute',
  width: '100%',
  height: '100%',

  borderRadius: '50%',
  overflow: 'hidden',
});

const MediaContentWrapper = styled('div', {
  position: 'relative',
  width: 160,
  height: 0,
  paddingBottom: 160,

  ...getMobileStyle({
    width: 120,
    paddingBottom: 120,
  }),
});

const ItemTextWrapper = styled(Flex, {
  width: '100%',

  gap: 10,
  ...getMobileStyle({ gap: 12 }),
});

const QuoteBlockComponentLayoutItemRoot = styled(Flex, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 1,
  gap: 40,

  variants: {
    alignStyle: {
      [AlignStyle.Left]: {
        '&&': { alignItems: 'flex-start' },
      },
      [AlignStyle.Center]: {
        '&&': { alignItems: 'center' },
      },
      [AlignStyle.Right]: {
        '&&': { alignItems: 'flex-end' },
      },
    },
  },
});

const QuoteBlockComponentLayoutRoot = styled(Flex, {
  '&&': {
    flexDirection: 'row',
    gap: 40,
    py: 60,
  },

  ...getMobileStyle({
    '&&&': {
      flexDirection: 'column',
      gap: 60,
    },
  }),
});

QuoteBlockComponentLayout.Item = QuoteBlockComponentLayoutItem;

export default QuoteBlockComponentLayout;
