import { Flex, IconButton, PrimitiveInput } from '@flex-design-system/fx';
import { XmarkCircleIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import type { ComponentProps } from 'react';

interface Props
  extends Omit<ComponentProps<typeof PrimitiveInput>, 'placeholder'> {
  onClear: () => void;
}

function UrlDocumentField({ onClear, ...props }: Props) {
  const { t } = useTranslation();

  return (
    <Flex align="center" justify="between" css={{ height: '100%' }}>
      <PrimitiveInput
        {...props}
        placeholder={t('recruiting.attachment.link.edit.placeholder')}
        css={{ width: '100%' }}
      />
      {props.value && (
        <IconButton
          variant="ghost"
          size="small"
          icon={<XmarkCircleIcon type="fill" color="grayLighter" />}
          description={
            <Translation tKey="recruiting.attachment.link.delete.short" />
          }
          onClick={e => {
            e.stopPropagation();
            onClear();
          }}
        />
      )}
    </Flex>
  );
}

export default UrlDocumentField;
