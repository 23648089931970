import { FileUpload } from '@flex-design-system/fx-extension';
import { styled } from '@flex-design-system/stitches-react';

export const DropZone = styled(FileUpload.DropZone, {
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Trigger = styled(FileUpload.Trigger, {
  height: '100%',
});

export const OverlayFallback = styled('div', {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$cosmicBlue',
  columnGap: '6px',
});
