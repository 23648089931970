import { getSessionStorage } from '@flex-packages/browser-storage';
import { keys } from '@flex-packages/utils';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import type { PublicJobDescriptionDetail } from '../../../../../base';
import type {
  JobDescriptionFormState,
  JobDescriptionFormStateJSON,
} from '../models';
import {
  generateDefaultJobDescriptionFormState,
  parseJobDescriptionFormStateToJSON,
  parseJSONToJobDescriptionFormState,
} from '../models';

const TIMEOUT = 10 * 60 * 1000;

interface Props {
  jobDescriptionIdHash: string;
}

interface JSONData extends Partial<JobDescriptionFormStateJSON> {
  __timeStamp__?: number;
}

const getSessionStorageKey = (jobDescriptionIdHash: string) =>
  `apply-form.${jobDescriptionIdHash}`;

function clearSessionStorage(jobDescriptionIdHash: string) {
  getSessionStorage({
    namespace: 'publicRecruiting',
  })?.removeItem(getSessionStorageKey(jobDescriptionIdHash));
}

export function SessionStorageAutoSaver({ jobDescriptionIdHash }: Props) {
  const { control } = useFormContext<JobDescriptionFormState>();

  // NOTE: useWatch를 쓸 경우 Deep Partial 이슈가 있어서 Partial로 타입을 강제로 바꿔줌
  const watchedFormState = useWatch({
    control,
  }) as Partial<JobDescriptionFormState>;
  const sessionStorageRef = useRef(
    getSessionStorage({ namespace: 'publicRecruiting' })
  );

  useEffect(() => {
    if (keys(watchedFormState).length === 0) {
      return;
    }

    const jsonData: JSONData = {
      ...parseJobDescriptionFormStateToJSON(watchedFormState),
      __timeStamp__: Date.now(),
    };

    sessionStorageRef.current?.setItem(
      getSessionStorageKey(jobDescriptionIdHash),
      JSON.stringify(jsonData)
    );
  }, [jobDescriptionIdHash, watchedFormState]);

  return <></>;
}

interface useProps {
  jobDescription: PublicJobDescriptionDetail;
}

function useSessionStorageAutoSaver({ jobDescription }: useProps) {
  const sessionStorageRef = useRef(
    getSessionStorage({ namespace: 'publicRecruiting' })
  );

  const getDefaultValue = useCallback((): Partial<JobDescriptionFormState> => {
    const defaultValue = generateDefaultJobDescriptionFormState(jobDescription);
    const formStateJSONString = sessionStorageRef.current?.getItem<string>(
      getSessionStorageKey(jobDescription.jobDescriptionIdHash)
    );
    if (!formStateJSONString) {
      return defaultValue;
    }

    try {
      const jsonData: JSONData = JSON.parse(formStateJSONString);
      const { __timeStamp__ = 0, ...formStateJSON } = jsonData;
      const currTimestamp = Date.now();

      if (TIMEOUT && currTimestamp - __timeStamp__ > TIMEOUT) {
        clearSessionStorage(jobDescription.jobDescriptionIdHash);
        return defaultValue;
      }

      const storedFormState = parseJSONToJobDescriptionFormState(formStateJSON);
      return {
        ...storedFormState,
        ...defaultValue,
      };
    } catch (e) {
      return defaultValue;
    }
  }, [jobDescription]);

  const clear = useCallback(() => {
    clearSessionStorage(jobDescription.jobDescriptionIdHash);
  }, [jobDescription]);

  return { getDefaultValue, clear };
}

SessionStorageAutoSaver.use = useSessionStorageAutoSaver;
