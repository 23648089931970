import {
  SiteDesignPageType,
  type SiteDesignPageResponse,
} from '@flex-apis/recruiting';
import type { Nullable } from '@flex-packages/types';
import { isNil } from '@flex-packages/utils';

import { urlPagePathMigrationHelper } from './urlPagePath';

export function isHomePageComponent(pageComponent: SiteDesignPageResponse) {
  return pageComponent.type === SiteDesignPageType.Home;
}

export function isSameUrlPagePath(
  targetUrlPagePath?: Nullable<string>,
  urlPathPath?: Nullable<string>
) {
  if (isNil(targetUrlPagePath) || isNil(urlPathPath)) return false;

  return (
    urlPagePathMigrationHelper.toMutationRequest({
      type: SiteDesignPageType.Custom,
      urlPagePath: targetUrlPagePath,
    }) ===
    urlPagePathMigrationHelper.toMutationRequest({
      type: SiteDesignPageType.Custom,
      urlPagePath: urlPathPath,
    })
  );
}
