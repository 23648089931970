import { Divider, Flex, IconButton, Typography } from '@flex-design-system/fx';
import { ArrowBackwardIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { useTranslation } from '@flex-packages/i18n';
import { Link } from '@flex-packages/router';
import { isNil } from '@flex-packages/utils';
import { RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT } from '@flex-private-packages/recruiting-common/base';
import { useJobDescriptionRangeString } from '@flex-private-packages/recruiting-common/hooks';
import { Fragment } from 'react';

import type { PublicJobDescriptionDetail } from '../../../../../base';
import { StaticUrlPagePath } from '../../../../../base';
import { parseToInternalRelativeUrl } from '../../../logics';
import { getMobileStyle } from '../../../utils';

import { ContentSection } from './ContentSection';

interface Props {
  jobDescription: PublicJobDescriptionDetail;
}

export function Hero({ jobDescription }: Props) {
  const { t } = useTranslation();
  const range = useJobDescriptionRangeString({ ...jobDescription });

  const tokens = [
    range,
    jobDescription.contractType &&
      t(
        RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT[jobDescription.contractType]
          .i18n
      ),
    jobDescription.jobRoleName,
  ].filter((token): token is string => !isNil(token) && token !== '');

  return (
    <Wrapper>
      <Link
        style={{ marginLeft: -9 }}
        href={parseToInternalRelativeUrl({
          urlPagePath: StaticUrlPagePath.JobDescriptionList,
        })}
        passHref
      >
        <IconButton variant="ghost" icon={<ArrowBackwardIcon />} />
      </Link>
      <Flex direction="column" css={{ gap: 16 }}>
        <Title>{jobDescription.title}</Title>
        <Flex direction="row" align="center" css={{ gap: 12 }}>
          {tokens.map((token, index) => {
            const isLast = index === tokens.length - 1;

            return (
              <Fragment key={`${token}_${index}`}>
                <TokenText color="gray" lineHeight="paragraph">
                  {token}
                </TokenText>
                {isLast ? null : (
                  <Divider orientation="vertical" color="blackAlpha010" />
                )}
              </Fragment>
            );
          })}
        </Flex>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled(ContentSection, {
  position: 'relative',
  paddingTop: 100,

  ...getMobileStyle({
    paddingTop: 50,
  }),
});

const Title = styled('h1', {
  fontWeight: 700,
  fontSize: 40,
  lineHeight: 1.4,
  color: '$grayDark',
  whiteSpace: 'pre-line',
  letterSpacing: '-0.04em',

  ...getMobileStyle({
    fontSize: 24,
  }),
});

const TokenText = styled(Typography, {
  '&&&': {
    fontSize: 16,

    ...getMobileStyle({ fontSize: 14 }),
  },
});
