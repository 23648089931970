import type { ListHorizontalBlockComponent } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<ListHorizontalBlockComponent, 'align' | 'items'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayDarker',
    size: isMultiItem(model) ? 22 : 32,
    mobileSize: 20,
    weight: 700,
    lineHeight: 1.5,
    letterSpacing: isMultiItem(model) ? '-0.66px' : '-0.96px',
    mobileLetterSpacing: '-0.6px',
  };
}

export function getSubTextStyle(
  model: Pick<ListHorizontalBlockComponent, 'align' | 'items'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayLight',
    size: isMultiItem(model) ? 15 : 17,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.7,
    letterSpacing: isMultiItem(model) ? '-0.15px' : '-0.51px',
    mobileLetterSpacing: '-0.48px',
  };
}

function isMultiItem(
  model: Pick<ListHorizontalBlockComponent, 'items'>
): boolean {
  return model.items.length > 1;
}
