import { Button, PrimitiveAnchor } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { Link } from '@flex-packages/router';

import type { LinkContent } from '../../../../../../base';
import { LinkType } from '../../../../../../base';
import { parseToInternalRelativeUrl } from '../../../../logics';
import { LINK_CONTENT_CLASS_NAME } from '../../constants';

import type { LinkStyle } from './style';
import { getButtonStyleProps } from './style';

interface Props extends LinkStyle {
  content: LinkContent;
  disabled?: boolean;
}

export function LinkContentViewer({ content, disabled, ...linkStyle }: Props) {
  const button = (
    <InnerButton
      {...getButtonStyleProps(linkStyle)}
      className={LINK_CONTENT_CLASS_NAME}
      disabled={disabled}
    >
      {content.text}
    </InnerButton>
  );

  if (disabled) {
    return button;
  }

  if (content.linkType === LinkType.External) {
    return <Anchor href={content.href}>{button}</Anchor>;
  }

  return (
    <Anchor asChild>
      <Link
        href={parseToInternalRelativeUrl({
          urlPagePath: content.href,
        })}
        passHref
      >
        {button}
      </Link>
    </Anchor>
  );
}

const Anchor = styled(PrimitiveAnchor, {
  flexShrink: 0,
});

const InnerButton = styled(Button, {
  '&&[aria-disabled="true"]': {
    opacity: 1,
  },
});
