import { TextField } from '@flex-design-system/fx';
import {
  AwardIcon,
  ChevronDownMiniIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { VeteranWhether } from '@flex-private-packages/recruiting-common/base';
import { VeteranSelect } from '@flex-private-packages/recruiting-common/components';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

export function VeteranField() {
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      key="hasVeteransCompensation"
      control={control}
      name="hasVeteransCompensation"
      rules={{
        required: t('recruiting.veteran.edit.helper.required'),
      }}
      render={({
        field: { ref, onChange, value },
        fieldState: { invalid, error },
      }) => {
        const label =
          value === VeteranWhether.Included
            ? t('recruiting.veteran.included')
            : value === VeteranWhether.NotIncluded
              ? t('recruiting.veteran.not_included')
              : '';
        return (
          <VeteranSelect
            open={isOpen}
            onOpenChange={setIsOpen}
            value={value as VeteranWhether}
            onVeteranChange={newValue => {
              onChange(newValue);
              clearErrors('hasVeteransCompensation');
            }}
          >
            {/** onFocus재호출 방지 */}
            <div role="button">
              <TextField
                ref={ref}
                variant="solid"
                id="hasVeteransCompensation"
                enterKeyHint="next"
                required
                css={{ cursor: 'pointer' }}
                onFocus={() => {
                  setIsOpen(true);
                }}
                rightSlot={<ChevronDownMiniIcon style={{ marginRight: 12 }} />}
                label={
                  <TextField.Label leftSlot={<AwardIcon />}>
                    <Translation tKey="recruiting.veteran" />
                  </TextField.Label>
                }
                placeholder={t('recruiting.veteran.edit.placeholder')}
                value={label}
                state={invalid ? 'error' : 'default'}
                helperText={
                  <TextField.HelperText>{error?.message}</TextField.HelperText>
                }
              />
            </div>
          </VeteranSelect>
        );
      }}
    />
  );
}
