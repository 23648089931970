import { format as formatUrl } from '@flex-packages/utils/url';

import type {
  PageComponent,
  PublicJobDescriptionDetail,
  SiteDesign,
} from '../../../base';
import { StaticUrlPagePath } from '../../../base';
import { isSameUrlPagePath } from '../../../base/site-design/logics';
import {
  HrefProtocolWhitelistRegex,
  UrlProtocolWhitelistRegex,
} from '../configs/regex';

export function parseYoutubeUrl(youtubeUrl: string): string | null {
  try {
    const url = new URL(youtubeUrl);

    // https://youtu.be/VhrVc2av0Ws
    if (url.origin === 'https://youtu.be') {
      return url.pathname.substring(1);
    }

    // https://www.youtube.com/watch?v=VhrVc2av0Ws&ab_channel=POCLANOS
    return new URLSearchParams(url.search).get('v');
  } catch {
    return null;
  }
}

export function parseToInternalFullUrl(params: {
  recruitingSiteRootDomain: string;
  subdomain: string;
  urlPagePath: string | StaticUrlPagePath;
  jobDescriptionIdHash?: string;
  protocol?: string;
}): string {
  const {
    recruitingSiteRootDomain,
    subdomain,
    urlPagePath,
    jobDescriptionIdHash,
    protocol = 'https',
  } = params;

  const main = `${protocol}://${subdomain}.${recruitingSiteRootDomain}`;

  if (isSameUrlPagePath(urlPagePath, StaticUrlPagePath.Main)) {
    return main;
  }

  if (isSameUrlPagePath(urlPagePath, StaticUrlPagePath.JobDescriptionList)) {
    const jobDescriptionList = `${main}${StaticUrlPagePath.JobDescriptionList}`;
    return jobDescriptionIdHash
      ? jobDescriptionList + `/${jobDescriptionIdHash}`
      : jobDescriptionList;
  }

  if (urlPagePath) {
    return `${main}/${urlPagePath}`;
  }

  return '#';
}

export function parseToInternalRelativeUrl(params: {
  urlPagePath: string | StaticUrlPagePath;
  jobDescriptionIdHash?: string;
}): string {
  const { urlPagePath, jobDescriptionIdHash } = params;

  if (isSameUrlPagePath(urlPagePath, StaticUrlPagePath.Main)) {
    return '/';
  }

  if (isSameUrlPagePath(urlPagePath, StaticUrlPagePath.JobDescriptionList)) {
    const jobDescriptionList = StaticUrlPagePath.JobDescriptionList;
    return jobDescriptionIdHash
      ? jobDescriptionList + `/${jobDescriptionIdHash}`
      : jobDescriptionList;
  }

  if (urlPagePath) {
    return urlPagePath;
  }

  return '#';
}

export function getCurrentPageComponent(
  siteDesign: SiteDesign,
  urlPagePath: string | StaticUrlPagePath
): PageComponent | undefined {
  return siteDesign.pages.find(page => {
    return isSameUrlPagePath(page.urlPagePath, urlPagePath);
  });
}

export function covertSupportedMenuURL(url: string) {
  try {
    // new URL 인자로 invalid 값이 전달되면 throw 되므로 try-catch
    const parsedURL = new URL(url ?? '');

    parsedURL.protocol = ['http:', 'https:'].includes(parsedURL.protocol)
      ? parsedURL.protocol
      : 'https:';

    return parsedURL.href;
  } catch (e) {
    /**
     * http: https: http:// https://
     * 위의 문자열도 에러 발생할 수 있음
     * */

    if (UrlProtocolWhitelistRegex.test(url)) {
      return url;
    } else {
      // protocol이 없는 경우 https를 붙여줌
      return `https://${url ?? ''}`;
    }
  }
}

export function convertExternalUrl(href: string) {
  try {
    const parsedURL = new URL(href ?? '');

    parsedURL.protocol = ['http:', 'https:', 'mailto:', 'tel:'].includes(
      parsedURL.protocol
    )
      ? parsedURL.protocol
      : 'https:';

    return parsedURL.href;
  } catch (e) {
    if (HrefProtocolWhitelistRegex.test(href)) {
      return href;
    } else {
      // protocol이 없는 경우 https를 붙여줌
      return new URL(`https://${href ?? ''}`).href;
    }
  }
}

export function makeJDUrl({
  origin,
  jobDescription,
}: {
  origin: string;
  jobDescription: PublicJobDescriptionDetail;
}) {
  try {
    const { hostname, protocol, port } = new URL(origin);

    const isDevPort = ['', '80', '443'].includes(port) === false;

    const url = formatUrl({
      hostname,
      port: isDevPort ? port : undefined,
      protocol: protocol as any,
      pathname: parseToInternalRelativeUrl({
        urlPagePath: StaticUrlPagePath.JobDescriptionList,
        jobDescriptionIdHash: jobDescription.jobDescriptionIdHash,
      }),
    });

    const 불필요한_문자가_제거된_제목 = filterMatchedCharacter(
      jobDescription.title,
      VALID_URL_TITLE
    );
    const 공백이_변환된_제목 = 불필요한_문자가_제거된_제목.replaceAll(
      /\s+/g,
      '-'
    );

    return url + `/${공백이_변환된_제목}`;
  } catch (error) {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }

    throw error;
  }
}

// 1. 숫자
// 2. 영문
// 3. 한글
// 4. 대시: -
// 5. 대괄호: [, ]
// 6. 공백
const VALID_URL_TITLE = /[0-9a-zA-Z가-힣-[\] ]/g;

function filterMatchedCharacter(value: string, regexp: RegExp) {
  const filterResult = value.match(regexp);

  if (filterResult === null) {
    return '';
  }

  return filterResult.join('');
}
