import type { TextFieldProps } from '@flex-design-system/fx';
import { TextField } from '@flex-design-system/fx';
import { BuildingIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';

type Props = Omit<TextFieldProps, 'type' | 'variant' | 'label'>;

const WorkExperienceCompanyNameInput = ({ helperText, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <TextField
      type="text"
      variant="solid"
      placeholder={t(
        'recruiting.work_experience.company_name.edit.placeholder'
      )}
      label={
        <TextField.Label_Legacy leftSlot={<BuildingIcon />}>
          <Translation tKey="recruiting.work_experience.company_name" />
        </TextField.Label_Legacy>
      }
      helperText={<TextField.HelperText>{helperText}</TextField.HelperText>}
      {...props}
    />
  );
};

export default WorkExperienceCompanyNameInput;
