import { values } from '@flex-packages/utils';

import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export const LinkType = {
  Internal: 'INTERNAL',
  External: 'EXTERNAL',
} as const;

export type LinkTypeKey = keyof typeof LinkType;
export type LinkTypeValue<Key extends LinkTypeKey = LinkTypeKey> =
  (typeof LinkType)[Key];

export interface LinkContent extends BlockContent {
  readonly contentType: BlockContentTypeValue<'Link'>;

  readonly linkType: LinkTypeValue;
  readonly text: string;
  readonly href: string;
}

export function validateLinkContent(param: unknown): param is BlockContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Link &&
    // has link type
    'linkType' in param &&
    values(LinkType).includes(param.linkType as LinkTypeValue) &&
    // has text
    'text' in param &&
    typeof param.text === 'string' &&
    // has href
    'href' in param &&
    typeof param.href === 'string'
  );
}
