import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export interface TextContent extends BlockContent {
  readonly contentType: BlockContentTypeValue<'Text'>;

  readonly text: string;
}

export function validateTextContent(param: unknown): param is TextContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Text &&
    // has text
    'text' in param &&
    typeof param.text === 'string'
  );
}
