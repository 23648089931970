import { useEffect } from 'react';
import { v4 as uuidV4 } from 'uuid';
import type { SiteAccessLogCreateRequest } from '@flex-apis/recruiting';
import { createSiteAccessLogRemote } from '@flex-apis/recruiting';
import { useAxios } from '@flex-packages/remote-hooks';
import { getLocalStorage } from '@flex-packages/browser-storage';
import { useOnce } from '@flex-packages/hooks-common';

const RECRUITING_ACCESS_ID_LOCAL_STORAGE_KEY = 'RECRUITING_ACCESS_ID';

/**
 * 외부 유입 경로를 기록하는 로그를 서버로 전송하는 hook
 */
export const useSendAccessLog = (
  params: Pick<SiteAccessLogCreateRequest, 'customerIdHash' | 'pageType'>
) => {
  const axiosInstance = useAxios().instances.json;
  const [fetchCreateSiteAccessLog] = createSiteAccessLogRemote(axiosInstance);

  const sendAccessLog = useOnce(async () => {
    try {
      await fetchCreateSiteAccessLog({
        userAgent: window.navigator.userAgent,
        siteAccessLogCreateRequest: {
          ...params,
          domain: window.location.host,
          urlPath: window.location.pathname,
          referer: document.referrer,
          accessId: getAccessId(),
        },
      });
    } catch {
      // 실패할 경우 아무것도 하지 않음.
      console.warn('[useSendAccessLog] Failed to send access log.');
    }
  });

  // 마운트 됬을때 한번 API 호출
  useEffect(() => {
    sendAccessLog();
  }, [sendAccessLog]);
};

const getAccessId = (): string => {
  const localStorage = getLocalStorage({
    namespace: 'recruiting',
  });

  let accessId: string | undefined | null = localStorage?.getItem(
    RECRUITING_ACCESS_ID_LOCAL_STORAGE_KEY
  );

  if (!accessId) {
    accessId = uuidV4();
    localStorage?.setItem(RECRUITING_ACCESS_ID_LOCAL_STORAGE_KEY, accessId);
  }

  return accessId;
};
