import {
  Button,
  Checkbox,
  Flex,
  Tooltip,
  useToast,
} from '@flex-design-system/fx';
import { PlusIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import type { CandidateEducationalBackground } from '@flex-private-packages/recruiting-common/base';
import { CandidateBackgroundList } from '@flex-private-packages/recruiting-common/components';
import { DndKit, useDndKitItem } from '@flex-services/shared-ui/layout';
import { useState } from 'react';
import {
  useController,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';

import { getMobileStyle, mobileOnly, pcOnly } from '../../../../../utils';
import type { JobDescriptionFormState } from '../../../models';
import {
  BackgroundItemContentColumn,
  BackgroundItemContentRow,
  FormSection,
} from '../../../shared';

import {
  EducationAdmissionYearMonthInputFormItem,
  EducationGraduateStatusTypeSelectFormItem,
  EducationGraduationYearMonthInputFormItem,
  EducationInstitutionNameAndTypeFormItem,
  EducationMajorNameInputFormItem,
} from './form-items';
import HeaderInformation from './Information';

const FIRST_ITEM_INDEX = 0;

export function EducationalBackgroundSection() {
  const [selectedItemIndex, setSelectedItemIndex] = useState<
    number | undefined
  >(0);

  const { control, setValue } = useFormContext<JobDescriptionFormState>();
  const noEducationalBackgrounds = useWatch({
    control,
    name: 'noEducationalBackgrounds',
  });
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'educationalBackgrounds',
  });

  const handleChangeSelectedItemIndex = (newIndex: number) => {
    if (newIndex === selectedItemIndex) {
      setSelectedItemIndex(undefined);
      return;
    }

    setSelectedItemIndex(newIndex);
  };

  const handleAddEducationalBackgroundItem = () => {
    append({} as any);
    setSelectedItemIndex(fields.length);
  };

  const handleRemoveEducationalBackgroundItem = (targetIndex: number) => {
    remove(targetIndex);
  };

  return (
    <FormSection
      title={
        <FormSection.Title
          subtext={
            <FormSection.Subtext
              css={{
                ...getMobileStyle({
                  whiteSpace: 'pre-line',
                }),
              }}
            >
              <Translation tKey="recruiting.educational_background.subtext" />
            </FormSection.Subtext>
          }
          rightSlot={
            <Flex align="center" css={{ gap: 16 }}>
              <Tooltip>
                <Tooltip.Trigger asChild>
                  <Checkbox.Label>
                    <Checkbox
                      color="black"
                      checked={noEducationalBackgrounds}
                      onCheckedChange={(newChecked: boolean) =>
                        setValue('noEducationalBackgrounds', newChecked)
                      }
                    />
                    <Translation tKey="recruiting.educational_background.empty" />
                  </Checkbox.Label>
                </Tooltip.Trigger>
                <Tooltip.Content>
                  <Translation tKey="recruiting.educational_background.empty.tip" />
                </Tooltip.Content>
              </Tooltip>
              <Button
                size="small"
                variant="outline"
                color="gray"
                leftSlot={<PlusIcon color="primary" />}
                onClick={handleAddEducationalBackgroundItem}
                css={pcOnly}
                disabled={noEducationalBackgrounds}
              >
                <Translation tKey="recruiting.background_section.add_button" />
              </Button>
            </Flex>
          }
        >
          <Translation tKey="recruiting.educational_background" />
        </FormSection.Title>
      }
    >
      {!noEducationalBackgrounds && (
        <Flex direction="column" align="stretch" css={{ gap: 10 }}>
          <CandidateBackgroundList>
            <DndKit.Group
              items={fields}
              getIdentifier={field => field.id}
              onReorder={(_, oldIndex, newIndex) => {
                move(oldIndex, newIndex);
              }}
              dndContextProps={{
                onDragStart: () => {
                  setSelectedItemIndex(undefined);
                },
              }}
              axis="y"
            >
              {fields.map((value, currentIndex) => {
                return (
                  <ListItem
                    key={value.id}
                    id={value.id}
                    currentIndex={currentIndex}
                    isExpanded={selectedItemIndex === currentIndex}
                    educationalBackground={value}
                    handleChangeSelectedItemIndex={
                      handleChangeSelectedItemIndex
                    }
                    handleRemoveEducationalBackgroundItem={
                      handleRemoveEducationalBackgroundItem
                    }
                  />
                );
              })}
            </DndKit.Group>
          </CandidateBackgroundList>
          <Button
            size="large"
            variant="outline"
            color="gray"
            leftSlot={<PlusIcon color="primary" />}
            onClick={handleAddEducationalBackgroundItem}
            css={{ ...mobileOnly }}
          >
            <Translation tKey="recruiting.background_section.add_button" />
          </Button>
        </Flex>
      )}
    </FormSection>
  );
}

function ListItem({
  id,
  currentIndex,
  educationalBackground,
  isExpanded,
  handleChangeSelectedItemIndex,
  handleRemoveEducationalBackgroundItem,
}: {
  id: string;
  currentIndex: number;
  educationalBackground: CandidateEducationalBackground;
  isExpanded: boolean;
  handleChangeSelectedItemIndex: (newIndex: number) => void;
  handleRemoveEducationalBackgroundItem: (index: number) => void;
}) {
  const { t } = useTranslation();
  const { success } = useToast();

  const { control } = useFormContext<JobDescriptionFormState>();
  const {
    fieldState: { error },
  } = useController({
    control,
    name: `educationalBackgrounds.${currentIndex}`,
    rules: {
      validate: value => {
        // TODO: 각 Filed의 rule과 연동할 수 있는 방안 생각하기
        return Boolean(
          value.name &&
            value.institutionType &&
            value.graduationStatus &&
            value.admissionDate
        );
      },
    },
  });

  const { a11yAttributes, listeners, setNodeRef, style } = useDndKitItem({
    id,
  });

  return (
    <CandidateBackgroundList.Item
      {...a11yAttributes}
      danger={Boolean(error)}
      ref={setNodeRef}
      expanded={isExpanded}
      style={style}
      onCollapse={() => handleChangeSelectedItemIndex(currentIndex)}
      header={
        <CandidateBackgroundList.ItemHeader
          leftSlot={
            <CandidateBackgroundList.ItemHeader.DragButton {...listeners} />
          }
          centerSlot={
            <HeaderInformation
              educationInstitutionName={educationalBackground.name}
              graduationStatus={educationalBackground.graduationStatus}
              isLastEducationalBackground={currentIndex === FIRST_ITEM_INDEX}
            />
          }
          rightSlot={
            <CandidateBackgroundList.ItemHeader.ControlButtonGroup
              title={t('recruiting.educational_background.control.title')}
              expanded={isExpanded}
              onItemDelete={() => {
                handleRemoveEducationalBackgroundItem(currentIndex);
                success(
                  t('recruiting.educational_background.control.toast.success')
                );
              }}
            />
          }
        />
      }
      content={
        <CandidateBackgroundList.ItemContent>
          <BackgroundItemContentColumn
            direction="column"
            align="stretch"
            css={{ gap: 24 }}
          >
            <EducationInstitutionNameAndTypeFormItem
              currentIndex={currentIndex}
            />
            <BackgroundItemContentRow>
              <EducationGraduateStatusTypeSelectFormItem
                currentIndex={currentIndex}
              />
              <EducationMajorNameInputFormItem currentIndex={currentIndex} />
            </BackgroundItemContentRow>
            <BackgroundItemContentRow>
              <EducationAdmissionYearMonthInputFormItem
                currentIndex={currentIndex}
              />
              <EducationGraduationYearMonthInputFormItem
                currentIndex={currentIndex}
              />
            </BackgroundItemContentRow>
          </BackgroundItemContentColumn>
        </CandidateBackgroundList.ItemContent>
      }
    />
  );
}
