import { CandidateWorkExperienceStatus } from '@flex-apis/recruiting';
import type { Legacy_SelectProps } from '@flex-design-system/fx';
import { Button, Legacy_Select } from '@flex-design-system/fx';
import { ChevronDownMiniIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation } from '@flex-packages/i18n';

import { WORK_EXPERIENCE_STATUS_CONTENT } from '../../../base';

type Props = Omit<
  Legacy_SelectProps<CandidateWorkExperienceStatus>,
  'children'
>;

const WORK_EXPERIENCE_STATUS_TYPES: Array<CandidateWorkExperienceStatus> = [
  CandidateWorkExperienceStatus.InEmploy,
  CandidateWorkExperienceStatus.Resignation,
];

const WorkExperienceStatusSelect = (props: Props) => {
  return (
    <Legacy_Select<CandidateWorkExperienceStatus> {...props}>
      <Legacy_Select.Trigger asChild>
        <Button
          type="button"
          variant="outline"
          size="xsmall"
          color="gray"
          rightSlot={<ChevronDownMiniIcon size={16} color="grayLight" />}
        >
          {props.value !== undefined ? (
            <Translation
              tKey={WORK_EXPERIENCE_STATUS_CONTENT[props.value].i18n}
            />
          ) : (
            <Translation tKey="recruiting.background.status.placeholder" />
          )}
        </Button>
      </Legacy_Select.Trigger>
      <Legacy_Select.Content>
        {WORK_EXPERIENCE_STATUS_TYPES.map(type => (
          <Legacy_Select.Option key={type} value={type}>
            <Legacy_Select.OptionTitle>
              <Translation tKey={WORK_EXPERIENCE_STATUS_CONTENT[type].i18n} />
            </Legacy_Select.OptionTitle>
          </Legacy_Select.Option>
        ))}
      </Legacy_Select.Content>
    </Legacy_Select>
  );
};

export default WorkExperienceStatusSelect;
