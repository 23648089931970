import type { ParagraphBlockComponent } from '../../../../../../base';
import { SizeStyle } from '../../../../../../base';
import type { TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<ParagraphBlockComponent, 'align' | 'size'>
): TextStyle {
  switch (model.size) {
    case SizeStyle.Large:
      return {
        align: model.align,
        color: 'grayDarker',
        size: 56,
        mobileSize: 32,
        weight: 700,
        lineHeight: 1.3,
        letterSpacing: '-1.68px',
        mobileLetterSpacing: '-0.96px',
      };
    case SizeStyle.Default:
      return {
        align: model.align,
        color: 'grayDarker',
        size: 32,
        mobileSize: 24,
        weight: 700,
        lineHeight: 1.3,
        letterSpacing: '-0.96px',
        mobileLetterSpacing: '-0.72px',
      };
    case SizeStyle.Small:
      return {
        align: model.align,
        color: 'grayDarker',
        size: 24,
        mobileSize: 20,
        weight: 700,
        lineHeight: 1.5,
        letterSpacing: '-0.72px',
        mobileLetterSpacing: '-0.6px',
      };
  }
}

export function getSubTextStyle(
  model: Pick<ParagraphBlockComponent, 'align' | 'size'>
): TextStyle {
  switch (model.size) {
    case SizeStyle.Large:
      return {
        align: model.align,
        color: 'grayLight',
        size: 24,
        mobileSize: 16,
        weight: 500,
        lineHeight: 1.5,
        letterSpacing: '-0.24px',
        mobileLetterSpacing: '-0.16px',
      };
    case SizeStyle.Default:
      return {
        align: model.align,
        color: 'grayLight',
        size: 17,
        mobileSize: 16,
        weight: 500,
        lineHeight: 1.7,
        mobileLineHeight: 1.6,
        letterSpacing: '-0.17px',
        mobileLetterSpacing: '-0.16px',
      };
    case SizeStyle.Small:
      return {
        align: model.align,
        color: 'grayLight',
        size: 17,
        mobileSize: 14,
        weight: 500,
        lineHeight: 1.6,
        letterSpacing: '-0.72px',
        mobileLetterSpacing: '-0.14px',
      };
  }
}
