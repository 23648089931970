import type {
  BlockComponentTypeValue as BlockComponentTypeValue_V1,
  TextButtonBlockComponent,
} from '../../block-v1-legacy';
import type { LinkContent, TextContent } from '../block-content';
import {
  migrateLinkContentV1,
  migrateTextContentV1,
  validateLinkContent,
  validateTextContent,
} from '../block-content';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  BlockComponentType,
  migrateBlockComponentV1,
  validateBlockComponent,
} from './block-component';

/* ------------- */
/* Block Content */
/* ------------- */

export interface CoverCompatibleBlockComponentContent {
  readonly title: TextContent;
  readonly subText: TextContent;
  readonly link: LinkContent;
}

export function updateCoverCompatibleBlockComponentContent(
  origin: CoverCompatibleBlockComponent,
  content: CoverCompatibleBlockComponentContent
): CoverCompatibleBlockComponent;
export function updateCoverCompatibleBlockComponentContent(
  origin: CoverCompatibleBlockComponent,
  content: unknown
): CoverCompatibleBlockComponent;
export function updateCoverCompatibleBlockComponentContent(
  origin: CoverCompatibleBlockComponent,
  content: unknown
): CoverCompatibleBlockComponent {
  if (!validateCoverCompatibleBlockComponentContent(content)) {
    throw new Error(
      '[updateCoverCompatibleBlockComponentContent]: Invalid content'
    );
  }

  const newContent: CoverCompatibleBlockComponentContent = {
    title: { ...content.title },
    subText: { ...content.subText },
    link: { ...content.link },
  };

  return { ...origin, ...newContent };
}

export function validateCoverCompatibleBlockComponentContent(
  param: unknown
): param is CoverCompatibleBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has link
    'link' in param &&
    validateLinkContent(param.link)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface CoverCompatibleBlockComponent
  extends BlockComponent,
    CoverCompatibleBlockComponentContent {
  readonly componentType: BlockComponentTypeValue<'CoverCompatible'>;
}

export function migrateTextButtonCenterBlockComponentV1(
  textButtonBlockComponent: TextButtonBlockComponent & {
    type: BlockComponentTypeValue_V1<'TextButtonCenter'>;
  }
): CoverCompatibleBlockComponent {
  const { title, subText, link } = textButtonBlockComponent;

  return {
    ...migrateBlockComponentV1(textButtonBlockComponent),
    componentType: BlockComponentType.CoverCompatible,
    title: migrateTextContentV1(title),
    subText: migrateTextContentV1(subText),
    link: migrateLinkContentV1(link),
  };
}

export function validateCoverCompatibleBlockComponent(
  param: unknown
): param is CoverCompatibleBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.CoverCompatible &&
    validateCoverCompatibleBlockComponentContent(param)
  );
}
