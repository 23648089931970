import { Divider, Tabs } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { useNow } from '@flex-packages/datetime-react/hooks';
import { Translation } from '@flex-packages/i18n';
import { useRef, useState } from 'react';

import type { PublicJobDescriptionDetail } from '../../../../../base';
import { useRendererProps } from '../../../contexts';
import { validateSubmitApply } from '../../../logics';
import { JobDescriptionContent } from '../../job-description-content';
import { JobDescriptionForm } from '../../job-description-form';
import { ResponsiveVerticalStack } from '../layout/responsive-stack';

import { ContentSection } from './ContentSection';
import { Hero } from './Hero';

enum AvailableTab {
  Description = 'Description',
  Apply = 'Apply',
}

interface Props {
  jobDescription: PublicJobDescriptionDetail;
}

export function JobDescriptionDetailContentRenderer({ jobDescription }: Props) {
  const tabListRef = useRef<HTMLDivElement>(null);

  const [tab, setTab] = useState(AvailableTab.Description);
  const now = useNow();
  const { siteDesign } = useRendererProps();
  const { valid: isCanApply } = validateSubmitApply(jobDescription, now);

  const scrollIntoTabListIfNeeded = () => {
    const el = tabListRef.current;
    if (el) {
      if (
        'scrollIntoViewIfNeeded' in el &&
        typeof el.scrollIntoViewIfNeeded === 'function'
      ) {
        el.scrollIntoViewIfNeeded();
      } else {
        el.scrollIntoView();
      }
    }
  };

  return (
    <Wrapper align="stretch" space={50} mobileSpaceOverrides={40}>
      <Hero jobDescription={jobDescription} />
      <ContentSection>
        <Tabs
          value={tab}
          onValueChange={newTab => setTab(newTab as AvailableTab)}
        >
          <Tabs.List ref={tabListRef}>
            <Tabs.Legacy_Trigger value={AvailableTab.Description}>
              <Tabs.Legacy_Label>
                <Translation
                  tKey="recruiting.site_builder.job_description.apply.description"
                  style={{ lineHeight: 1.5 }}
                />
              </Tabs.Legacy_Label>
            </Tabs.Legacy_Trigger>
            {isCanApply && (
              <Tabs.Legacy_Trigger value={AvailableTab.Apply}>
                <Tabs.Legacy_Label>
                  <Translation
                    tKey="recruiting.site_builder.job_description.apply.short"
                    style={{ lineHeight: 1.5 }}
                  />
                </Tabs.Legacy_Label>
              </Tabs.Legacy_Trigger>
            )}
          </Tabs.List>
          <Divider />
          <Tabs.Content value={AvailableTab.Description}>
            <JobDescriptionContent
              jobDescription={jobDescription}
              now={now}
              onMoveToApply={() => {
                setTab(AvailableTab.Apply);
                scrollIntoTabListIfNeeded();
              }}
            />
          </Tabs.Content>
          <Tabs.Content value={AvailableTab.Apply}>
            <JobDescriptionForm
              jobDescription={jobDescription}
              customerIdHash={siteDesign.customerIdHash}
              customerName={siteDesign.customerName}
            />
          </Tabs.Content>
        </Tabs>
      </ContentSection>
    </Wrapper>
  );
}

const Wrapper = styled(ResponsiveVerticalStack, {
  isolation: 'isolate',
  py: 40,
});
