import type { ModalProps } from '@flex-design-system/fx';
import {
  Avatar,
  Button,
  Flex,
  Modal,
  Typography,
} from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';
import { Link } from '@flex-packages/router';
import { EmojiView } from '@flex-services/emoji';
import { useFormContext, useWatch } from 'react-hook-form';

import { getMobileStyle } from '../../../utils';
import type { JobDescriptionFormState } from '../models';

interface Props extends ModalProps {
  mainPageLink: string;
}

export function SubmittedModal({ mainPageLink, ...props }: Props) {
  const { control } = useFormContext<JobDescriptionFormState>();
  const name = useWatch({ control, name: 'name' });

  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.ScrollArea
          css={{
            paddingTop: 66,
            paddingBottom: 40,

            ...getMobileStyle({
              minWidth: 'calc(100vw - 40px)',
            }),
          }}
        >
          <Modal.Body>
            <Flex direction="column" align="center" css={{ gap: 24 }}>
              <Avatar color="presetYellow" colorScheme="weak" size={64}>
                <EmojiView code="1f4ab" />
              </Avatar>
              <Flex direction="column" align="center" css={{ gap: 14 }}>
                <Typography
                  size={28}
                  color="grayDark"
                  lineHeight={1.4}
                  weight={700}
                  css={{ textAlign: 'center', wordBreak: 'keep-all' }}
                  asChild
                >
                  <Translation
                    tKey="recruiting.site_builder.job_description.apply.success.title"
                    tOptions={{ values: { name } }}
                  />
                </Typography>
                <Typography
                  size={15}
                  color="gray"
                  lineHeight={1}
                  weight={400}
                  css={{ textAlign: 'center', wordBreak: 'keep-all' }}
                  asChild
                >
                  <Translation tKey="recruiting.site_builder.job_description.apply.success.description" />
                </Typography>
              </Flex>
            </Flex>
          </Modal.Body>
        </Modal.ScrollArea>
        <Modal.Footer>
          <Link href={mainPageLink} passHref>
            <Button fullWidth size="large" color="grayDarker">
              <Translation tKey="recruiting.site_builder.job_description.apply.success.action" />
            </Button>
          </Link>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
