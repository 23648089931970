import { useTranslation } from '@flex-packages/i18n';
import { EducationMajorNameInput } from '@flex-private-packages/recruiting-common/components';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function EducationMajorNameInputFormItem({ currentIndex }: Props) {
  const { t } = useTranslation();

  return (
    <Controller<
      JobDescriptionFormState,
      `educationalBackgrounds.${typeof currentIndex}.majorName`
    >
      name={`educationalBackgrounds.${currentIndex}.majorName`}
      render={({ field }) => (
        <EducationMajorNameInput
          placeholder={t(
            'recruiting.educational_background.major.edit.placeholder'
          )}
          {...field}
        />
      )}
    />
  );
}
