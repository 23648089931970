import { styled } from '@flex-design-system/stitches-react';

import type { JobDescriptionV1PageContent } from '../../../../../base';
import { migrateTextContentV1 } from '../../../../../base';
import { TextContentViewer } from '../../block-content-field';
import { BlockComponentSection } from '../../block-editor';
import { PublicJobDescriptionListRenderer } from '../../public-job-description-list-editor';
import { JOB_DESCRIPTION_LIST_PAGE_TITLE_STYLE } from '../configs';
import { ResponsiveVerticalStack } from '../layout/responsive-stack';

interface Props {
  customerIdHash: string;
  jobDescriptionPageContent: JobDescriptionV1PageContent;
}

export function JobDescriptionPageContentRenderer({
  customerIdHash,
  jobDescriptionPageContent,
}: Props) {
  return (
    <JobDescriptionListPageContentRendererRoot
      space={80}
      mobileSpaceOverrides={60}
      align="stretch"
    >
      <BlockComponentSection>
        <BlockComponentSection.Content>
          <TextContentViewer
            content={migrateTextContentV1(jobDescriptionPageContent.content)}
            {...JOB_DESCRIPTION_LIST_PAGE_TITLE_STYLE}
          />
        </BlockComponentSection.Content>
      </BlockComponentSection>
      <PublicJobDescriptionListRenderer customerIdHash={customerIdHash} />
    </JobDescriptionListPageContentRendererRoot>
  );
}

const JobDescriptionListPageContentRendererRoot = styled(
  ResponsiveVerticalStack,
  {
    isolation: 'isolate',
    py: 40,
  }
);
