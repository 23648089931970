import type { LinkContent, TextContent } from '../block-content';
import { validateLinkContent, validateTextContent } from '../block-content';

import type { BlockComponent } from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_TYPES = [
  BlockComponentType.TextButtonLeft,
  BlockComponentType.TextButtonCenter,
] as const;
const VALID_ALIGN_STYLES = [AlignStyle.Left, AlignStyle.Center] as const;
const VALID_SIZE_STYLES = [SizeStyle.Default] as const;

type ValidTypes = (typeof VALID_TYPES)[number];
type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];
type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

export interface TextButtonBlockComponent extends BlockComponent {
  readonly type: ValidTypes;
  readonly align: ValidAlignStyle;
  readonly size: ValidSizeStyle;

  readonly title: TextContent;
  readonly subText: TextContent;
  readonly link: LinkContent;
}

export function validateTextButtonBlockComponent(
  param: unknown
): param is TextButtonBlockComponent {
  return (
    // is block component
    validateBlockComponent(param) &&
    VALID_TYPES.includes(param.type as ValidTypes) &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle) &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has link
    'link' in param &&
    validateLinkContent(param.link)
  );
}
