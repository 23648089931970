import { CandidateDocumentFileType } from '@flex-apis/recruiting';
import type { TranslationKey } from '@flex-packages/i18n';
import { trans } from '@flex-packages/i18n-base';

export const ATTACHMENT_TYPE_LOKALISE_KEY: Record<
  CandidateDocumentFileType,
  TranslationKey
> = {
  [CandidateDocumentFileType.File]: trans('recruiting.attachment.file.title'),
  [CandidateDocumentFileType.Url]: trans('recruiting.attachment.link.title'),
};
