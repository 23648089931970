import { Typography } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';
import { getFormattedSizeBytes } from '@flex-services/file/base';

import { candidateAttachmentRules } from '../../../../../base';

export const FileEmptyView = () => {
  return (
    <Typography weight={500} size={14} color="grayLight">
      <Translation
        tKey="recruiting.attachment.file.document.max"
        tOptions={{
          values: {
            sizeLimit: getFormattedSizeBytes(
              candidateAttachmentRules.maxFileSize
            ),
          },
        }}
      />
    </Typography>
  );
};
