import { WorkExperienceJobRoleNameInput } from '@flex-private-packages/recruiting-common/components';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function WorkExperienceJobRoleInputFormItem({ currentIndex }: Props) {
  return (
    <Controller<
      JobDescriptionFormState,
      `workExperienceBackgrounds.${typeof currentIndex}.jobRoleName`
    >
      name={`workExperienceBackgrounds.${currentIndex}.jobRoleName`}
      render={({ field }) => <WorkExperienceJobRoleNameInput {...field} />}
    />
  );
}
