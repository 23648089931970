import { CandidateWorkExperienceStatus } from '@flex-apis/recruiting';
import {
  ClickPopover,
  DatePicker,
  FieldBox,
  FormField,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  TodayIcon,
} from '@flex-design-system/legacy-flex-icons';
import { isBeforeDay } from '@flex-packages/date';
import { TimeV2 } from '@flex-packages/datetime-react/components';
import { Translation, useLanguage, useTranslation } from '@flex-packages/i18n';
import { isNil, isNonNil } from '@flex-packages/utils';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function WorkExperienceRetirementYearMonthInputFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();
  const locale = useLanguage();
  const { getValues } = useFormContext<JobDescriptionFormState>();

  const { workExperienceBackgrounds = [] } = getValues();

  const isRetirement =
    workExperienceBackgrounds[currentIndex]?.workExperienceStatus ===
    CandidateWorkExperienceStatus.Resignation;

  return (
    <Controller<
      JobDescriptionFormState,
      `workExperienceBackgrounds.${number}.retirementDate`
    >
      name={`workExperienceBackgrounds.${currentIndex}.retirementDate`}
      rules={{
        required:
          isRetirement &&
          t('recruiting.work_experience.retire_date.edit.required'),
        validate: retirementDate => {
          const workExperienceBackground =
            workExperienceBackgrounds[currentIndex];
          if (isNil(workExperienceBackground)) {
            return t(
              'recruiting.work_experience.retire_date.edit.validate_unexpected'
            );
          }
          const joinDate = workExperienceBackground.joinDate;

          if (
            isNonNil(retirementDate) &&
            isNonNil(joinDate) &&
            isBeforeDay(retirementDate, joinDate)
          ) {
            return t('recruiting.work_experience.retire_date.edit.validate');
          }
        },
      }}
      render={({
        field: { value, onChange, ...field },
        fieldState: { error },
      }) => {
        const retirementDate = isNil(value) ? null : value;

        return (
          <FormField
            required={isRetirement}
            state={error ? 'error' : 'default'}
            {...field}
          >
            <FormField.Label leftSlot={<TodayIcon />}>
              <Translation tKey="recruiting.work_experience.retire_date" />
            </FormField.Label>
            <DatePicker
              mode="single"
              selectedDate={retirementDate}
              onDateChange={onChange}
            >
              <ClickPopover>
                <ClickPopover.Trigger asChild>
                  <FormField.FieldBox asChild>
                    <PrimitiveButton css={{ width: '100%' }}>
                      <FieldBox.Content>
                        {retirementDate !== null ? (
                          <TimeV2
                            date={retirementDate}
                            presetName="year_month"
                            locale={locale}
                          />
                        ) : (
                          <Typography color="grayLight" asChild>
                            <Translation tKey="recruiting.work_experience.retire_date.edit.placeholder" />
                          </Typography>
                        )}
                      </FieldBox.Content>
                      <FieldBox.RightSlotRoot>
                        <ChevronDownMiniIcon size={22} color="grayLight" />
                      </FieldBox.RightSlotRoot>
                    </PrimitiveButton>
                  </FormField.FieldBox>
                </ClickPopover.Trigger>
                <ClickPopover.Content_Legacy>
                  <DatePicker.Content css={{ paddingTop: '6px' }}>
                    <DatePicker.MonthInput />
                    <DatePicker.MonthController />
                    <DatePicker.MonthCalendar />
                  </DatePicker.Content>
                </ClickPopover.Content_Legacy>
              </ClickPopover>
            </DatePicker>
            {error?.message && (
              <FormField.HelperText>{error.message}</FormField.HelperText>
            )}
          </FormField>
        );
      }}
    />
  );
}
