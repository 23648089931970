import type { Locale } from '@flex-packages/i18n';
import type { ReactNode } from 'react';

export interface LokaliseText extends Record<Locale, ReactNode> {}

export interface TermsList {
  title: LokaliseText;
  items: Array<LokaliseText>;
}

export interface TermGroup {
  title: LokaliseText;
  description?: LokaliseText;
  terms: Array<TermsList>;
}

export const get_개인정보_및_민감정보 = ({
  customerName,
}: {
  customerName: string;
}): TermGroup => ({
  title: {
    ko: '개인정보 수집 및 이용 동의서 (필수)',
    en: 'Consent to Collection and Use of Personal Information (Required)',
  },
  description: {
    ko: (
      <strong>
        {customerName} (이하 “회사”)은/는 “flex”를 통한 지원서 접수 및 채용 전형
        진행을 위해 다음의 개인 정보를 수집 및 이용합니다.'
      </strong>
    ),
    en: (
      <strong>
        {customerName} (hereinafter referred to as “Company”) accepts
        applications and conducts recruitment through “flex” To proceed, we
        collect and use the following personal information.
      </strong>
    ),
  },
  terms: [
    {
      title: {
        ko: '1. 개인정보 수집 및 이용 목적',
        en: '1. Purpose of Collection and Use of Personal Information',
      },
      items: [
        {
          ko: '채용전형 진행 (지원자 식별, 지원의사 확인, 면접 등 전형 진행), 공지사항 전달, 상시채용을 위한 참고자료 이용, 입사 및 채용 관련 문의 응답, 입사 지원자와의 원활한 의사소통',
          en: 'Recruitment process (identification of applicants, confirmation of intention to apply, interviews, etc.), delivery of notices, use of reference materials for regular recruitment, response to inquiries related to employment and recruitment, smooth communication with job applicants',
        },
        {
          ko: '최종 합격 시 처우 산정, 입사자 본인확인, 인사 관리, 근로계약 체결 및 이행, 근로자 명부 및 임금대장 작성 등에 활용',
          en: 'Calculation of treatment upon final acceptance, self-confirmation of new employees, personnel management, use for conclusion and performance of labor contracts, preparation of employee rosters and wage ledgers, etc.',
        },
      ],
    },
    {
      title: {
        ko: '2. 수집하는 개인정보 항목',
        en: '2. Personal Information Items Collected',
      },
      items: [
        {
          ko: '[필수항목] 이름, 연락처, 이메일, 지원경로, 학력 정보, 병역 사항 (해당인 경우), 이력서 및 경력기술서 내용 중 개인식별 가능성이 있는 모든 정보',
          en: '[Required] Name, contact information, email, application route, educational background, military service status (if applicable), all information in the resume and career description that can identify individuals',
        },
        {
          ko: '[선택항목] 포트폴리오 (개인정보가 포함된 파일을 제출 하는 경우), 추가 링크 (개인 블로그, 웹사이트 등 개인이 관리하는 사이트 URL 제공 시), 기타 본인이 직접 입력하거나 첨부파일을 통해 업로드하는 정보 중 개인식별 가능성이 있는 모든 정보',
          en: '[Optional] Portfolio (if submitting a file containing personal information), additional links (when providing a URL of a site managed by the individual, such as a personal blog or website), all information that can identify individuals that individuals directly enter or upload via attachments',
        },
      ],
    },
    {
      title: {
        ko: '3. 보유 및 이용기간',
        en: '3. Retention and Use Period',
      },
      items: [
        {
          ko: (
            <>
              지원서 접수완료일자로부터 <strong>3년 간</strong> 보관
            </>
          ),
          en: (
            <>
              Retention for <strong>3 years</strong> from the date of completion
              of the application
            </>
          ),
        },
        {
          ko: '단, 정보주체의 삭제 요청이 있는 경우, 지체없이 파기',
          en: 'However, if there is a request from the data subject to delete, it will be destroyed without delay',
        },
      ],
    },
    {
      title: {
        ko: '4. 동의를 거부할 권리 및 동의 거부에 따른 불이익',
        en: '4. Right to Refuse Consent and Disadvantages of Refusing Consent',
      },
      items: [
        {
          ko: '정보주체는 개인정보 수집 및 이용에 관한 동의를 거부할 권리가 있습니다. 단, 동의를 거부할 경우 지원서 접수 및 채용 전형 진행에 제한을 받을 수 있습니다.',
          en: 'The data subject has the right to refuse consent to the collection and use of personal information. However, if consent is refused, there may be restrictions on the application and recruitment process.',
        },
      ],
    },
  ],
});

export const get_민감정보 = (): TermGroup => ({
  title: {
    ko: '민감정보 수집 및 이용 동의서 (필수)',
    en: 'Consent to Collection and Use of Sensitive Information (Required)',
  },
  terms: [
    {
      title: {
        ko: '1. 민감정보 수집 및 이용 목적',
        en: '1. Purpose of Collection and Use of Sensitive Information',
      },
      items: [
        {
          ko: '국가유공자 예우 및 지원에 관한 법률, 장애인 고용촉진 및 직업재활법에 따라 채용시 우대',
          en: 'Preferential treatment for employment under the Act on the Promotion of Employment of Persons with Disabilities and the Rehabilitation of the Disabled, and the Act on the Honor and Support of National Merit',
        },
      ],
    },
    {
      title: {
        ko: '2. 수집하는 민감정보 항목',
        en: '2. Sensitive Information Items Collected',
      },
      items: [
        {
          ko: <strong>장애 사항, 보훈 대상 여부</strong>,
          en: (
            <strong>Disability status, eligibility for national merit</strong>
          ),
        },
      ],
    },
    {
      title: {
        ko: '3. 보유 및 이용기간',
        en: '3. Retention and Use Period',
      },
      items: [
        {
          ko: (
            <>
              지원서 접수완료일자로부터 <strong>3년 간</strong> 보관
            </>
          ),
          en: (
            <>
              Retention for <strong>3 years</strong> from the date of completion
              of the application
            </>
          ),
        },
        {
          ko: '단, 정보주체의 삭제 요청이 있는 경우, 지체없이 파기',
          en: 'However, if there is a request from the data subject to delete, it will be destroyed without delay',
        },
      ],
    },
    {
      title: {
        ko: '4. 동의를 거부할 권리 및 동의 거부에 따른 불이익',
        en: '4. Right to Refuse Consent and Disadvantages of Refusing Consent',
      },
      items: [
        {
          ko: '정보주체는 민감정보 수집 및 이용에 관한 동의를 거부할 권리가 있습니다. 단, 동의를 거부할 경우 채용 우대에 따른 가산점 미제공 등 제한이 있을 수 있으니 이 점 양해를 바랍니다.',
          en: 'The data subject has the right to refuse consent to the collection and use of sensitive information. However, if consent is refused, there may be restrictions on the provision of additional points for preferential treatment in employment.',
        },
      ],
    },
  ],
});

export const get_개인정보처리업무_위탁 = (): TermGroup => ({
  title: {
    ko: '개인정보처리업무 위탁에 관한 사항',
    en: 'About Outsourcing Personal Information Processing',
  },
  description: {
    ko: '회사는 플렉스 주식회사에 지원서 접수 및 채용 절차 진행에 필요한 개인정보 처리업무를 위탁하였습니다. 플렉스 주식회사는 개인정보 보호법 및 관련 법령에 따라 개인정보를 안전하게 보호할 수 있도록 최선을 다합니다.',
    en: 'The Company has entrusted Flex Co., Ltd. with the personal information processing tasks necessary for the application and recruitment process. Flex Co., Ltd. will do its best to protect personal information safely in accordance with the Personal Information Protection Act and related laws.',
  },
  terms: [],
});
