import { Divider, DynamicHeightTransition } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { cloneElement, forwardRef, isValidElement } from 'react';
import type { HTMLAttributes, ReactElement, ReactNode } from 'react';

import type { CandidateBackgroundListItemHeaderProps } from './Header';

export interface CandidateBackgroundListItemProps
  extends Omit<HTMLAttributes<HTMLLIElement>, 'content'> {
  danger?: boolean;
  header: ReactElement<CandidateBackgroundListItemHeaderProps>;
  content: ReactNode;
  expanded?: boolean;
  onCollapse?: VoidFunction;
}

const CandidateBackgroundListItem = forwardRef<
  HTMLLIElement,
  CandidateBackgroundListItemProps
>(function ({ header, content, expanded = true, onCollapse, ...props }, ref) {
  return (
    <ItemRoot ref={ref} {...props}>
      <DynamicHeightTransition>
        <DynamicHeightTransition.Content>
          {isValidElement(header) &&
            cloneElement<CandidateBackgroundListItemHeaderProps>(header, {
              onClick: onCollapse,
            })}
          {expanded && (
            <>
              <Divider color="grayLightest" />
              {content}
            </>
          )}
        </DynamicHeightTransition.Content>
      </DynamicHeightTransition>
    </ItemRoot>
  );
});

const ItemRoot = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'normal',

  borderRadius: '8px',

  backgroundColor: '$white',
  boxShadow:
    '0px 2px 6px rgba(0, 0, 0, 0.02), inset 0px -1px 0px rgba(0, 0, 0, 0.06), inset 0px 0px 0px 1px rgba(0, 0, 0, 0.04)',

  variants: {
    danger: {
      true: {
        boxShadow:
          'inset 0px 0px 0px 1px #F44712, 0px 2px 6px rgba(242, 75, 23, 0.02), inset 0px -1px 0px rgba(242, 75, 23, 0.06), inset 0px 0px 0px 1px rgba(242, 75, 23, 0.04)',
      },
    },
  },
});

export default CandidateBackgroundListItem;
