import { AlignStyle } from '../../../../../../base';
import type { LinkStyle, TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(): TextStyle {
  return {
    align: AlignStyle.Center,
    color: 'grayDarker',
    size: 42,
    mobileSize: 32,
    weight: 700,
    lineHeight: 1.3,
    letterSpacing: '-1.26px',
    mobileLetterSpacing: '-0.96px',
  };
}

export function getSubTextStyle(): TextStyle {
  return {
    align: AlignStyle.Center,
    color: 'grayLight',
    size: 24,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.24px',
    mobileLetterSpacing: '-0.16px',
  };
}

export function getLinkStyle(): LinkStyle {
  return {
    color: 'negative',
  };
}
