import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

import { getMobileStyle } from '../../../utils';

export const BackgroundItemContentColumn = styled(Flex, {
  gap: 8,
});

export const BackgroundItemContentRow = styled('div', {
  display: 'flex',
  alignItems: 'start',

  '& > div': {
    width: '100%',
  },

  '& > *:not(:first-child)': {
    marginLeft: 16,
  },

  ...getMobileStyle({
    flexDirection: 'column',
    alignItems: 'stretch',

    '& > *:not(:first-child)': {
      marginLeft: 0,
      marginTop: 16,
    },
  }),
});
