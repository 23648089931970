import { Flex, Spinner } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { Translation } from '@flex-packages/i18n';

import type {
  PublicJobDescription,
  PublicJobDescriptionFilter,
} from '../../../../base';

import Empty from './Empty';
import JobDescriptionListItem from './Item';

interface Props {
  disabled?: boolean;
  publicJobDescriptions: Array<PublicJobDescription> | undefined;
  publicJobDescriptionsLoading: boolean;
  filter: PublicJobDescriptionFilter;
}

function PublicJobDescriptionListContent({
  publicJobDescriptions,
  publicJobDescriptionsLoading,
  filter,
  disabled,
}: Props) {
  if (publicJobDescriptionsLoading || publicJobDescriptions === undefined) {
    return (
      <Flex
        align="center"
        justify="center"
        css={{ width: '100%', height: 468 }}
      >
        <Spinner size={36} />
      </Flex>
    );
  }

  // 채용 공고가 등록되지 않은 경우
  if (publicJobDescriptions?.length === 0 && filter.titleContains === '') {
    return (
      <Empty
        title={<Translation tKey="recruiting.site.job_description.empty" />}
      />
    );
  }

  if (publicJobDescriptions?.length === 0) {
    return (
      <Empty
        title={<Translation tKey="recruiting.search.not_found" />}
        subtext={<Translation tKey="recruiting.search.not_found.description" />}
      />
    );
  }

  return (
    <PublicJobDescriptionListContentRoot>
      {publicJobDescriptions?.map(jobDescription => (
        <JobDescriptionListItem
          key={jobDescription.jobDescriptionIdHash}
          disabled={disabled}
          jobDescription={jobDescription}
        />
      ))}
    </PublicJobDescriptionListContentRoot>
  );
}

const PublicJobDescriptionListContentRoot = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

export default PublicJobDescriptionListContent;
