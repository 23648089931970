import { CandidateDocumentFileType } from '@flex-apis/recruiting';
import type { FormFieldProps } from '@flex-design-system/fx';
import { FieldBox, Flex, FormField, HelperText } from '@flex-design-system/fx';
import { ArticlePlusIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { useTranslation } from '@flex-packages/i18n';
import { EventFeatureBoundary } from '@flex-packages/logger';
import type { FileType } from '@flex-services/file/hooks';
import type { ComponentProps, FC } from 'react';
import { forwardRef, Suspense } from 'react';
import type { RefCallBack } from 'react-hook-form';

import type { CandidateDocumentFieldModel } from '../../../base';

import DocumentTypeSegmentControl from './DocumentTypeSegmentControl';
import { FileDocumentField } from './FileDocumentField';
import UrlDocumentField from './UrlDocumentField';

interface FileDocumentFieldComponentProps {
  file?: FileType;
  onFileChange?: (file?: FileType) => void;
}

interface Props extends Omit<FormFieldProps, 'children'> {
  id: string;
  value: CandidateDocumentFieldModel;
  variant: ComponentProps<typeof FormField.FieldBox>['variant'];
  onValueChange?: (value: CandidateDocumentFieldModel) => void;
  FileDocumentFieldComponent?: FC<FileDocumentFieldComponentProps>;
  inputRef?: RefCallBack;
}

const CandidateDocumentField = forwardRef<HTMLLabelElement, Props>(
  (
    {
      id,
      value,
      variant,
      onValueChange,
      FileDocumentFieldComponent = FileDocumentField,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Flex direction="column" align="stretch">
        <FormField required={value.isRequired} {...props}>
          <FormField.Label
            id={id}
            leftSlot={<ArticlePlusIcon size={16} color="grayLighter" />}
          >
            <span>{value.title}</span>
          </FormField.Label>
          <FormField.FieldBox
            variant={variant}
            asChild
            css={{
              px: 0,
              // File 삭제 버튼이 focus 됐을 때, FieldBox의 외곽선이 활성화가 되지 않도록
              '&:has(button[role="radio"]:focus)': {
                boxShadow: '$inputOutline',
              },
              // hover 스타일이 전체에 적용되도록
              '&:not(:has(div[role="radiogroup"]:hover)):hover': {
                background: '$blackAlpha004',
              },
              '&:not(:has(div[role="radiogroup"]:active)):active': {
                background: '$blackAlpha008',
              },
            }}
          >
            <Label type={value.registeredType} ref={ref}>
              <FieldBoxContent>
                {value.registeredType === CandidateDocumentFileType.File ? (
                  <Suspense>
                    <EventFeatureBoundary eventFeature="recruitingCandidateFileUpload">
                      <FileDocumentFieldComponent
                        file={value.file}
                        onFileChange={file =>
                          onValueChange?.({ ...value, file })
                        }
                      />
                    </EventFeatureBoundary>
                  </Suspense>
                ) : (
                  <UrlDocumentField
                    value={value.url}
                    onChange={e =>
                      onValueChange?.({ ...value, url: e.target.value })
                    }
                    onClear={() => onValueChange?.({ ...value, url: '' })}
                  />
                )}
              </FieldBoxContent>
              {!value.isFixedType && (
                <FieldBoxRightSlotRoot>
                  <DocumentTypeSegmentControl
                    value={value.registeredType}
                    onValueChange={registeredType =>
                      onValueChange?.({ ...value, registeredType })
                    }
                  />
                </FieldBoxRightSlotRoot>
              )}
            </Label>
          </FormField.FieldBox>
        </FormField>
        {props.state === 'error' &&
          value.registeredType === CandidateDocumentFileType.Url && (
            <HelperText state="error">
              {t('recruiting.candidate_document_field.url_error')}
            </HelperText>
          )}
      </Flex>
    );
  }
);

const Label = styled('label', {
  '&&': { alignItems: 'center', paddingRight: 4, height: 40 },

  variants: {
    type: {
      [CandidateDocumentFileType.Url]: {
        cursor: 'text',
      },
      [CandidateDocumentFileType.File]: {
        cursor: 'pointer',
      },
    },
  },
});

const FieldBoxContent = styled(FieldBox.Content, {
  '&&': { py: 0, height: '100%' },
  '& > *': { paddingLeft: 12 },
});

const FieldBoxRightSlotRoot = styled(FieldBox.RightSlotRoot, {
  '&&': { py: 4 },
});

export default CandidateDocumentField;
