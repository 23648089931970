import { TextField } from '@flex-design-system/fx';
import { FaceSmileIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

export function NameField() {
  const { t } = useTranslation();
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();

  return (
    <Controller
      control={control}
      name="name"
      rules={{
        required: t('recruiting.name.edit.helper.required'),
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            variant="solid"
            onChange={e => {
              field.onChange(e);
              clearErrors('name');
            }}
            id="name"
            required
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            label={
              <TextField.Label leftSlot={<FaceSmileIcon />}>
                <Translation tKey="recruiting.name" />
              </TextField.Label>
            }
            placeholder={t('recruiting.name.edit.placeholder.applicator')}
            state={error !== undefined ? 'error' : 'default'}
            helperText={
              <TextField.HelperText>{error?.message}</TextField.HelperText>
            }
          />
        );
      }}
    />
  );
}
