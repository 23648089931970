import { v4 as uuidV4 } from 'uuid';

import type { MediaContent, TextContent } from '../block-content';
import {
  generateDefaultMediaContent,
  generateDefaultTextContent,
  validateMediaContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Left, AlignStyle.Right] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface ListVerticalBlockComponentContent {
  readonly items: Array<ListVerticalBlockItem>; // 3개
}

export interface ListVerticalBlockItem {
  readonly id: string;
  readonly title: TextContent;
  readonly subText: TextContent;
  readonly media: MediaContent | null;
}

export function updateListVerticalBlockComponentContent(
  origin: ListVerticalBlockComponent,
  content: unknown
): ListVerticalBlockComponent;
export function updateListVerticalBlockComponentContent(
  origin: ListVerticalBlockComponent,
  content: ListVerticalBlockComponentContent
): ListVerticalBlockComponent;
export function updateListVerticalBlockComponentContent(
  origin: ListVerticalBlockComponent,
  content: unknown
): ListVerticalBlockComponent {
  if (!validateListVerticalBlockComponentContent(content)) {
    throw new Error(
      '[updateListVerticalBlockComponentContent]: Invalid content'
    );
  }

  const newContent: ListVerticalBlockComponentContent = {
    items: content.items.map(item => ({ ...item })),
  };

  return { ...origin, ...newContent };
}

export function validateListVerticalBlockComponentContent(
  param: unknown
): param is ListVerticalBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has items
    'items' in param &&
    Array.isArray(param.items) &&
    validateListVerticalBlockItems(param.items)
  );
}

export function validateListVerticalBlockItems(
  param: Array<unknown>
): param is Array<ListVerticalBlockItem> {
  return param.length === 3 && param.every(validateListVerticalBlockItem);
}

export function validateListVerticalBlockItem(
  param: unknown
): param is ListVerticalBlockItem {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText) &&
    // has media
    'media' in param &&
    (param.media === null || validateMediaContent(param.media))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface ListVerticalBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateListVerticalBlockComponentRandomStyle(
  origin: ListVerticalBlockComponent
): ListVerticalBlockComponent {
  const newStyle: ListVerticalBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateListVerticalBlockComponentStyle(
  param: unknown
): param is ListVerticalBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface ListVerticalBlockComponent
  extends BlockComponent,
    ListVerticalBlockComponentContent,
    ListVerticalBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'ListVertical'>;
}

export function generateDefaultListVerticalBlockComponent(param: {
  hasMedia: boolean;
  align: ValidAlignStyle;
}): ListVerticalBlockComponent {
  const { hasMedia, align } = param;

  return {
    componentType: BlockComponentType.ListVertical,
    id: uuidV4(),
    items: Array.from({ length: 3 }).map(() => ({
      id: uuidV4(),
      title: generateDefaultTextContent(),
      subText: generateDefaultTextContent(),
      media: hasMedia ? generateDefaultMediaContent() : null,
    })),
    align,
  };
}

export function validateListVerticalBlockComponent(
  param: unknown
): param is ListVerticalBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.ListVertical &&
    validateListVerticalBlockComponentContent(param) &&
    validateListVerticalBlockComponentStyle(param)
  );
}
