import type { JobGroup } from '@flex-private-packages/recruiting-common/base';

class JobGroupListController {
  private jobGroupList: JobGroup[];
  private idHashList: string[];

  constructor({ jobGroupList }: { jobGroupList: JobGroup[] }) {
    this.jobGroupList = [...jobGroupList];
    this.idHashList = jobGroupList.reduce<string[]>(
      (list, jobGroup) => [...list, jobGroup.idHash],
      []
    );
  }

  public getJobGroupList() {
    return [...this.jobGroupList];
  }

  public getIdHashList() {
    return [...this.idHashList];
  }

  public getName(param: { idHash: string }): string | undefined;
  public getName(param: { idHash: string; defaultValue: string }): string;
  public getName({ idHash, defaultValue }: any) {
    const targetJobGroup = this.jobGroupList.find(
      jobGroup => jobGroup.idHash === idHash
    );
    return targetJobGroup?.name ?? defaultValue;
  }

  public getSize() {
    return this.jobGroupList.length;
  }
}

export default JobGroupListController;
