import type { CoverBlockComponent } from '../../../../../../base';
import type { LinkStyle, TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<CoverBlockComponent, 'align' | 'background'>
): TextStyle {
  return {
    align: model.align,
    color: model.background === null ? 'grayDarker' : 'white',
    size: 56,
    mobileSize: 32,
    weight: 700,
    lineHeight: 1.3,
    letterSpacing: '-1.68px',
    mobileLetterSpacing: '-0.96px',
  };
}

export function getSubTextStyle(
  model: Pick<CoverBlockComponent, 'align' | 'background'>
): TextStyle {
  return {
    align: model.align,
    color: model.background === null ? 'grayLight' : 'grayLightest',
    size: 24,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.5,
    letterSpacing: '-0.24px',
    mobileLetterSpacing: '-0.16px',
  };
}

export function getLinkStyle(
  model: Pick<CoverBlockComponent, 'background'>
): LinkStyle {
  return {
    color: model.background === null ? 'negative' : 'positive',
  };
}
