import { RecruitingEmploymentContractType } from '@flex-apis/recruiting';
import type { TranslationKey } from '@flex-packages/i18n';
import { trans } from '@flex-packages/i18n-base';

export const RECRUITING_EMPLOYMENT_CONTRACT_TYPE_CONTENT: Record<
  RecruitingEmploymentContractType,
  { i18n: TranslationKey }
> = {
  [RecruitingEmploymentContractType.Regular]: {
    i18n: trans('recruiting.employment_contract_type.regular'),
  },
  [RecruitingEmploymentContractType.Contract]: {
    i18n: trans('recruiting.employment_contract_type.contract'),
  },
  [RecruitingEmploymentContractType.Freelancer]: {
    i18n: trans('recruiting.employment_contract_type.freelancer'),
  },
  [RecruitingEmploymentContractType.PartTime]: {
    i18n: trans('recruiting.employment_contract_type.part_time'),
  },
  [RecruitingEmploymentContractType.Intern]: {
    i18n: trans('recruiting.employment_contract_type.intern'),
  },
  [RecruitingEmploymentContractType.Dispatch]: {
    i18n: trans('recruiting.employment_contract_type.dispatch'),
  },
};
