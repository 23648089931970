import type { ButtonProps } from '@flex-design-system/fx';
import { ArrowForwardIcon } from '@flex-design-system/legacy-flex-icons';
import type { StitchesCSS } from '@flex-design-system/stitches-react';

export type LinkColorType = 'negative' | 'positive';

export interface LinkStyle {
  color: LinkColorType;
}

export function getButtonStyleProps(
  linkStyle: LinkStyle
): Partial<ButtonProps> {
  const backgroundColor =
    linkStyle.color === 'negative' ? 'grayDarker' : 'white';
  const fontColor = linkStyle.color === 'negative' ? 'white' : 'grayDarker';

  return {
    variant: 'solid',
    size: 'xlarge',
    color: backgroundColor,
    rightSlot: <ArrowForwardIcon type="fill" color={fontColor} />,
    css: getButtonCSS(linkStyle),
  };
}

export function getButtonCSS(linkStyle: LinkStyle) {
  const fontColor = linkStyle.color === 'negative' ? 'white' : 'grayDarker';

  return {
    color: `$${fontColor} !important`,
    flexShrink: 0,
  } satisfies StitchesCSS;
}
