import { v4 as uuidV4 } from 'uuid';

import type {
  BackgroundContent,
  BackgroundTypeValue,
  TextContent,
} from '../block-content';
import {
  generateDefaultBackgroundContent,
  generateDefaultTextContent,
  validateBackgroundContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [
  AlignStyle.Left,
  AlignStyle.Center,
  AlignStyle.Right,
] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface AboutBlockComponentContent {
  readonly title: TextContent;
  readonly subTitle: TextContent;
  readonly description: TextContent;
  readonly background: BackgroundContent | null;
}

export function updateAboutBlockComponentContent(
  origin: AboutBlockComponent,
  content: unknown
): AboutBlockComponent;
export function updateAboutBlockComponentContent(
  origin: AboutBlockComponent,
  content: AboutBlockComponentContent
): AboutBlockComponent;
export function updateAboutBlockComponentContent(
  origin: AboutBlockComponent,
  content: unknown
): AboutBlockComponent {
  if (!validateAboutBlockComponentContent(content)) {
    throw new Error('[updateAboutBlockComponentContent]: Invalid content');
  }

  const newContent: AboutBlockComponentContent = {
    title: { ...content.title },
    subTitle: { ...content.subTitle },
    description: { ...content.description },
    background: content.background && { ...content.background },
  };

  return { ...origin, ...newContent };
}

export function validateAboutBlockComponentContent(
  param: unknown
): param is AboutBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subTitle
    'subTitle' in param &&
    validateTextContent(param.subTitle) &&
    // has description
    'description' in param &&
    validateTextContent(param.description) &&
    // has background
    'background' in param &&
    (param.background === null || validateBackgroundContent(param.background))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface AboutBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateAboutBlockComponentRandomStyle(
  origin: AboutBlockComponent
): AboutBlockComponent {
  const newStyle: AboutBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateAboutBlockComponentStyle(
  param: unknown
): param is AboutBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface AboutBlockComponent
  extends BlockComponent,
    AboutBlockComponentContent,
    AboutBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'About'>;
}

export function generateDefaultAboutBlockComponent(param: {
  align: ValidAlignStyle;
  backgroundType?: BackgroundTypeValue;
}): AboutBlockComponent {
  const { align, backgroundType } = param;

  return {
    componentType: BlockComponentType.About,
    id: uuidV4(),
    title: generateDefaultTextContent(),
    subTitle: generateDefaultTextContent(),
    description: generateDefaultTextContent(),
    background: backgroundType
      ? generateDefaultBackgroundContent({ backgroundType })
      : null,
    align,
  };
}

export function validateAboutBlockComponent(
  param: unknown
): param is AboutBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.About &&
    validateAboutBlockComponentContent(param) &&
    validateAboutBlockComponentStyle(param)
  );
}
