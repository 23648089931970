import type { QuoteBlockComponent } from '../../../../../../base';
import {
  MediaContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import QuoteBlockComponentLayout from './QuoteBlockComponentLayout';
import { getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: QuoteBlockComponent;
}

export function QuoteBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <QuoteBlockComponentLayout>
          {model.items.map(item => (
            <QuoteBlockComponentLayout.Item
              key={item.id}
              align={model.align}
              media={<MediaContentViewer content={item.image} />}
              title={
                <TextContentViewer
                  content={item.title}
                  {...getTitleTextStyle(model)}
                />
              }
              subText={
                <TextContentViewer
                  content={item.subText}
                  {...getSubTextStyle(model)}
                />
              }
            />
          ))}
        </QuoteBlockComponentLayout>
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
