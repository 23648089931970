import type { ColumnsBlockComponent } from '../../../../../../base';
import { TextContentViewer } from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { ColumnsBlockComponentLayout } from './ColumnsBlockComponentLayout';
import { getSubTextStyle, getTitleTextStyle } from './styles';

interface Props {
  model: ColumnsBlockComponent;
}

export function ColumnsBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <ColumnsBlockComponentLayout>
          {model.items.map(item => (
            <ColumnsBlockComponentLayout.Item
              key={item.id}
              align={model.align}
              title={
                item.title && (
                  <TextContentViewer
                    content={item.title}
                    {...getTitleTextStyle(model)}
                  />
                )
              }
              subText={
                <TextContentViewer
                  content={item.subText}
                  {...getSubTextStyle(model)}
                />
              }
            />
          ))}
        </ColumnsBlockComponentLayout>
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
