import { Primitive } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import type { ComponentProps } from 'react';

import PageLayoutFooter from './PageLayoutFooter';
import { PageLayoutHeader } from './PageLayoutHeader';

export function PageLayout({
  children,
  ...rootProps
}: Omit<ComponentProps<typeof PageLayoutRoot>, 'as' | 'asChild'>) {
  return (
    <PageLayoutRoot {...rootProps} as="article">
      {children}
    </PageLayoutRoot>
  );
}

function PageLayoutBody({
  children,
  ...rootProps
}: Omit<ComponentProps<typeof PageLayoutBodyRoot>, 'as' | 'asChild'>) {
  return (
    <PageLayoutBodyRoot {...rootProps} as="article">
      {children}
    </PageLayoutBodyRoot>
  );
}

const PageLayoutBodyRoot = styled(Primitive.div, {});

const PageLayoutRoot = styled(Primitive.div, {
  isolation: 'isolate',
  position: 'relative',

  backgroundColor: '$white',
});

PageLayout.Header = PageLayoutHeader;
PageLayout.Body = PageLayoutBody;
PageLayout.Footer = PageLayoutFooter;
