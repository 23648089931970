import { TextField } from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  ShieldIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { isNil } from '@flex-packages/utils';
import { MILITARY_SERVICE_CONTENT } from '@flex-private-packages/recruiting-common/base';
import { MilitaryServiceSelect } from '@flex-private-packages/recruiting-common/components';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

export function MilitaryServiceField() {
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      key="militaryServiceStatus"
      control={control}
      name="militaryServiceStatus"
      rules={{
        required: t('recruiting.military.edit.helper.required'),
      }}
      render={({
        field: { ref, onChange, value },
        fieldState: { invalid, error },
      }) => {
        const fieldValue = isNil(value)
          ? ''
          : t(MILITARY_SERVICE_CONTENT[value].i18n);

        return (
          <MilitaryServiceSelect
            open={isOpen}
            onOpenChange={setIsOpen}
            value={value}
            onMilitaryServiceChange={newValue => {
              onChange(newValue);
              clearErrors('militaryServiceStatus');
            }}
          >
            {/** onFocus재호출 방지 */}
            <div role="button">
              <TextField
                ref={ref}
                variant="solid"
                id="militaryServiceStatus"
                enterKeyHint="next"
                required
                css={{ cursor: 'pointer' }}
                onFocus={() => {
                  setIsOpen(true);
                }}
                rightSlot={<ChevronDownMiniIcon style={{ marginRight: 12 }} />}
                label={
                  <TextField.Label leftSlot={<ShieldIcon />}>
                    <Translation tKey="recruiting.military" />
                  </TextField.Label>
                }
                placeholder={t('recruiting.military.edit.placeholder')}
                value={fieldValue}
                state={invalid ? 'error' : 'default'}
                helperText={
                  <TextField.HelperText>{error?.message}</TextField.HelperText>
                }
              />
            </div>
          </MilitaryServiceSelect>
        );
      }}
    />
  );
}
