import type {
  AttachmentDocumentResponse,
  CandidateDocumentCreateRequest,
} from '@flex-apis/recruiting';
import {
  AttachmentDocumentRequiredType,
  CandidateDocumentFileType,
} from '@flex-apis/recruiting';
import type { FileType } from '@flex-services/file/hooks';

export interface CandidateDocumentFieldModel {
  title: string;
  requestDocumentIdHash: string;
  file?: FileType;
  url?: string;
  type: AttachmentDocumentRequiredType;
  isFixedType: boolean;
  registeredType: CandidateDocumentFileType;
  isRequired: boolean;
}

export function generateCandidateDocumentFieldModel(
  res: Pick<
    AttachmentDocumentResponse,
    'title' | 'attachmentDocumentIdHash' | 'requiredDocumentType' | 'isRequired'
  >
): CandidateDocumentFieldModel {
  return {
    title: res.title,
    requestDocumentIdHash: res.attachmentDocumentIdHash,
    type: res.requiredDocumentType,
    isFixedType:
      res.requiredDocumentType !== AttachmentDocumentRequiredType.UrlOrFile,
    registeredType: CandidateDocumentFileType.File,
    isRequired: res.isRequired,
  };
}

export function parseCandidateDocumentFieldModelToCandidateDocumentCreateRequest(
  candidateDocument: CandidateDocumentFieldModel,
  index: number
): CandidateDocumentCreateRequest {
  const base: Omit<CandidateDocumentCreateRequest, 'fileKey' | 'url'> = {
    title: candidateDocument.title,
    requestDocumentIdHash: candidateDocument.requestDocumentIdHash,
    displayOrder: index + 1,
    type: candidateDocument.type,
  };

  // 제출 자료가 File인 경우
  if (
    candidateDocument.registeredType === CandidateDocumentFileType.File &&
    candidateDocument.file?.fileKey
  ) {
    return {
      ...base,
      fileKey: candidateDocument.file.fileKey,
    };
  }

  // 제출 자료가 URL인 경우
  if (
    candidateDocument.registeredType === CandidateDocumentFileType.Url &&
    candidateDocument.url
  ) {
    return {
      ...base,
      url: candidateDocument.url,
    };
  }

  throw new Error(
    'CandidateDocumentFieldModelToCandidateDocumentCreateRequest: 제출 자료 정보가 없습니다.'
  );
}
