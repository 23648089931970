import { useContext } from 'react';

import { RendererPropsContext } from './context';

export function useRendererProps() {
  const context = useContext(RendererPropsContext);
  if (context === null) {
    throw new Error(
      '[useRendererProps] RendererPropsProvider 내부에서 사용돼야 합니다.'
    );
  }

  return context;
}
