import type { Timestamp } from '@flex-packages/date';
import { endOfDay, isAfter, isBefore, startOfDay } from '@flex-packages/date';
import { RecruitingPeriodType } from '@flex-private-packages/recruiting-common/base';

import type { PublicJobDescriptionDetail } from '../../../base';

export interface ValidSubmitApplyResult {
  valid: true;
}

export interface InvalidSubmitApplyResult {
  valid: false;
  reason: 'late' | 'early';
}

export const validateSubmitApply = (
  jobDescription: PublicJobDescriptionDetail,
  now: Timestamp
): ValidSubmitApplyResult | InvalidSubmitApplyResult => {
  const { periodType, startDate, endDate } = jobDescription;

  // 상시 채용
  if (periodType === RecruitingPeriodType.Occasional) {
    return { valid: true };
  }

  // 데이터가 없을 경우에는 채용 가능 상태로 간주
  if (!startDate || !endDate) {
    return { valid: true };
  }

  // 현재 날짜가 채용 기간에 포함되는지 체크
  if (isBefore(now, startOfDay(startDate))) {
    return { valid: false, reason: 'early' };
  }
  if (isAfter(now, endOfDay(endDate))) {
    return { valid: false, reason: 'late' };
  }
  return { valid: true };
};
