import { values } from '@flex-packages/utils';

export const REDIRECT_PATH_QUERY_KEY = 'path';
export const VIEWER_HOST_HEADER_KEY = 'CAREERS-HOST';

export const ErrorSubdomain = {
  NotFoundSubdomain: '404',
  InternalServerErrorSubdomain: '500',
} as const;
export const ReservedSubDomain = {
  Redirect: '302',
} as const;

// NOTE: legacy subdomain은 이전에 사용하던 비 정상적인 subdomain입니다.
// "/"가 Subdomain에 포함되어 path에서 Subdomain을 추출할 수 없습니다.
// 때문에 예외 사항으로 기록하고 따로 핸들링합니다.
export const ABNORMAL_LEGACY_SUBDOMAINS = [
  'ethan-alice/career/recruit',
] as const;

export type ErrorSubdomainKey = keyof typeof ErrorSubdomain;
export type ErrorSubdomainValue<
  Key extends ErrorSubdomainKey = ErrorSubdomainKey
> = (typeof ErrorSubdomain)[Key];

export function isErrorSubdomain(
  subdomain: string
): subdomain is ErrorSubdomainValue {
  return values(ErrorSubdomain).includes(subdomain as ErrorSubdomainValue);
}
