import { SiteDesignPageType } from '@flex-apis/recruiting';

/**
 * 아래 BE 마이그레이션 대응
 * https://flex-cv82520.slack.com/archives/C04HL8NQ68G/p1720149434538029
 */
const urlPagePathMigrationHelper = {
  // 사이트 디자인 생성/수정 요청 보낼 때
  toMutationRequest: ({
    urlPagePath,
    type,
  }: {
    urlPagePath: string;
    type: SiteDesignPageType;
  }) => {
    if (type === SiteDesignPageType.Home) {
      return urlPagePath;
    }

    return urlPagePath.startsWith('/') ? urlPagePath : `/${urlPagePath}`;
  },

  // careers-team에서 사이트 그릴 때
  toSiteDesignRendererData: ({
    urlPagePath,
    type,
  }: {
    urlPagePath: string;
    type: SiteDesignPageType;
  }) => {
    if (type === SiteDesignPageType.Home) {
      return urlPagePath;
    }

    return urlPagePath.startsWith('/') ? urlPagePath : `/${urlPagePath}`;
  },

  // 사이트 디자인 에디터에서 수정할 데이터
  toSiteDesignFormData: ({
    urlPagePath,
    type,
  }: {
    urlPagePath: string;
    type: SiteDesignPageType;
  }) => {
    if (type === SiteDesignPageType.Home) {
      return urlPagePath;
    }

    return urlPagePath.startsWith('/') ? urlPagePath.slice(1) : urlPagePath;
  },
};

export { urlPagePathMigrationHelper };
