import {
  EducationExperienceGraduateType,
  EducationExperienceInstitutionLevel,
  type CandidateEducationExperienceRequest,
  type CandidateEducationExperienceResponse,
} from '@flex-apis/recruiting';
import { dateToISODatePart, isoDatePartToDate } from '@flex-packages/date';
import { isNonNil } from '@flex-packages/utils';
import { z } from 'zod';

export const candidateEducationalBackgroundSchema = z.object({
  name: z.string(),
  institutionType: z.enum([
    EducationExperienceInstitutionLevel.Unknown,
    EducationExperienceInstitutionLevel.HighSchool,
    EducationExperienceInstitutionLevel.BachelorCollege,
    EducationExperienceInstitutionLevel.BachelorUniversity,
    EducationExperienceInstitutionLevel.MasterUniversity,
    EducationExperienceInstitutionLevel.PhdUniversity,
  ]),
  graduationStatus: z.enum([
    EducationExperienceGraduateType.Unknown,
    EducationExperienceGraduateType.PlanToGraduate,
    EducationExperienceGraduateType.Attending,
    EducationExperienceGraduateType.Absence,
    EducationExperienceGraduateType.Graduated,
    EducationExperienceGraduateType.DropOut,
    EducationExperienceGraduateType.Candidate,
  ]),
  majorName: z.string().optional(),
  admissionDate: z.date().nullable(),
  graduationDate: z.date().nullable().optional(),
});

export type CandidateEducationalBackground = z.infer<
  typeof candidateEducationalBackgroundSchema
>;

export function generateWithCandidateEducationalBackgroundResponse(
  res: CandidateEducationExperienceResponse
): CandidateEducationalBackground {
  return {
    name: res.name,
    institutionType: res.level,
    graduationStatus: res.graduateType,
    majorName: res.majorName ?? undefined,
    admissionDate: isoDatePartToDate(res.beginDate),
    graduationDate: isNonNil(res.endDate)
      ? isoDatePartToDate(res.endDate)
      : null,
  };
}

export function parseToEducationalBackgroundPatchRequest(
  candidateEducationalBackground: CandidateEducationalBackground
): CandidateEducationExperienceRequest {
  if (candidateEducationalBackground.admissionDate === null) {
    throw new Error(
      '[parseToEducationalBackgroundPatchRequest] 입학일자가 선택되지 않았어요.'
    );
  }

  return {
    name: candidateEducationalBackground.name,
    level: candidateEducationalBackground.institutionType,
    graduateType: candidateEducationalBackground.graduationStatus,
    majorName: candidateEducationalBackground.majorName,
    beginDate: dateToISODatePart(candidateEducationalBackground.admissionDate),
    endDate: isNonNil(candidateEducationalBackground.graduationDate)
      ? dateToISODatePart(candidateEducationalBackground.graduationDate)
      : undefined,
  };
}

export interface CandidateEducationalBackgroundJSON
  extends Omit<
    CandidateEducationalBackground,
    'admissionDate' | 'graduationDate'
  > {
  admissionDate: string | null;
  graduationDate?: string | null;
}

export function parseCandidateEducationalBackgroundToJSON(
  model: CandidateEducationalBackground
): CandidateEducationalBackgroundJSON {
  return {
    ...model,
    admissionDate:
      model.admissionDate && dateToISODatePart(model.admissionDate),
    graduationDate:
      model.graduationDate && dateToISODatePart(model.graduationDate),
  };
}

export function parseJSONToCandidateEducationalBackground(
  json: CandidateEducationalBackgroundJSON
): CandidateEducationalBackground {
  return {
    ...json,
    admissionDate:
      typeof json.admissionDate === 'string'
        ? isoDatePartToDate(json.admissionDate)
        : json.admissionDate,
    graduationDate:
      typeof json.graduationDate === 'string'
        ? isoDatePartToDate(json.graduationDate)
        : json.graduationDate,
  };
}

export default CandidateEducationalBackground;
