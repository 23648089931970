import { Flex } from '@flex-design-system/fx';
import { styled, type StitchesCSS } from '@flex-design-system/stitches-react';
import { useLayoutEffect, useRef, useState, type ComponentProps } from 'react';

import type { CoverBlockComponent } from '../../../../../../base';
import { AlignStyle, SizeStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';
import { LINK_CONTENT_CLASS_NAME } from '../../../block-content-field';

interface Props {
  align: CoverBlockComponent['align'];
  size: CoverBlockComponent['size'];

  title: JSX.Element;
  subText: JSX.Element;
  link: JSX.Element | null;
}

export function CoverBlockComponentLayout({
  align,
  size,
  title,
  subText,
  link,
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const [hasLink, setHasLink] = useState(link !== null);

  useLayoutEffect(() => {
    setHasLink(
      Boolean(
        rootRef.current?.querySelectorAll(`.${LINK_CONTENT_CLASS_NAME}`).length
      )
    );
  }, [link]);

  return (
    <CoverBlockComponentLayoutRoot
      ref={rootRef}
      align={getBlockComponentLayoutRootAlign({ align })}
      css={getBlockComponentLayoutRootCSS({ size, hasLink })}
      direction="column"
    >
      <TextContentWrapper direction="column" align="stretch">
        {title}
        {subText}
      </TextContentWrapper>
      {link}
    </CoverBlockComponentLayoutRoot>
  );
}

const TextContentWrapper = styled(Flex, {
  width: '100%',
  gap: 24,
  ...getMobileStyle({ gap: 20 }),
});

const CoverBlockComponentLayoutRoot = styled(Flex, {
  gap: 50,
  ...getMobileStyle({
    gap: 30,
  }),
});

function getBlockComponentLayoutRootAlign(param: {
  align: CoverBlockComponent['align'];
}): ComponentProps<typeof Flex>['align'] {
  const { align } = param;

  switch (align) {
    case AlignStyle.Center:
      return 'center';
    case AlignStyle.Left:
      return 'start';
    case AlignStyle.Right:
      return 'end';
  }
}

function getBlockComponentLayoutRootCSS(param: {
  size: CoverBlockComponent['size'];
  hasLink: boolean;
}): StitchesCSS {
  const { size, hasLink } = param;

  switch (size) {
    case SizeStyle.Default:
      return hasLink
        ? { py: 80, ...getMobileStyle({ py: 60 }) }
        : { py: 133, ...getMobileStyle({ py: 113 }) };
    case SizeStyle.Large:
      return hasLink
        ? { py: 227, ...getMobileStyle({ py: 170 }) }
        : { py: 280, ...getMobileStyle({ py: 213 }) };
  }
}
