import { Flex } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

interface Props {
  title: JSX.Element;
  subTitle: JSX.Element;
  description: JSX.Element;
}

export function AboutBlockComponentLayout({
  title,
  subTitle,
  description,
}: Props) {
  return (
    <AboutBlockComponentLayoutRoot direction="column" align="stretch">
      <TitleContentWrapper direction="column" align="stretch">
        {title}
        {subTitle}
      </TitleContentWrapper>
      {description}
    </AboutBlockComponentLayoutRoot>
  );
}

const TitleContentWrapper = styled(Flex, {
  gap: 12,
});

const AboutBlockComponentLayoutRoot = styled(Flex, {
  py: 60,
  gap: 30,
});
