import { Button, Divider, Flex } from '@flex-design-system/fx';
import { SendIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { Translation } from '@flex-packages/i18n';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import type { PublicJobDescriptionDetail } from '../../../../base';
import { StaticUrlPagePath } from '../../../../base';
import { parseToInternalRelativeUrl } from '../../logics';

import {
  ApplicationPathField,
  AttachmentDocumentField,
  ConfirmApplyDialog,
  DisabledTypeField,
  EducationalBackgroundSection,
  EmailField,
  MilitaryServiceField,
  NameField,
  PhoneNumberField,
  PreQuestionField,
  SubmittedModal,
  Terms,
  VeteranField,
  WorkExperienceBackgroundSection,
} from './components';
import { useSubmitJobDescriptionApplyForm } from './hooks';
import type { JobDescriptionFormState } from './models';
import { SessionStorageAutoSaver } from './session-storage';
import { FormSection } from './shared';

interface Props {
  customerIdHash: string;
  customerName: string;
  jobDescription: PublicJobDescriptionDetail;
}

export function JobDescriptionForm({
  customerIdHash,
  customerName,
  jobDescription,
}: Props) {
  const { requiredInformation } = jobDescription;

  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);

  const { getDefaultValue, clear } = SessionStorageAutoSaver.use({
    jobDescription,
  });
  const { mutateAsync: submit, isLoading: isSubmitting } =
    useSubmitJobDescriptionApplyForm({
      jobDescription,
    });

  const formMethods = useForm<JobDescriptionFormState>({
    defaultValues: getDefaultValue(),
    reValidateMode: 'onChange',
  });

  const handleSubmitButtonClick = () => {
    setShowDialog(true);
  };

  const handleSubmit = async () => {
    try {
      await submit(formMethods.getValues());
      setShowDialog(false);
      setSubmitSuccess(true);
      clear();
    } catch (e) {
      console.error(e);
    }
  };

  const formOnSubmit = formMethods.handleSubmit(handleSubmitButtonClick);
  const requiredSensitiveInformation =
    requiredInformation.veteran || requiredInformation.handicapCase;

  useEffect(() => {
    const { educationalBackgrounds, workExperienceBackgrounds } =
      formMethods.getValues();

    if (requiredInformation.education && !educationalBackgrounds?.length) {
      formMethods.setValue('educationalBackgrounds', [{} as any]);
    }
    if (requiredInformation.work && !workExperienceBackgrounds?.length) {
      formMethods.setValue('workExperienceBackgrounds', [{} as any]);
    }
  }, [requiredInformation.education, requiredInformation.work, formMethods]);

  return (
    <FormProvider {...formMethods}>
      <SessionStorageAutoSaver
        jobDescriptionIdHash={jobDescription.jobDescriptionIdHash}
      />
      <form onSubmit={formOnSubmit}>
        <Wrapper direction="column" align="stretch">
          <Flex direction="column" align="stretch" css={{ gap: 70 }}>
            <FormSection
              title={
                <FormSection.Title>
                  <Translation tKey="recruiting.site_builder.job_description.apply.basic_info" />
                </FormSection.Title>
              }
            >
              <NameField />
              <EmailField />
              <PhoneNumberField />
              {requiredInformation.militaryService ? (
                <MilitaryServiceField />
              ) : null}
              {requiredInformation.handicapCase ? <DisabledTypeField /> : null}
              {requiredInformation.veteran ? <VeteranField /> : null}
              <ApplicationPathField customerIdHash={customerIdHash} />
            </FormSection>
            {requiredInformation.education ? (
              <EducationalBackgroundSection />
            ) : null}
            {requiredInformation.work ? (
              <WorkExperienceBackgroundSection />
            ) : null}
            {jobDescription.documentList.length > 0 ? (
              <FormSection
                title={
                  <FormSection.Title>
                    <Translation tKey="recruiting.site_builder.job_description.apply.attachment" />
                  </FormSection.Title>
                }
              >
                <AttachmentDocumentField />
              </FormSection>
            ) : null}
            {jobDescription.preQuestionList.length > 0 ? (
              <Flex direction="column" align="stretch" css={{ gap: 40 }}>
                {jobDescription.preQuestionList.map((preQuestion, index) => {
                  return (
                    <PreQuestionField
                      key={preQuestion.idHash}
                      preQuestion={preQuestion}
                      expectedMaxContentLength={
                        preQuestion.expectedMaxContentLength
                      }
                      index={index}
                    />
                  );
                })}
              </Flex>
            ) : null}
          </Flex>
          <Divider color="blackAlpha010" css={{ my: 60 }} />
          <Flex direction="column" align="stretch" css={{ gap: 34 }}>
            <Terms
              customerName={customerName}
              requiredSensitiveInformation={requiredSensitiveInformation}
            />
            <Button
              type="submit"
              size="xlarge"
              color="grayDarker"
              leftSlot={<SendIcon />}
              css={{ marginTop: 68 }}
            >
              <Translation tKey="recruiting.site_builder.job_description.apply.short" />
            </Button>
          </Flex>
          <ConfirmApplyDialog
            open={showDialog}
            onOpenChange={setShowDialog}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
          />
          <SubmittedModal
            open={isSubmitSuccess}
            mainPageLink={parseToInternalRelativeUrl({
              urlPagePath: StaticUrlPagePath.Main,
            })}
          />
        </Wrapper>
      </form>
    </FormProvider>
  );
}

const Wrapper = styled(Flex, {
  paddingTop: 60,
  paddingBottom: 100,
});
