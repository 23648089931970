import { getApplicationPathTagsPublicRemote } from '@flex-apis/recruiting';
import { Legacy_Select, TextField } from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  FlagIcon,
} from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { useQueryFromRemote } from '@flex-packages/remote-hooks';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

interface Props {
  customerIdHash: string;
}

export function ApplicationPathField(props: Props) {
  const { customerIdHash } = props;
  const { t } = useTranslation();
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();
  const { data: applicationPathTags = [] } = useQueryFromRemote({
    remote: getApplicationPathTagsPublicRemote,
    extraParams: {
      customerIdHash,
    },
    options: { suspense: false },
  });

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Controller
      key="applicationPath"
      control={control}
      name="applicationPath"
      rules={{
        required: t(
          'recruiting.job_description.application_path.edit.helper.required'
        ),
      }}
      render={({
        field: { ref, onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <Legacy_Select
            open={isOpen}
            onOpenChange={setIsOpen}
            value={value}
            onValueChange={newValue => {
              onChange(newValue);
              clearErrors('applicationPath');
            }}
          >
            <Legacy_Select.Trigger type={undefined} asChild={true}>
              {/** onFocus재호출 방지 */}
              <div role="button">
                <TextField
                  ref={ref}
                  variant="solid"
                  id="applicationPath"
                  enterKeyHint="next"
                  required
                  css={{ cursor: 'pointer' }}
                  onFocus={() => {
                    setIsOpen(true);
                  }}
                  rightSlot={
                    <ChevronDownMiniIcon style={{ marginRight: 12 }} />
                  }
                  label={
                    <TextField.Label leftSlot={<FlagIcon />}>
                      <Translation tKey="recruiting.job_description.application_path" />
                    </TextField.Label>
                  }
                  placeholder={t(
                    'recruiting.job_description.application_path.edit.placeholder'
                  )}
                  value={value}
                  state={invalid ? 'error' : 'default'}
                  helperText={
                    <TextField.HelperText>
                      {error?.message}
                    </TextField.HelperText>
                  }
                />
              </div>
            </Legacy_Select.Trigger>
            <Legacy_Select.Content>
              <Legacy_Select.Group
                title={
                  <Translation tKey="recruiting.job_description.application_path" />
                }
              >
                {applicationPathTags.map(tag => (
                  <Legacy_Select.Option
                    key={tag.applicationPathTagIdHash}
                    value={tag.name}
                  >
                    <Legacy_Select.OptionTitle>
                      {tag.name}
                    </Legacy_Select.OptionTitle>
                  </Legacy_Select.Option>
                ))}
              </Legacy_Select.Group>
            </Legacy_Select.Content>
          </Legacy_Select>
        );
      }}
    />
  );
}
