import type { SingleTimeDatePresetNames } from '@flex-packages/date';
import { dateToISODatePart } from '@flex-packages/date';
import { useFormatISODatePartV2 } from '@flex-packages/datetime-react/hooks';
import { useLanguage, useTranslation } from '@flex-packages/i18n';

import { PERIOD_TYPE_CONTENT, RecruitingPeriodType } from '../../base';

interface Props {
  periodType?: RecruitingPeriodType | null;
  startDate?: Date | null;
  endDate?: Date | null;
  presetName?: SingleTimeDatePresetNames;
}

export function useJobDescriptionRangeString({
  periodType,
  startDate,
  endDate,
  presetName = 'date_short',
}: Props) {
  const { t } = useTranslation();
  const locale = useLanguage();
  const formatISODatePart = useFormatISODatePartV2({ locale });

  if (periodType === RecruitingPeriodType.Occasional) {
    return t(PERIOD_TYPE_CONTENT[RecruitingPeriodType.Occasional].i18n);
  }

  return [startDate, endDate]
    .map(date => {
      return date
        ? formatISODatePart({
            isoDatePart: dateToISODatePart(date),
            presetName,
          })
        : '';
    })
    .join(' - ')
    .trim();
}
