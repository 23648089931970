import { v4 as uuidV4 } from 'uuid';

import type { TextContent } from '../block-content';
import {
  generateDefaultTextContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import { validateAboutBlockComponentStyle } from './about-block-component';
import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [
  AlignStyle.Left,
  AlignStyle.Center,
  AlignStyle.Right,
] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface ColumnsBlockComponentContent {
  readonly items: Array<ColumnsBlockItem>; // 1 ~ 3개
}

export interface ColumnsBlockItem {
  readonly id: string;
  readonly title: TextContent | null;
  readonly subText: TextContent;
}

export function updateColumnsBlockComponentContent(
  origin: ColumnsBlockComponent,
  content: ColumnsBlockComponentContent
): ColumnsBlockComponent;
export function updateColumnsBlockComponentContent(
  origin: ColumnsBlockComponent,
  content: unknown
): ColumnsBlockComponent;
export function updateColumnsBlockComponentContent(
  origin: ColumnsBlockComponent,
  content: unknown
): ColumnsBlockComponent {
  if (!validateColumnsBlockComponentContent(content)) {
    throw new Error('[updateColumnsBlockComponentContent]: Invalid content');
  }

  const newContent: ColumnsBlockComponentContent = {
    items: content.items.map(item => ({ ...item })),
  };

  return { ...origin, ...newContent };
}

export function validateColumnsBlockComponentContent(
  param: unknown
): param is ColumnsBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has items
    'items' in param &&
    Array.isArray(param.items) &&
    param.items.length >= 1 &&
    param.items.length <= 3 &&
    param.items.every(validateColumnsBlockItem)
  );
}

export function validateColumnsBlockItem(
  param: unknown
): param is ColumnsBlockItem {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has title
    'title' in param &&
    (param.title === null || validateTextContent(param.title)) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText)
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface ColumnsBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateColumnsBlockComponentRandomStyle(
  origin: ColumnsBlockComponent
): ColumnsBlockComponent {
  const newStyle: ColumnsBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateColumnsBlockComponentStyle(
  param: unknown
): param is ColumnsBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface ColumnsBlockComponent
  extends BlockComponent,
    ColumnsBlockComponentContent,
    ColumnsBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'Columns'>;
}

export function generateDefaultColumnsBlockComponent(param: {
  itemCount: 1 | 2 | 3;
  align: ValidAlignStyle;
  hasTitle: boolean;
}): ColumnsBlockComponent {
  const { itemCount, align, hasTitle } = param;

  return {
    componentType: BlockComponentType.Columns,
    id: uuidV4(),
    items: Array.from({ length: itemCount }).map(() => ({
      id: uuidV4(),
      title: hasTitle ? generateDefaultTextContent() : null,
      subText: generateDefaultTextContent(),
    })),
    align,
  };
}

export function validateColumnsBlockComponent(
  param: unknown
): param is ColumnsBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Columns &&
    validateColumnsBlockComponentContent(param) &&
    validateAboutBlockComponentStyle(param)
  );
}
