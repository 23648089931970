import { values } from '@flex-packages/utils';

import { getRandomImageUrl } from '../logics';

import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export const BACKGROUND_DEFAULT_COLOR = '#E2E4E4';

export const BackgroundType = {
  Color: 'COLOR',
  BlackOverlayImage: 'BLACK_OVERLAY_IMAGE',
  WhiteOverlayImage: 'WHITE_OVERLAY_IMAGE',
} as const;

export type BackgroundTypeKey = keyof typeof BackgroundType;
export type BackgroundTypeValue<
  Key extends BackgroundTypeKey = BackgroundTypeKey,
> = (typeof BackgroundType)[Key];

export interface BackgroundContent extends BlockContent {
  readonly contentType: BlockContentTypeValue<'Background'>;

  readonly backgroundType: BackgroundTypeValue;
  readonly src: string;
}

export function generateDefaultBackgroundContent(param: {
  backgroundType: BackgroundTypeValue;
}): BackgroundContent {
  const { backgroundType } = param;

  return {
    contentType: BlockContentType.Background,
    backgroundType,
    src: getDefaultBackgroundSrc(backgroundType),
  };
}

function getDefaultBackgroundSrc(
  backgroundType: BackgroundTypeValue
): BackgroundContent['src'] {
  switch (backgroundType) {
    case BackgroundType.Color:
      return BACKGROUND_DEFAULT_COLOR;
    case BackgroundType.BlackOverlayImage:
    case BackgroundType.WhiteOverlayImage:
      return getRandomImageUrl();
  }
}

export function validateBackgroundContent(
  param: unknown
): param is BackgroundContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Background &&
    // has background type
    'backgroundType' in param &&
    values(BackgroundType).includes(
      param.backgroundType as BackgroundTypeValue
    ) &&
    // has src
    'src' in param &&
    typeof param.src === 'string'
  );
}
