import {
  registerCandidateForPublicRemote,
  type CandidatePublicControllerApiRegisterCandidateForPublicRequest,
  type PublicCandidateDocumentCreateRequest,
} from '@flex-apis/recruiting';
import { dateToISODatePart } from '@flex-packages/date';
import { useFetcherFromRemote } from '@flex-packages/remote-hooks';
import { isNonNil } from '@flex-packages/utils';
import {
  candidateEducationalBackgroundSchema,
  candidateWorkExperienceBackgroundSchema,
  VeteranWhether,
  type CandidateDocumentFieldModel,
  type CandidateEducationalBackground,
  type CandidateWorkExperienceBackground,
} from '@flex-private-packages/recruiting-common/base';
import { useMutation } from '@tanstack/react-query';

import type { PublicJobDescriptionDetail } from '../../../../../base';
import type { JobDescriptionFormState } from '../models';

interface Props {
  jobDescription: PublicJobDescriptionDetail;
}

export function useSubmitJobDescriptionApplyForm({ jobDescription }: Props) {
  const submit = useFetcherFromRemote({
    remote: registerCandidateForPublicRemote,
  });

  // TODO: 강렬하게 정리하고 싶은 함수
  const handleSubmit = async (data: JobDescriptionFormState) => {
    const {
      name,
      email,
      phoneNumber,
      applicationPath,
      documents,
      hasVeteransCompensation,
      handicapCase,
      militaryServiceStatus,
      preQuestionAnswers,
      educationalBackgrounds = [],
      noEducationalBackgrounds,
      workExperienceBackgrounds = [],
      noWorkExperience,
    } = data;

    const preQuestions = jobDescription.preQuestionList.map(
      (preQuestion, index) => ({
        preQuestionIdHash: preQuestion.idHash,
        content: preQuestionAnswers?.[index],
      })
    );

    const payload: CandidatePublicControllerApiRegisterCandidateForPublicRequest =
      {
        jobDescriptionIdHash: jobDescription.jobDescriptionIdHash,
        candidatePublicCreateRequest: {
          /**
           * https://flex-cv82520.slack.com/archives/C04HL8NQ68G/p1715912351335139?thread_ts=1715912018.950839&cid=C04HL8NQ68G
           */
          applicationDate: dateToISODatePart(new Date()),
          applicationPath,
          email: email,
          name,
          phoneNumber,
          publicCandidateDocuments: documents
            .map(parseDocument)
            .filter(isNonNil),
          hasVeteransCompensation:
            hasVeteransCompensation === VeteranWhether.Included,
          handicapCaseString: handicapCase, // https://flex-cv82520.slack.com/archives/C02GDF2SZPS/p1658118823483029
          militaryServiceStatusString: militaryServiceStatus, // https://flex-cv82520.slack.com/archives/C02GDF2SZPS/p1658118823483029
          candidatePreQuestionModelsJsonString: JSON.stringify(preQuestions),
          educationExperiencesJsonString: JSON.stringify(
            // 학력없음 처리
            noEducationalBackgrounds
              ? []
              : formatEducationalBackgrounds(educationalBackgrounds)
          ),
          workExperiencesJsonString: JSON.stringify(
            // 경력없음 처리
            noWorkExperience
              ? []
              : formatWorkExperienceBackgrounds(workExperienceBackgrounds)
          ),
        },
      };

    await submit(payload);
  };

  return useMutation({ mutationFn: handleSubmit });
}

function parseDocument(
  document: CandidateDocumentFieldModel
): PublicCandidateDocumentCreateRequest | undefined {
  switch (document.registeredType) {
    case 'FILE': {
      if (document.file?.fileKey) {
        return {
          attachmentDocumentIdHash: document.requestDocumentIdHash,
          attachmentDocumentRequiredType: 'FILE',
          temporaryFileKey: document.file.fileKey,
        };
      }
      return;
    }
    case 'URL': {
      return document.url
        ? {
            attachmentDocumentIdHash: document.requestDocumentIdHash,
            attachmentDocumentRequiredType: 'URL_OR_FILE',
            url: document.url,
          }
        : undefined;
    }
  }
}

function formatEducationalBackgrounds(
  educationalBackgrounds: CandidateEducationalBackground[]
) {
  return educationalBackgrounds
    .filter(
      educationalBackground =>
        candidateEducationalBackgroundSchema.safeParse(educationalBackground)
          .success
    )
    .map(
      ({
        name,
        institutionType,
        graduationStatus,
        majorName,
        admissionDate,
        graduationDate,
      }) => {
        return {
          name,
          level: institutionType,
          graduateType: graduationStatus,
          majorName: majorName,
          beginDate: isNonNil(admissionDate)
            ? dateToISODatePart(admissionDate)
            : undefined,
          endDate: isNonNil(graduationDate)
            ? dateToISODatePart(graduationDate)
            : undefined,
        };
      }
    );
}

function formatWorkExperienceBackgrounds(
  workExperienceBackgrounds: CandidateWorkExperienceBackground[]
) {
  return workExperienceBackgrounds
    .filter(
      workExperienceBackground =>
        candidateWorkExperienceBackgroundSchema.safeParse(
          workExperienceBackground
        ).success
    )
    .map(
      ({
        name,
        contractType,
        jobRoleName,
        workExperienceStatus,
        joinDate,
        retirementDate,
      }) => {
        return {
          name,
          contractType,
          status: workExperienceStatus,
          jobRoleName,
          beginDate: isNonNil(joinDate)
            ? dateToISODatePart(joinDate)
            : undefined,
          endDate: isNonNil(retirementDate)
            ? dateToISODatePart(retirementDate)
            : undefined,
        };
      }
    );
}
