import { Flex, Typography } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { useLanguage } from '@flex-packages/i18n';
import { Fragment } from 'react';

import type { TermGroup } from './constants';

interface Props {
  termGroup: TermGroup;
}

export function TermsGroupContainer({ termGroup }: Props) {
  const locale = useLanguage();

  return (
    <Flex direction="column" css={{ gap: 12 }}>
      <TermTitle>{termGroup.title[locale]}</TermTitle>
      <TextAreaLikeText color="gray">
        {termGroup.description?.[locale]}
        {termGroup.description && termGroup.terms.length > 0 && (
          <>
            <br />
            <br />
          </>
        )}
        {termGroup.terms.map((termList, groupIndex) => (
          <Fragment key={groupIndex}>
            <>{termList.title[locale]}</>
            <TermList>
              {termList.items.map((item, listIndex) => (
                <TermListItem key={listIndex}>{item[locale]}</TermListItem>
              ))}
            </TermList>
          </Fragment>
        ))}
      </TextAreaLikeText>
    </Flex>
  );
}

const TextAreaLikeText = styled(Typography, {
  px: 18,
  py: 12,
  borderRadius: 8,
  boxShadow: '$inputOutline',
  fontSize: 12,
  lineHeight: 1.5,

  maxHeight: 200,
  overflowY: 'auto',
});

const TermList = styled('ul', {
  marginLeft: 8,
  marginTop: 4,
  listStyleType: 'dics',
  listStylePosition: 'inside',

  '&:not(:last-child)': {
    marginBottom: 32,
  },
});

const TermListItem = styled('li', {
  listStyleType: 'disc',
});

const TermTitle = styled('div', {
  fontSize: 15,
  lineHeight: 1.4,
  fontWeight: 600,
  color: '$grayDark',
});
