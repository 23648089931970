import type { GalleryBlockComponent } from '../../../../../../base';
import { MediaContentViewer } from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { GalleryBlockComponentLayout } from './GalleryBlockComponentLayout';

interface Props {
  model: GalleryBlockComponent;
}

export function GalleryBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <GalleryBlockComponentLayout
          size={model.size}
          gap={model.gap}
          medias={model.medias.map((media, index) => (
            <MediaContentViewer key={index} content={media} />
          ))}
        />
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
