import { isNil, values } from '@flex-packages/utils';

export const BlockContentType = {
  Text: 0,
  Media: 1,
  Link: 2,
} as const;

export type BlockContentTypeKey = keyof typeof BlockContentType;
export type BlockContentTypeValue<
  Key extends BlockContentTypeKey = BlockContentTypeKey,
> = (typeof BlockContentType)[Key];

export interface BlockContent {
  readonly contentType: BlockContentTypeValue;
}

export function validateBlockContent(param: unknown): param is BlockContent {
  return (
    // is object
    typeof param === 'object' &&
    !isNil(param) &&
    // has content type
    'contentType' in param &&
    values(BlockContentType).includes(
      param.contentType as BlockContentTypeValue
    )
  );
}
