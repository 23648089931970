import { gap, Stack } from '@flex-design-system/utils-style';
import { isNil } from '@flex-packages/utils';
import type { ComponentProps } from 'react';

import { getMobileStyle } from '../../../../utils';

type StackVerticalProps = ComponentProps<typeof Stack.Vertical>;
export interface ResponsiveVerticalStackProps
  extends Omit<StackVerticalProps, 'css'> {
  mobileSpaceOverrides?: Pick<StackVerticalProps, 'space'>['space'];
}

export function ResponsiveVerticalStack({
  mobileSpaceOverrides,
  children,
  ...props
}: ResponsiveVerticalStackProps) {
  const mobileGapStyle = isNil(mobileSpaceOverrides)
    ? {}
    : getMobileStyle(
        gap({ direction: 'vertical', space: mobileSpaceOverrides })
      );

  return (
    <Stack.Vertical css={mobileGapStyle} {...props}>
      {children}
    </Stack.Vertical>
  );
}

type StackHorizontalProps = ComponentProps<typeof Stack.Horizontal>;
export interface ResponsiveHorizontalStackProps
  extends Omit<StackHorizontalProps, 'css'> {
  mobileSpaceOverrides?: Pick<StackVerticalProps, 'space'>['space'];
}

export function ResponsiveHorizontalStack({
  mobileSpaceOverrides,
  ...props
}: ResponsiveHorizontalStackProps) {
  const mobileGapStyle = isNil(mobileSpaceOverrides)
    ? {}
    : getMobileStyle(
        gap({ direction: 'horizontal', space: mobileSpaceOverrides })
      );

  return <Stack.Horizontal css={mobileGapStyle} {...props} />;
}
