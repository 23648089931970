import type { ParagraphBlockComponent } from '../../../../../../base';
import { TextContentViewer } from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { ParagraphBlockComponentLayout } from './ParagraphBlockComponentLayout';
import { getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: ParagraphBlockComponent;
}

export function ParagraphBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <ParagraphBlockComponentLayout
          size={model.size}
          title={
            <TextContentViewer
              content={model.title}
              {...getTitleTextStyle(model)}
            />
          }
          subText={
            model.subText && (
              <TextContentViewer
                content={model.subText}
                {...getSubTextStyle(model)}
              />
            )
          }
        />
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
