export const getUndefinedValueController = <U extends string>(
  undefinedValue: U
) => {
  const isUndefined = <T>(value: T | U): boolean => {
    return value === undefinedValue;
  };

  const withUndefinedValue = <T>(value?: T): T | U => {
    return value ?? undefinedValue;
  };

  const getValueWithoutUndefined = <T>(value: T | U): T | undefined => {
    return value === undefinedValue ? undefined : (value as T);
  };

  return { isUndefined, withUndefinedValue, getValueWithoutUndefined };
};
