import {
  ClickPopover,
  DatePicker,
  FieldBox,
  Flex,
  FormField,
  PrimitiveButton,
  Typography,
} from '@flex-design-system/fx';
import {
  ChevronDownMiniIcon,
  TodayIcon,
} from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { TimeV2 } from '@flex-packages/datetime-react/components';
import { Translation, useLanguage, useTranslation } from '@flex-packages/i18n';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function WorkExperienceJoinYearMonthInputFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();
  const locale = useLanguage();

  return (
    <Controller<
      JobDescriptionFormState,
      `workExperienceBackgrounds.${number}.joinDate`
    >
      name={`workExperienceBackgrounds.${currentIndex}.joinDate`}
      rules={{
        required: t(
          'recruiting.work_experience.join_date.edit.helper.required'
        ),
      }}
      render={({
        field: { value: joinDate, onChange, ...field },
        fieldState: { error },
      }) => {
        return (
          <FormField required state={error ? 'error' : 'default'} {...field}>
            <FormField.Label leftSlot={<TodayIcon />}>
              <Translation tKey="recruiting.work_experience.join_date" />
            </FormField.Label>
            <DatePicker
              mode="single"
              selectedDate={joinDate}
              onDateChange={onChange}
            >
              <ClickPopover>
                <ClickPopover.Trigger asChild>
                  <FormField.FieldBox asChild>
                    <PrimitiveButton css={{ width: '100%' }}>
                      <FieldBox.Content>
                        {joinDate ? (
                          <TimeV2
                            date={joinDate}
                            presetName="year_month"
                            locale={locale}
                          />
                        ) : (
                          <Typography color="grayLight" asChild>
                            <Translation tKey="recruiting.work_experience.join_date.edit.placeholder" />
                          </Typography>
                        )}
                      </FieldBox.Content>
                      <FieldBox.RightSlotRoot>
                        <ChevronDownMiniIcon size={22} color="grayLight" />
                      </FieldBox.RightSlotRoot>
                    </PrimitiveButton>
                  </FormField.FieldBox>
                </ClickPopover.Trigger>
                <ClickPopover.Content_Legacy>
                  <DatePicker.Content css={{ paddingTop: '6px' }}>
                    <DatePicker.MonthInput />
                    <DatePicker.MonthController>
                      {() => (
                        <ControllerRoot align="center" justify="end">
                          <ClearButton onClick={() => onChange(null)}>
                            <Translation tKey="recruiting.reset" />
                          </ClearButton>
                        </ControllerRoot>
                      )}
                    </DatePicker.MonthController>
                    <DatePicker.MonthCalendar />
                  </DatePicker.Content>
                </ClickPopover.Content_Legacy>
              </ClickPopover>
            </DatePicker>
            {error?.message && (
              <FormField.HelperText>{error.message}</FormField.HelperText>
            )}
          </FormField>
        );
      }}
    />
  );
}

const ControllerRoot = styled(Flex, {
  height: 28,
  px: 14,
});

const ClearButton = styled('button', {
  background: 'none',
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  cursor: 'pointer',

  color: '$gray',

  fontSize: 12,
  textDecoration: 'underline',
});
