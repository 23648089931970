import { CandidateMilitaryServiceStatus } from '@flex-apis/recruiting';
import type { Legacy_SelectProps } from '@flex-design-system/fx';
import { Legacy_Select } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';

import { MILITARY_SERVICE_CONTENT } from '../../base';

interface MilitaryServiceSelectProps
  extends Legacy_SelectProps<CandidateMilitaryServiceStatus> {
  militaryService?: CandidateMilitaryServiceStatus;
  onMilitaryServiceChange: (
    newMilitaryService: CandidateMilitaryServiceStatus
  ) => void;
}

const MILITARY_SERVICES: Array<CandidateMilitaryServiceStatus> = [
  CandidateMilitaryServiceStatus.Exempted,
  CandidateMilitaryServiceStatus.ExpirationTermOfService,
  CandidateMilitaryServiceStatus.InProgressTermOfService,
  CandidateMilitaryServiceStatus.Unfulfilled,
];

function MilitaryServiceSelect({
  children,
  militaryService,
  onMilitaryServiceChange,
  ...props
}: MilitaryServiceSelectProps) {
  return (
    <Legacy_Select<CandidateMilitaryServiceStatus>
      {...props}
      value={militaryService}
      onValueChange={onMilitaryServiceChange}
    >
      <Legacy_Select.Trigger asChild>{children}</Legacy_Select.Trigger>
      <Legacy_Select.Content onCloseAutoFocus={e => e.preventDefault()}>
        {MILITARY_SERVICES.map(service => (
          <Legacy_Select.Option key={service} value={service}>
            <Legacy_Select.OptionTitle>
              <Translation tKey={MILITARY_SERVICE_CONTENT[service].i18n} />
            </Legacy_Select.OptionTitle>
          </Legacy_Select.Option>
        ))}
      </Legacy_Select.Content>
    </Legacy_Select>
  );
}

export default MilitaryServiceSelect;
