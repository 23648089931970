import { values } from '@flex-packages/utils';

export const BlockComponentType = {
  // Text + Button
  TextButtonLeft: 0,
  TextButtonCenter: 1,
  // Text
  Text: 2,
  TextDouble: 3,
  // Image
  Media: 4,
  // Image + Text
  MediaTextSingle: 5,
  MediaTextMultiple: 6,
  MediaTextTriple: 7,
} as const;

export type BlockComponentTypeKey = keyof typeof BlockComponentType;
export type BlockComponentTypeValue<
  Key extends BlockComponentTypeKey = BlockComponentTypeKey,
> = (typeof BlockComponentType)[Key];

export const SizeStyle = {
  Default: 'DEFAULT',
  Small: 'SMALL',
  Large: 'LARGE',
} as const;

export type SizeStyleKey = keyof typeof SizeStyle;
export type SizeStyleValue<Key extends SizeStyleKey = SizeStyleKey> =
  (typeof SizeStyle)[Key];

export const AlignStyle = {
  Left: 'LEFT',
  Center: 'CENTER',
  Right: 'RIGHT',
} as const;

export type AlignStyleKey = keyof typeof AlignStyle;
export type AlignStyleValue<Key extends AlignStyleKey = AlignStyleKey> =
  (typeof AlignStyle)[Key];

export interface BlockComponent {
  readonly id: string;
  readonly type: BlockComponentTypeValue;
  readonly size: SizeStyleValue;
  readonly align: AlignStyleValue;
}

export function validateBlockComponent(
  param: unknown
): param is BlockComponent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has type
    'type' in param &&
    values(BlockComponentType).includes(
      param.type as BlockComponentTypeValue
    ) &&
    // has size
    'size' in param &&
    values(SizeStyle).includes(param.size as SizeStyleValue) &&
    // has align
    'align' in param &&
    values(AlignStyle).includes(param.align as AlignStyleValue)
  );
}
