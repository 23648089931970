import type { ListHorizontalBlockComponent } from '../../../../../../base';
import {
  MediaContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { ListHorizontalBlockComponentLayout } from './ListHorizontalBlockComponentLayout';
import { getSubTextStyle, getTitleTextStyle } from './style';

interface Props {
  model: ListHorizontalBlockComponent;
}

export function ListHorizontalBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <ListHorizontalBlockComponentLayout>
          {model.items.map(item => (
            <ListHorizontalBlockComponentLayout.Item
              key={item.id}
              align={model.align}
              title={
                <TextContentViewer
                  content={item.title}
                  {...getTitleTextStyle(model)}
                />
              }
              subText={
                <TextContentViewer
                  content={item.subText}
                  {...getSubTextStyle(model)}
                />
              }
              media={<MediaContentViewer content={item.media} />}
            />
          ))}
        </ListHorizontalBlockComponentLayout>
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
