import type { PropsWithChildren } from 'react';
import { memo } from 'react';

import type { RendererProps } from './context';
import { RendererPropsContext } from './context';

interface Props extends RendererProps {}

function _RendererPropsProvider({
  children,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <RendererPropsContext.Provider value={props}>
      {children}
    </RendererPropsContext.Provider>
  );
}

export const RendererPropsProvider = memo(_RendererPropsProvider);
