import {
  SiteDesignPageLinkType,
  SiteDesignPageType,
} from '@flex-apis/recruiting';
import type { SiteDesignPageResponse } from '@flex-apis/recruiting';

import type { BlockComponentV2PageContent } from '../page-content';
import {
  generateDefaultBlockComponentV2PageContent,
  generateEmptyBlockComponentV2PageContent,
  parseBlockComponentV2PageContentString,
} from '../page-content';

import type { PageComponent } from './page-component';
import { StaticUrlPagePath } from './page-component';

const MAIN_PAGE_CONTENT_DEFAULT_TITLE = '회사 소개';

export interface MainPageComponent extends PageComponent {
  readonly type: typeof SiteDesignPageType.Home;
  readonly linkType: typeof SiteDesignPageLinkType.Internal;
  readonly blockComponentPageContent: BlockComponentV2PageContent;
  readonly jobDescriptionPageContent: null;
}

export function isMainPageComponent(
  pageComponent: PageComponent
): pageComponent is MainPageComponent {
  return pageComponent.type === SiteDesignPageType.Home;
}

export function generateMainPageComponent(params: {
  siteDesignPageResponse: SiteDesignPageResponse;
}): MainPageComponent {
  const { siteDesignPageResponse } = params;
  return {
    type: SiteDesignPageType.Home,
    linkType: SiteDesignPageLinkType.Internal,
    title: siteDesignPageResponse.title,
    urlPagePath: StaticUrlPagePath.Main,
    href: siteDesignPageResponse.href ?? null,
    enabled: siteDesignPageResponse.enabled,
    blockComponentPageContent: parseBlockComponentV2PageContentString(
      siteDesignPageResponse.content?.content ?? ''
    ),
    jobDescriptionPageContent: null,
  };
}

// AI 자동생성 케이스를 처리하는 기본 페이지 생성 함수
export function getEmptyMainPageComponent(): MainPageComponent {
  return {
    type: SiteDesignPageType.Home,
    linkType: SiteDesignPageLinkType.Internal,
    title: MAIN_PAGE_CONTENT_DEFAULT_TITLE,
    urlPagePath: StaticUrlPagePath.Main,
    href: null,
    enabled: true,
    blockComponentPageContent: generateEmptyBlockComponentV2PageContent(),
    jobDescriptionPageContent: null,
  };
}

export function getDefaultMainPageComponent(): MainPageComponent {
  return {
    type: SiteDesignPageType.Home,
    linkType: SiteDesignPageLinkType.Internal,
    title: MAIN_PAGE_CONTENT_DEFAULT_TITLE,
    urlPagePath: StaticUrlPagePath.Main,
    href: null,
    enabled: true,
    blockComponentPageContent: generateDefaultBlockComponentV2PageContent(),
    jobDescriptionPageContent: null,
  };
}
