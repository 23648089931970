import type { CoverHalfBlockComponent } from '../../../../../../base';
import { AlignStyle, SizeStyle } from '../../../../../../base';
import type { LinkStyle, TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<CoverHalfBlockComponent, 'size'>
): TextStyle {
  const isSmall = model.size === SizeStyle.Small;

  return {
    align: AlignStyle.Left,
    color: 'grayDarker',
    size: isSmall ? 36 : 56,
    mobileSize: isSmall ? 28 : 32,
    weight: 700,
    lineHeight: 1.3,
    letterSpacing: isSmall ? '-1.08px' : '-1.68px',
    mobileLetterSpacing: isSmall ? '-0.84px' : '-0.96px',
  };
}

export function getSubTextStyle(
  model: Pick<CoverHalfBlockComponent, 'size'>
): TextStyle {
  const isSmall = model.size === SizeStyle.Small;

  return {
    align: AlignStyle.Left,
    color: 'grayLight',
    size: isSmall ? 17 : 24,
    mobileSize: 16,
    weight: 500,
    lineHeight: 1.5,
    letterSpacing: isSmall ? '-0.17px' : '-0.24px',
    mobileLetterSpacing: '-0.16px',
  };
}

export function getLinkStyle(): LinkStyle {
  return {
    color: 'negative',
  };
}
