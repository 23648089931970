import type { MediaContent, TextContent } from '../block-content';
import { validateMediaContent, validateTextContent } from '../block-content';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Left] as const;
const VALID_SIZE_STYLES = [SizeStyle.Default] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];
type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

export interface MediaTextSingleBlockComponent extends BlockComponent {
  readonly type: BlockComponentTypeValue<'MediaTextSingle'>;
  readonly align: ValidAlignStyle;
  readonly size: ValidSizeStyle;

  readonly media: MediaContent;
  readonly title: TextContent;
  readonly subText: TextContent;
}

export function validateMediaTextSingleBlockComponent(
  param: unknown
): param is MediaTextSingleBlockComponent {
  return (
    // is block component
    validateBlockComponent(param) &&
    param.type === BlockComponentType.MediaTextSingle &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle) &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has media
    'media' in param &&
    validateMediaContent(param.media) &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    validateTextContent(param.subText)
  );
}
