import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { SiteDesign, StaticUrlPagePath } from '../../../../base';

export interface RendererProps {
  siteDesign: SiteDesign;
  isHeaderExpended: boolean;
  urlPagePath: StaticUrlPagePath | string;
  setHeaderExpended: Dispatch<SetStateAction<boolean>>;
}

export const RendererPropsContext = createContext<RendererProps | null>(null);
