import { TextField } from '@flex-design-system/fx';
import type { TextFieldProps } from '@flex-design-system/fx';
import { SchoolIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation } from '@flex-packages/i18n';

type EducationInstitutionNameInputProps = Omit<
  TextFieldProps,
  'type' | 'label' | 'variant'
>;

function EducationInstitutionNameInput({
  helperText,
  ...props
}: EducationInstitutionNameInputProps) {
  return (
    <TextField
      type="text"
      variant="solid"
      label={
        <TextField.Label_Legacy leftSlot={<SchoolIcon />}>
          <Translation tKey="recruiting.educational_background.institution_name" />
        </TextField.Label_Legacy>
      }
      helperText={<TextField.HelperText>{helperText}</TextField.HelperText>}
      {...props}
    />
  );
}

export default EducationInstitutionNameInput;
