import { Flex, Typography } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';

export const Container = styled(Flex, {
  gap: 10,
  width: '100%',
});

export const FileContentInfo = styled(Typography, {
  flex: 1,
});
