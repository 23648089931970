import { values } from '@flex-packages/utils';

import type {
  AlignStyleValue as AlignStyleValue_V1,
  BlockComponentTypeValue as BlockComponentTypeValue_V1,
  SizeStyleValue as SizeStyleValue_V1,
} from '../../block-v1-legacy';
import {
  AlignStyle as AlignStyle_V1,
  BlockComponentType as BlockComponentType_V1,
  SizeStyle as SizeStyle_V1,
} from '../../block-v1-legacy';
import type { BlockComponent as BlockComponent_V1 } from '../../block-v1-legacy/block-component/block-component';

export const BlockComponentType = {
  Cover: 'COVER',
  CoverHalf: 'COVER_HALF',
  About: 'ABOUT',
  Gallery: 'GALLERY',
  Banner: 'BANNER',
  Paragraph: 'PARAGRAPH',
  Quote: 'QUOTE',
  Columns: 'COLUMNS',
  ListVertical: 'LIST_VERTICAL',
  ListHorizontal: 'LIST_HORIZONTAL',

  CoverCompatible: 'COVER_COMPATIBLE',

  Error: 'ERROR',
} as const;

export type BlockComponentTypeKey = keyof typeof BlockComponentType;
export type BlockComponentTypeValue<
  Key extends BlockComponentTypeKey = BlockComponentTypeKey,
> = (typeof BlockComponentType)[Key];

export function migrateBlockComponentTypeV1(
  blockComponentType: BlockComponentTypeValue_V1
): BlockComponentTypeValue {
  switch (blockComponentType) {
    case BlockComponentType_V1.Text:
      return BlockComponentType.Paragraph;
    case BlockComponentType_V1.TextDouble:
      return BlockComponentType.Cover;
    case BlockComponentType_V1.TextButtonLeft:
      return BlockComponentType.Cover;
    case BlockComponentType_V1.TextButtonCenter:
      return BlockComponentType.CoverCompatible;
    case BlockComponentType_V1.Media:
      return BlockComponentType.Gallery;
    case BlockComponentType_V1.MediaTextSingle:
      return BlockComponentType.ListHorizontal;
    case BlockComponentType_V1.MediaTextMultiple:
      return BlockComponentType.ListHorizontal;
    case BlockComponentType_V1.MediaTextTriple:
      return BlockComponentType.ListHorizontal;
  }
}
export const SizeStyle = {
  Default: 'DEFAULT',
  Small: 'SMALL',
  Large: 'LARGE',
} as const;

export type SizeStyleKey = keyof typeof SizeStyle;
export type SizeStyleValue<Key extends SizeStyleKey = SizeStyleKey> =
  (typeof SizeStyle)[Key];

export function migrateSizeStyleV1(
  sizeStyle: SizeStyleValue_V1
): SizeStyleValue {
  switch (sizeStyle) {
    case SizeStyle_V1.Default:
      return SizeStyle.Default;
    case SizeStyle_V1.Small:
      return SizeStyle.Small;
    case SizeStyle_V1.Large:
      return SizeStyle.Large;
  }
}

export const AlignStyle = {
  Left: 'LEFT',
  Center: 'CENTER',
  Right: 'RIGHT',
  Side: 'SIDE',
} as const;

export type AlignStyleKey = keyof typeof AlignStyle;
export type AlignStyleValue<Key extends AlignStyleKey = AlignStyleKey> =
  (typeof AlignStyle)[Key];

export function migrateAlignStyleV1(
  alignStyle: AlignStyleValue_V1
): AlignStyleValue {
  switch (alignStyle) {
    case AlignStyle_V1.Left:
      return AlignStyle.Left;
    case AlignStyle_V1.Center:
      return AlignStyle.Center;
    case AlignStyle_V1.Right:
      return AlignStyle.Right;
  }
}

export interface BlockComponent {
  readonly componentType: BlockComponentTypeValue;
  readonly id: string;
  readonly content?: string;
}

export function migrateBlockComponentV1(
  blockComponent: BlockComponent_V1
): BlockComponent {
  const { type, id } = blockComponent;

  return {
    componentType: migrateBlockComponentTypeV1(type),
    id: id,
  };
}

export function validateBlockComponent(
  param: unknown
): param is BlockComponent {
  return (
    // is object
    // has content
    typeof param === 'object' &&
    param !== null &&
    // has id
    'id' in param &&
    typeof param.id === 'string' &&
    // has type
    'componentType' in param &&
    values(BlockComponentType).includes(
      param.componentType as BlockComponentTypeValue
    ) &&
    ('content' in param ? typeof param.content === 'string' : true)
  );
}
