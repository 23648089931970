export const BACKGROUND_CONTENT_FIELD_Z_INDEX = 100;
export const TEXT_CONTENT_FIELD_Z_INDEX = 200;
export const LINK_CONTENT_FIELD_Z_INDEX = 200;
export const MEDIA_CONTENT_FIELD_Z_INDEX = 200;

export const BLOCK_CONTENT_MAX_Z_INDEX = Math.max(
  BACKGROUND_CONTENT_FIELD_Z_INDEX,
  TEXT_CONTENT_FIELD_Z_INDEX,
  LINK_CONTENT_FIELD_Z_INDEX,
  MEDIA_CONTENT_FIELD_Z_INDEX
);

export const BACKGROUND_CONTENT_CLASS_NAME =
  'recruiting-site-builder-background-content';
export const LINK_CONTENT_CLASS_NAME = 'recruiting-site-builder-link-content';
export const TEXT_CONTENT_CLASS_NAME = 'recruiting-site-builder-text-content';
export const MEDIA_CONTENT_CLASS_NAME = 'recruiting-site-builder-media-content';
