import { useGetEmploymentContractTypesInUsedRemote } from '@flex-apis/recruiting-hooks';
import { styled } from '@flex-design-system/stitches-react';
import { usePublicJobGroupList } from '@flex-private-packages/recruiting-common/hooks';
import { useState } from 'react';

import type { PublicJobDescriptionFilter } from '../../../../base';
import { BlockComponentSection } from '../block-editor';

import PublicJobDescriptionListContent from './Content';
import Filter from './Filter/Filter';
import usePublicJobDescriptions from './hooks/usePublicJobDescriptions';

interface Props {
  customerIdHash: string;
}

export function PublicJobDescriptionListRenderer({ customerIdHash }: Props) {
  const [filter, setFilter] = useState<PublicJobDescriptionFilter>({});
  const { publicJobDescriptions, isLoading: publicJobDescriptionsLoading } =
    usePublicJobDescriptions(
      { customerIdHash, ...filter },
      { suspense: false }
    );

  const { jobGroupList, isLoading: jobGroupListLoading } =
    usePublicJobGroupList({ customerIdHash }, { suspense: false });
  const { data: contractTypes = [], isLoading: contractTypesLoading } =
    useGetEmploymentContractTypesInUsedRemote(
      { customerIdHash },
      { suspense: false }
    );

  return (
    <BlockComponentSection>
      <BlockComponentSection.Content>
        <Wrapper>
          <Filter
            isLoading={jobGroupListLoading || contractTypesLoading}
            jobGroupList={jobGroupList}
            contractTypes={contractTypes}
            filter={filter}
            onChangeFilter={setFilter}
          />
          <PublicJobDescriptionListContent
            publicJobDescriptions={publicJobDescriptions}
            publicJobDescriptionsLoading={publicJobDescriptionsLoading}
            filter={filter}
          />
        </Wrapper>
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}

const Wrapper = styled('section', {
  width: '100%',

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',

  gap: 60,
});
