import { values } from '@flex-packages/utils';

import type {
  LinkContent as LinkContent_V1,
  LinkTypeValue as LinkTypeValue_V1,
} from '../../block-v1-legacy';
import { LinkType as LinkType_V1 } from '../../block-v1-legacy';

import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export const LinkType = {
  Internal: 'INTERNAL',
  External: 'EXTERNAL',
} as const;

export type LinkTypeKey = keyof typeof LinkType;
export type LinkTypeValue<Key extends LinkTypeKey = LinkTypeKey> =
  (typeof LinkType)[Key];

export function migrateLinkTypeV1(linkType: LinkTypeValue_V1): LinkTypeValue {
  switch (linkType) {
    case LinkType_V1.Internal:
      return LinkType.Internal;
    case LinkType_V1.External:
      return LinkType.External;
  }
}

export interface LinkContent extends BlockContent {
  readonly contentType: BlockContentTypeValue<'Link'>;

  readonly linkType: LinkTypeValue;
  readonly text: string;
  readonly href: string;
}

export function generateDefaultLinkContent(): LinkContent {
  return {
    contentType: BlockContentType.Link,
    linkType: LinkType.Internal,
    href: 'job-descriptions',
    text: '채용 중인 공고 보기',
  };
}

export function migrateLinkContentV1(linkContent: LinkContent_V1): LinkContent {
  return {
    contentType: BlockContentType.Link,
    linkType: migrateLinkTypeV1(linkContent.linkType),
    text: linkContent.text,
    href: linkContent.href,
  };
}

export function validateLinkContent(param: unknown): param is LinkContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Link &&
    // has link type
    'linkType' in param &&
    values(LinkType).includes(param.linkType as LinkTypeValue) &&
    // has text
    'text' in param &&
    typeof param.text === 'string' &&
    // has href
    'href' in param &&
    typeof param.href === 'string'
  );
}
