import type { SiteDesign } from '@flex-private-packages/recruiting-site-builder/base';
import { StaticUrlPagePath } from '@flex-private-packages/recruiting-site-builder/base';
import { SiteDesignRenderer } from '@flex-private-packages/recruiting-site-builder/components';
import { QueryAsyncBoundary } from '@flex-services/async-boundary';
import { useState } from 'react';

import { NextHead } from '../../head';
import { getSEODescription, getSEOTitle } from '../../logics';

interface Props {
  siteDesign: SiteDesign;
}

export function JobDescriptionListPage({ siteDesign }: Props) {
  const [isHeaderExpended, setHeaderExpended] = useState(false);

  const title = getSEOTitle(siteDesign);
  const description = getSEODescription(siteDesign);
  const image = siteDesign.logoImgUrl;

  return (
    <>
      <NextHead
        title={title}
        description={description}
        faviconUrl={siteDesign.faviconImgUrl}
        og={{ title, description, image, twitterCardType: 'summary' }}
        extra={
          <style
            dangerouslySetInnerHTML={{
              __html: `body { overflow: ${
                isHeaderExpended ? 'hidden' : 'auto'
              }; }`,
            }}
          />
        }
      />
      <QueryAsyncBoundary pendingFallback={null}>
        <SiteDesignRenderer
          css={{ minHeight: '100vh' }}
          siteDesign={siteDesign}
          urlPagePath={StaticUrlPagePath.JobDescriptionList}
          isHeaderExpended={isHeaderExpended}
          setHeaderExpended={setHeaderExpended}
        />
      </QueryAsyncBoundary>
    </>
  );
}
