import { Empty, Flex } from '@flex-design-system/fx';
import { FaceNormalIcon } from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import type { ReactNode } from 'react';

import { getMobileStyle } from '../../utils';

interface Props {
  title: ReactNode;
  subtext?: ReactNode;
}

export default function EmptyComp(props: Props) {
  const { title, subtext } = props;
  return (
    <Container align="center" justify="center">
      <Empty
        size="large"
        topSlot={<FaceNormalIcon color="blackAlpha012" />}
        title={<Empty.Title>{title}</Empty.Title>}
        subtext={subtext ? <Empty.Subtext>{subtext}</Empty.Subtext> : null}
      />
    </Container>
  );
}

const Container = styled(Flex, {
  background: '$background',
  borderRadius: 10,

  py: 180,
  ...getMobileStyle({
    py: 40,
  }),
});
