import { Flex, Typography } from '@flex-design-system/fx';
import type { StitchesCSS } from '@flex-design-system/stitches-react';
import { styled } from '@flex-design-system/stitches-react';
import { isNonNil } from '@flex-packages/utils';
import type { ReactNode } from 'react';

export function FormSection({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <Flex direction="column" align="stretch" css={{ gap: 26 }}>
      {title}
      <Flex direction="column" align="stretch" css={{ gap: 34 }}>
        {children}
      </Flex>
    </Flex>
  );
}

function FormSectionTitle({
  children,
  subtext,
  rightSlot,
}: {
  children: ReactNode;
  subtext?: ReactNode;
  rightSlot?: ReactNode;
}) {
  return (
    <Flex direction="row" align="center" justify="between" style={{ gap: 16 }}>
      <Flex direction="column" align="start" css={{ gap: 8 }}>
        <Typography size={16} weight={600}>
          {children}
        </Typography>
        {subtext}
      </Flex>
      {isNonNil(rightSlot) && <RightSlotRoot>{rightSlot}</RightSlotRoot>}
    </Flex>
  );
}

FormSection.Title = FormSectionTitle;
FormSection.Subtext = ({
  children,
  css,
}: {
  children: ReactNode;
  css?: StitchesCSS;
}) => {
  return (
    <Typography size={13} weight={400} color="grayLight" css={css}>
      {children}
    </Typography>
  );
};

const RightSlotRoot = styled('div', { flexShrink: 0 });
