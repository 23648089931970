import type { PropsWithChildren } from 'react';

import { BlockComponentSection } from '../../block-editor';

interface Props {}

export function ContentSection({ children }: PropsWithChildren<Props>) {
  return (
    <BlockComponentSection>
      <BlockComponentSection.Content
        css={{ maxWidth: 640, boxSizing: 'content-box' }}
      >
        {children}
      </BlockComponentSection.Content>
    </BlockComponentSection>
  );
}
