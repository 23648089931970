import type { TypographyProps } from '@flex-design-system/fx';
import type { PresetColorType } from '@flex-design-system/fx-colors';
import type { StitchesCSS } from '@flex-design-system/stitches-react';

import type { AlignStyleValue } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

export type TextAlignType = 'start' | 'center' | 'end';

export interface TextStyle {
  align: AlignStyleValue;
  mobileAlign?: AlignStyleValue;
  size: TypographyProps['size'];
  mobileSize?: TypographyProps['size'];
  weight: TypographyProps['weight'];
  mobileWeight?: TypographyProps['weight'];
  lineHeight: TypographyProps['lineHeight'];
  mobileLineHeight?: TypographyProps['lineHeight'];
  letterSpacing?: number | string;
  mobileLetterSpacing?: number | string;
  color?: PresetColorType;
}

export function getTextCSS({
  color,
  align,
  mobileAlign,
  size,
  mobileSize,
  weight,
  mobileWeight,
  lineHeight,
  mobileLineHeight,
  letterSpacing,
  mobileLetterSpacing,
}: TextStyle) {
  return {
    color: `$${color}`,
    textAlign: parseAlignStyleToTextAlignType(align),
    fontSize: size,
    fontWeight: weight,
    lineHeight: lineHeight,
    letterSpacing: letterSpacing,
    ...getMobileStyle({
      textAlign: mobileAlign && parseAlignStyleToTextAlignType(mobileAlign),
      fontSize: mobileSize,
      fontWeight: mobileWeight,
      lineHeight: mobileLineHeight,
      letterSpacing: mobileLetterSpacing,
    }),
  } satisfies StitchesCSS;
}

function parseAlignStyleToTextAlignType(
  alignStyle: AlignStyleValue
): TextAlignType {
  switch (alignStyle) {
    case AlignStyle.Center:
      return 'center';
    case AlignStyle.Left:
      return 'start';
    case AlignStyle.Right:
      return 'end';
    case AlignStyle.Side:
      return 'start';
  }
}
