import type { BannerBlockComponent } from '../../../../../../base';
import type { LinkStyle, TextStyle } from '../../../block-content-field';

export function getTitleTextStyle(
  model: Pick<BannerBlockComponent, 'align'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayDarker',
    size: 24,
    weight: 700,
    lineHeight: 1.3,
    letterSpacing: '-0.72px',
  };
}

export function getSubTextStyle(
  model: Pick<BannerBlockComponent, 'align'>
): TextStyle {
  return {
    align: model.align,
    color: 'grayLight',
    size: 16,
    mobileSize: 20,
    weight: 600,
    mobileWeight: 700,
    lineHeight: 1.5,
    letterSpacing: '-0.16px',
    mobileLetterSpacing: '-0.6px',
  };
}

export function getLinkStyle(): LinkStyle {
  return { color: 'negative' };
}
