import type { TextContent } from '../block-content';
import { validateTextContent } from '../block-content';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Left, AlignStyle.Center] as const;
const VALID_SIZE_STYLES = [SizeStyle.Default, SizeStyle.Large] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];
type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

export interface TextBlockComponent extends BlockComponent {
  readonly type: BlockComponentTypeValue<'Text'>;
  readonly align: ValidAlignStyle;
  readonly size: ValidSizeStyle;

  readonly text: TextContent;
}

export function validateTextBlockComponent(
  param: unknown
): param is TextBlockComponent {
  return (
    // is block component
    validateBlockComponent(param) &&
    param.type === BlockComponentType.Text &&
    VALID_ALIGN_STYLES.includes(param.align as ValidAlignStyle) &&
    VALID_SIZE_STYLES.includes(param.size as ValidSizeStyle) &&
    // has text
    'text' in param &&
    validateTextContent(param.text)
  );
}
