import { useTranslation } from '@flex-packages/i18n';
import {
  WorkExperienceCompanyNameInput,
  WorkExperienceStatusSelect,
} from '@flex-private-packages/recruiting-common/components';
import { Controller } from 'react-hook-form';

import type { JobDescriptionFormState } from '../../../../models';

interface Props {
  currentIndex: number;
}

export function WorkExperienceCompanyNameAndStatusFormItem({
  currentIndex,
}: Props) {
  const { t } = useTranslation();

  return (
    <Controller<
      JobDescriptionFormState,
      `workExperienceBackgrounds.${typeof currentIndex}.name`
    >
      name={`workExperienceBackgrounds.${currentIndex}.name`}
      rules={{
        required: t('recruiting.work_experience.retirement_date.placeholder'),
      }}
      render={({ field: nameField, fieldState: { error: nameError } }) => (
        <Controller<
          JobDescriptionFormState,
          `workExperienceBackgrounds.${typeof currentIndex}.workExperienceStatus`
        >
          name={`workExperienceBackgrounds.${currentIndex}.workExperienceStatus`}
          rules={{
            required: t(
              'recruiting.work_experience.status.edit.helper.required'
            ),
          }}
          render={({
            field: {
              onChange: onWorkExperienceStatusTypeChange,
              ...selectField
            },
            fieldState: { error: workExperienceStatusTypeError },
          }) => {
            const errorState =
              nameError || workExperienceStatusTypeError ? 'error' : 'default';
            const helperText =
              nameError?.message ?? workExperienceStatusTypeError?.message;

            return (
              <WorkExperienceCompanyNameInput
                required
                state={errorState}
                helperText={helperText}
                {...nameField}
              >
                <WorkExperienceStatusSelect
                  onValueChange={onWorkExperienceStatusTypeChange}
                  {...selectField}
                />
              </WorkExperienceCompanyNameInput>
            );
          }}
        />
      )}
    />
  );
}
