import { trans, type TranslationKey } from '@flex-packages/i18n-base';

import { RecruitingPeriodType } from '../models';

export const PERIOD_TYPE_CONTENT: Record<
  RecruitingPeriodType,
  { i18n: TranslationKey; order: number }
> = {
  [RecruitingPeriodType.Periodic]: {
    i18n: trans('recruiting.period.type.기간_선택'),
    order: 1,
  },
  [RecruitingPeriodType.Occasional]: {
    i18n: trans('recruiting.period.type.상시_채용'),
    order: 2,
  },
};
