import { resource } from '@flex-packages/static-resource';
import Head from 'next/head';

import type { OGOption } from './types';

interface Props {
  title?: string;
  description?: string;
  faviconUrl?: string;
  og?: OGOption;
  extra?: React.ReactNode;
  notAllowedRobots?: boolean;
}

export function NextHead({
  extra,
  title = 'flex',
  description,
  og,
  faviconUrl,
  notAllowedRobots = false,
}: Props) {
  return (
    <Head>
      <title>{title}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {description && <meta name="description" content={description} />}
      <link
        rel="icon"
        sizes="16x16"
        href={faviconUrl ?? resource('/v2/shared/favicon/favicon-16x16.png')}
      />
      <link
        rel="icon"
        sizes="32x32"
        href={faviconUrl ?? resource('/v2/shared/favicon/favicon-32x32.png')}
      />
      <link
        rel="icon"
        sizes="any" // https://www.leereamsnyder.com/blog/favicons-in-2021#update
        href={faviconUrl ?? resource('/v2/shared/favicon/favicon.ico')}
      />
      <link
        rel="icon"
        type="image/svg+xml"
        href={faviconUrl ?? resource('/v2/shared/favicon/favicon.svg')}
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={faviconUrl ?? resource('/v2/shared/favicon/apple-touch-icon.png')}
      />
      <link
        rel="manifest"
        href={resource('/v2/shared/favicon/site.webmanifest')}
      />
      <meta name="msapplication-TileColor" content="#333333" />
      <meta
        name="msapplication-config"
        content={resource('/v2/shared/favicon-210521/browserconfig.xml')}
      />
      <meta name="theme-color" content="#333333" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {notAllowedRobots && <meta name="robots" content="noindex nofollow" />}
      {og && (
        <>
          <meta property="og:title" content={og.title} />
          <meta property="og:description" content={og.description} />
          <meta property="og:image" content={og.image} />
          <meta name="twitter:card" content={og.twitterCardType ?? 'summary'} />
          <meta name="twitter:title" content={og.title} />
          <meta name="twitter:description" content={og.description} />
          <meta name="twitter:image" content={og.image} />
        </>
      )}
      {extra}
    </Head>
  );
}
