import { IconButton } from '@flex-design-system/fx';
import type { IconButtonProps } from '@flex-design-system/fx';
import { DragHandleIcon } from '@flex-design-system/legacy-flex-icons';

type ItemHeaderDragButtonProps = Omit<IconButtonProps, 'variant' | 'icon'>;

function ItemHeaderDragButton(props: ItemHeaderDragButtonProps) {
  return (
    <IconButton
      size="small"
      variant="ghost"
      icon={<DragHandleIcon color="grayLighter" />}
      {...props}
    />
  );
}

export default ItemHeaderDragButton;
