import type { StackProps } from '@flex-design-system/utils-style';
import { Stack } from '@flex-design-system/utils-style';
import type { ReactNode } from 'react';

import ContractTypeInputFormItem from './item';
import CandidateBackgroundListItemContent from './item/Content';
import CandidateBackgroundListItemHeader from './item/Header';

interface CandidateBackgroundListProps extends Omit<StackProps, 'direction'> {
  children: ReactNode;
}

function CandidateBackgroundList({ children }: CandidateBackgroundListProps) {
  return (
    <Stack.Vertical align="normal" space={8} elementType="ul" css={{ my: 0 }}>
      {children}
    </Stack.Vertical>
  );
}

CandidateBackgroundList.Item = ContractTypeInputFormItem;
CandidateBackgroundList.ItemHeader = CandidateBackgroundListItemHeader;
CandidateBackgroundList.ItemContent = CandidateBackgroundListItemContent;

export default CandidateBackgroundList;
