import {
  Divider,
  Flex,
  Primitive,
  PrimitiveAnchor,
  Typography,
} from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { Translation } from '@flex-packages/i18n';
import { FlexLogo } from '@flex-services/shared-ui/assets';
import type { ComponentProps } from 'react';
import { Fragment } from 'react';

import type { SiteDesignLink } from '../../../../../../base';
import {
  CONTENT_HORIZONTAL_PADDING,
  CONTENT_HORIZONTAL_PADDING_MOBILE,
  CONTENT_MAX_WIDTH,
} from '../../../../configs/style';
import { getMobileStyle } from '../../../../utils';

function PageLayoutFooter({
  children,
  ...rootProps
}: Omit<ComponentProps<typeof PageLayoutFooterRoot>, 'as' | 'asChild'>) {
  return (
    <PageLayoutFooterRoot {...rootProps} as="footer">
      {children}
    </PageLayoutFooterRoot>
  );
}

interface ContentProps
  extends Omit<
    ComponentProps<typeof PageLayoutFooterContentRoot>,
    'children' | 'space'
  > {
  customerName?: string;
  customerAddress?: string;
  customerTelephoneNumber?: string;
  representativeEmail?: string;
  footerLinks: Array<SiteDesignLink>;
  privacyPolicyUrl?: string;
}

function PageLayoutFooterContent({
  customerName,
  customerAddress,
  customerTelephoneNumber,
  representativeEmail,
  footerLinks,
  privacyPolicyUrl,
  ...rootProps
}: ContentProps) {
  return (
    <PageLayoutFooterContentRoot direction="column" {...rootProps}>
      <PageLayoutFooterCustomerName customerName={customerName} />
      <PageLayoutFooterCustomerInfo
        customerName={customerName}
        customerAddress={customerAddress}
        customerTelephoneNumber={customerTelephoneNumber}
        representativeEmail={representativeEmail}
      />
      <PageLayoutFooterLinks
        footerLinks={footerLinks}
        privacyPolicyUrl={privacyPolicyUrl}
      />
      <PoweredByFlexLogo />
    </PageLayoutFooterContentRoot>
  );
}

function PageLayoutFooterCustomerName({
  customerName,
}: Pick<ContentProps, 'customerName'>) {
  return (
    <Typography size={15} lineHeight={1.4} color="gray" weight={600}>
      {customerName}
    </Typography>
  );
}

function PageLayoutFooterCustomerInfo({
  customerName,
  customerAddress,
  customerTelephoneNumber,
  representativeEmail,
}: Pick<
  ContentProps,
  | 'customerName'
  | 'customerAddress'
  | 'customerTelephoneNumber'
  | 'representativeEmail'
>) {
  return (
    <Flex direction="column" css={{ gap: 4 }}>
      {customerAddress && (
        <Typography size={13} lineHeight="paragraph" color="grayLight">
          {customerAddress}
        </Typography>
      )}
      {customerTelephoneNumber && (
        <PrimitiveAnchor href={`tel:${customerTelephoneNumber}`}>
          <Typography size={13} lineHeight="paragraph" color="grayLight">
            {customerTelephoneNumber}
          </Typography>
        </PrimitiveAnchor>
      )}
      {representativeEmail && (
        <PrimitiveAnchor href={`mailto:${representativeEmail}`}>
          <Typography
            size={13}
            lineHeight="paragraph"
            color="grayLight"
            asChild
          >
            <Translation
              tKey="recruiting.site_builder.footer.recruit_inquiry"
              tOptions={{ values: { email: representativeEmail } }}
            />
          </Typography>
        </PrimitiveAnchor>
      )}
      {customerName && (
        <Typography size={13} lineHeight="paragraph" color="grayLight">
          © {customerName}. All rights Reserved
        </Typography>
      )}
    </Flex>
  );
}

function PageLayoutFooterLinks({
  footerLinks,
  privacyPolicyUrl,
}: Pick<ContentProps, 'footerLinks' | 'privacyPolicyUrl'>) {
  const validatedPrivacyPolicyUrl = (() => {
    if (typeof privacyPolicyUrl !== 'string') {
      return undefined;
    }

    try {
      const url = new URL(privacyPolicyUrl);
      return url.href;
    } catch {
      return undefined;
    }
  })();

  return (
    <HStack>
      {validatedPrivacyPolicyUrl && (
        <>
          <PrimitiveAnchor
            href={validatedPrivacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Typography
              size={13}
              lineHeight="paragraph"
              color="gray"
              weight={700}
              asChild
            >
              <Translation tKey="recruiting.site_builder.footer.privacy_policy" />
            </Typography>
          </PrimitiveAnchor>
          {footerLinks.length > 0 && <Divider orientation="vertical" />}
        </>
      )}
      {footerLinks.map((link, index, arr) => {
        const hasNextItem = index !== arr.length - 1;

        return (
          // 순서변경이 지원되게 된다면 index가 아니라 id 필드를 만들어서 key에 넣어줘야함
          <Fragment key={`${link.title}-${link.href}-${index}`}>
            <PrimitiveAnchor href={link.href} target="_blank" rel="noreferrer">
              <Typography
                size={13}
                lineHeight="paragraph"
                color="grayLight"
                weight={700}
              >
                {link.title}
              </Typography>
            </PrimitiveAnchor>
            {hasNextItem && <Divider orientation="vertical" />}
          </Fragment>
        );
      })}
    </HStack>
  );
}

function PoweredByFlexLogo() {
  return (
    <Flex direction="row" align="center" css={{ gap: 8 }}>
      <Typography color="grayLighter" size={12} lineHeight={1.4}>
        Powered by
      </Typography>
      <PrimitiveAnchor
        href="https://flex.team"
        target="_blank"
        rel="noreferrer"
      >
        <FlexLogo
          width={33}
          height={15}
          variant="text"
          color="#C8CCD0"
          style={{ width: 33, height: 15 }}
        />
      </PrimitiveAnchor>
    </Flex>
  );
}

const HStack = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: 6,
  columnGap: 4,
});

const PageLayoutFooterContentRoot = styled(Flex, {
  maxWidth: CONTENT_MAX_WIDTH,
  mx: 'auto',
  px: CONTENT_HORIZONTAL_PADDING,
  py: 50,
  gap: 14,

  ...getMobileStyle({
    px: CONTENT_HORIZONTAL_PADDING_MOBILE,
  }),
});

const PageLayoutFooterRoot = styled(Primitive.div, {
  position: 'relative',
  background: '$blackAlpha003',
});

PageLayoutFooter.Content = PageLayoutFooterContent;
PageLayoutFooter.CustomerName = PageLayoutFooterCustomerName;
PageLayoutFooter.CustomerInfo = PageLayoutFooterCustomerInfo;

export default PageLayoutFooter;
