import type { BannerBlockComponent } from '../../../../../../base';
import {
  BackgroundContentViewer,
  LinkContentViewer,
  TextContentViewer,
} from '../../../block-content-field';
import { BlockComponentSection } from '../../block-component-section';

import { BannerBlockComponentLayout } from './BannerBlockComponentLayout';
import { getLinkStyle, getSubTextStyle, getTitleTextStyle } from './styles';

interface Props {
  model: BannerBlockComponent;
}

export function BannerBlockComponentRenderer({ model }: Props) {
  return (
    <BlockComponentSection>
      <BackgroundContentViewer content={model.background}>
        <BlockComponentSection.Content>
          <BannerBlockComponentLayout
            align={model.align}
            title={
              <TextContentViewer
                content={model.title}
                {...getTitleTextStyle(model)}
              />
            }
            subText={
              model.subText && (
                <TextContentViewer
                  content={model.subText}
                  {...getSubTextStyle(model)}
                />
              )
            }
            link={
              <LinkContentViewer content={model.link} {...getLinkStyle()} />
            }
          />
        </BlockComponentSection.Content>
      </BackgroundContentViewer>
    </BlockComponentSection>
  );
}
