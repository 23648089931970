export const PageContentType = {
  JobDescriptionListVersion1: 0,
  BlocComponentVersion1: 1,
  BlocComponentVersion2: 2,
} as const;

export type PageContentTypeKey = keyof typeof PageContentType;
export type PageContentTypeValue<
  Key extends PageContentTypeKey = PageContentTypeKey,
> = (typeof PageContentType)[Key];

export interface PageContent {
  readonly type: PageContentTypeValue;
  readonly content: any;
}
