import { Legacy_Select } from '@flex-design-system/fx';
import { getUndefinedValueController } from '@flex-private-packages/recruiting-common/base';
import type { ReactNode } from 'react';

import SelectTriggerFieldBox from './SelectTriggerFieldBox';

const UNDEFINED_VALUE = '__UNDEFINED_VALUE__';
const { withUndefinedValue, getValueWithoutUndefined } =
  getUndefinedValueController(UNDEFINED_VALUE);

interface Props<T extends string> {
  isLoading?: boolean;
  disabled?: boolean;
  itemList: T[];
  emptyValueLabel: ReactNode;
  value?: T;
  onValueChange: (value?: T) => void;
  optionItemLabelTransfer?: (value: T) => string;
}

function SelectWithEmptyValue<T extends string>({
  isLoading,
  disabled,
  itemList,
  emptyValueLabel,
  value,
  onValueChange,
  optionItemLabelTransfer = item => item,
}: Props<T>) {
  const handleValueChange = (newValue: T | typeof UNDEFINED_VALUE) => {
    onValueChange(getValueWithoutUndefined(newValue));
  };

  return (
    <Legacy_Select<T | typeof UNDEFINED_VALUE>
      value={withUndefinedValue(value)}
      onValueChange={handleValueChange}
    >
      <Legacy_Select.Trigger asChild disabled={isLoading || disabled}>
        <SelectTriggerFieldBox isLoading={isLoading} disabled={disabled}>
          {value ? optionItemLabelTransfer(value) : emptyValueLabel}
        </SelectTriggerFieldBox>
      </Legacy_Select.Trigger>
      <Legacy_Select.Content>
        <Legacy_Select.Option
          value={UNDEFINED_VALUE}
          textValue={UNDEFINED_VALUE}
        >
          <Legacy_Select.OptionTitle>
            {emptyValueLabel}
          </Legacy_Select.OptionTitle>
        </Legacy_Select.Option>
        {itemList.map(item => (
          <Legacy_Select.Option
            key={item}
            value={item}
            textValue={optionItemLabelTransfer(item)}
          >
            <Legacy_Select.OptionTitle>
              {optionItemLabelTransfer(item)}
            </Legacy_Select.OptionTitle>
          </Legacy_Select.Option>
        ))}
      </Legacy_Select.Content>
    </Legacy_Select>
  );
}

export default SelectWithEmptyValue;
