import type { BlockComponent as BlockComponent_V1 } from '../../block-v1-legacy';
import { BlockComponentType as BlockComponentType_V1 } from '../../block-v1-legacy';
import type { BlockComponent } from '../block-component';
import {
  migrateMediaBlockComponentV1,
  migrateMediaTextMultiBlockComponentV1,
  migrateMediaTextSingleBlockComponentV1,
  migrateTextBlockComponentV1,
  migrateTextButtonCenterBlockComponentV1,
  migrateTextButtonLeftBlockComponentV1,
  migrateTextDoubleBlockComponentV1,
} from '../block-component';

export function migrateBlockComponentV1(
  blockComponent: BlockComponent_V1
): BlockComponent {
  switch (blockComponent.type) {
    case BlockComponentType_V1.Text:
      return migrateTextBlockComponentV1(blockComponent);
    case BlockComponentType_V1.TextButtonLeft:
      return migrateTextButtonLeftBlockComponentV1({
        ...blockComponent,
        type: BlockComponentType_V1.TextButtonLeft,
      });
    case BlockComponentType_V1.TextButtonCenter:
      return migrateTextButtonCenterBlockComponentV1({
        ...blockComponent,
        type: BlockComponentType_V1.TextButtonCenter,
      });
    case BlockComponentType_V1.TextDouble:
      return migrateTextDoubleBlockComponentV1(blockComponent);
    case BlockComponentType_V1.Media:
      return migrateMediaBlockComponentV1(blockComponent);
    case BlockComponentType_V1.MediaTextSingle:
      return migrateMediaTextSingleBlockComponentV1(blockComponent);
    case BlockComponentType_V1.MediaTextMultiple:
      return migrateMediaTextMultiBlockComponentV1(blockComponent);
    case BlockComponentType_V1.MediaTextTriple:
      return migrateMediaTextMultiBlockComponentV1(blockComponent);
  }
}
