import { AlignStyle } from '../../../../base';
import type { TextStyle } from '../block-content-field';

export const JOB_DESCRIPTION_LIST_PAGE_TITLE_MAX_LENGTH = 500;
export const JOB_DESCRIPTION_LIST_PAGE_TITLE_STYLE: TextStyle = {
  align: AlignStyle.Left,
  color: 'grayDarker',
  size: 56,
  mobileSize: 32,
  weight: 700,
  lineHeight: 1.3,
  letterSpacing: '-1.68px',
  mobileLetterSpacing: '-0.96px',
};
