import { useToast } from '@flex-design-system/fx';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { useEventLogger } from '@flex-packages/logger';
import { getFormattedSizeBytes } from '@flex-services/file/base';
import {
  useFileUploadByPreSignedUrlWithState,
  type FileType,
} from '@flex-services/file/hooks';

import { candidateAttachmentRules } from '../../../../base';

interface UseFileDocumentFieldProps {
  file?: FileType;
  onFileChange?: (file?: FileType) => void;
}

export function useFileDocumentField({
  file,
  onFileChange,
}: UseFileDocumentFieldProps) {
  const toast = useToast();
  const { t } = useTranslation();
  const { impression } = useEventLogger();

  const handleFileChange = (nextFile?: FileType) => {
    if (nextFile?.uuid !== file?.uuid || nextFile?.status !== file?.status) {
      onFileChange?.(nextFile);
    }
  };

  const { reset, upload, restrictionInfo } =
    useFileUploadByPreSignedUrlWithState({
      sourceType: 'RecruitingCandidateAttachment',
      initialFiles: file?.fileKey ? [file] : undefined,
      onFilesChange: result => handleFileChange(result[0]),
      onExceedTheMaxSize: () => {
        toast.warning(
          <Translation
            tKey="recruiting.attachment.file.max"
            tOptions={{
              values: {
                sizeLimit: getFormattedSizeBytes(
                  candidateAttachmentRules.maxFileSize
                ),
              },
            }}
          />
        );
      },
    });

  const handleUpload = (files: File[]) => {
    if (files.length === 0) {
      return;
    }
    if (files.length > 1) {
      toast.error(
        t('recruiting.attachment.file.multiple_files', {
          count: 1,
        })
      );
      return;
    }

    const newFile = files[0];
    if (!newFile) {
      toast.error(t('recruiting.attachment.file.unknown_error'));
      return;
    }

    if (!candidateAttachmentRules.allowedMimeTypes.includes(newFile.type)) {
      toast.warning(
        <Translation tKey="recruiting.attachment.file.invalid_type" />
      );
      impression('candidate_unsupported_file_type', {
        fileType: newFile.type,
      });
      return;
    }

    const uuid = newFile.name + newFile.size;

    reset([uuid]);
    upload({ file: newFile, uuid });
  };

  return { handleUpload, restrictionInfo };
}
