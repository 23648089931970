import { TextField } from '@flex-design-system/fx';
import { EmailIcon } from '@flex-design-system/legacy-flex-icons';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { isValidEmail } from '@flex-packages/validator';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

export function EmailField() {
  const { t } = useTranslation();
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();

  return (
    <Controller
      control={control}
      name="email"
      rules={{
        required: t('recruiting.email.edit.helper.required'),
        validate: value => {
          if (!isValidEmail(value)) {
            return t('recruiting.email.edit.helper.validate');
          }

          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            onChange={e => {
              field.onChange(e);
              clearErrors('email');
            }}
            variant="solid"
            id="email"
            type="email"
            required
            label={
              <TextField.Label leftSlot={<EmailIcon />}>
                <Translation tKey="recruiting.email" />
              </TextField.Label>
            }
            placeholder={t('recruiting.email.edit.placeholder')}
            state={error !== undefined ? 'error' : 'default'}
            helperText={
              <TextField.HelperText>{error?.message}</TextField.HelperText>
            }
          />
        );
      }}
    />
  );
}
