import { FileSourceType } from '@flex-apis/recruiting';
import {
  useGetFileSourceTypeRestrictionsRemote,
  useUploadTemporaryFileRemoteMutation,
} from '@flex-apis/recruiting-hooks';
import {
  Avatar,
  Flex,
  IconButton,
  Spinner,
  Typography,
  useToast,
} from '@flex-design-system/fx';
import { FileUpload } from '@flex-design-system/fx-extension';
import {
  PdfIcon,
  XmarkCircleIcon,
} from '@flex-design-system/legacy-flex-icons';
import { styled } from '@flex-design-system/stitches-react';
import { isAxiosErrorWithErrorMessage } from '@flex-packages/error';
import { Translation, useTranslation } from '@flex-packages/i18n';
import { useEventLogger } from '@flex-packages/logger';
import { candidateAttachmentRules } from '@flex-private-packages/recruiting-common/base';
import { getFormattedSizeBytes } from '@flex-services/file/base';
import type { FileType } from '@flex-services/file/hooks';
import type { DragEvent } from 'react';

import { useRendererProps } from '../../../contexts';

const RECRUITING_SOURCE_TYPE = FileSourceType.RecruitingCandidateSelfAttachment;

interface PublicFileDocumentFieldProps {
  file?: FileType;
  onFileChange?: (file?: FileType) => void;
}

export default function PublicFileDocumentField({
  file,
  onFileChange,
}: PublicFileDocumentFieldProps) {
  const { t } = useTranslation();

  const { impression } = useEventLogger();
  const { error } = useToast();
  const { siteDesign } = useRendererProps();
  const { data } = useGetFileSourceTypeRestrictionsRemote(
    { sourceType: RECRUITING_SOURCE_TYPE },
    {
      staleTime: 24 * 60 * 60 * 1000,
    }
  );
  const { mutateAsync: upload } = useUploadTemporaryFileRemoteMutation();

  const handleUpload = async (files: File[]) => {
    const f = files[0];

    if (f === undefined) {
      return;
    }

    if (files.length > 1) {
      error(
        t('recruiting.attachment.file.multiple_files', {
          count: 1,
        })
      );
      return;
    }

    if (!candidateAttachmentRules.allowedMimeTypes.includes(f.type)) {
      error(<Translation tKey="recruiting.attachment.file.invalid_type" />);
      impression('candidate_unsupported_file_type', {
        fileType: f.type,
      });
      return;
    }

    if (
      data?.restriction.maxSizeBytes &&
      f.size > data?.restriction.maxSizeBytes
    ) {
      error(
        <Translation
          tKey="recruiting.attachment.file.max"
          tOptions={{
            values: {
              sizeLimit: getFormattedSizeBytes(data.restriction.maxSizeBytes),
            },
          }}
        />
      );
      return;
    }
    onFileChange?.({
      fileKey: '',
      uuid: f.name + f.size,
      name: f.name,
      status: 'loading',
      file: f,
    });
    const ret = await upload({
      customerIdHash: siteDesign.customerIdHash,
      sourceType: RECRUITING_SOURCE_TYPE,
      multipartFile: f,
    }).catch(e => {
      onFileChange?.();
      if (isAxiosErrorWithErrorMessage(e)) {
        error(e.response.data.message);
      } else {
        throw e;
      }
    });
    if (!ret) {
      return;
    }

    const uuid = f.name + f.size;
    onFileChange?.({
      fileKey: ret.temporaryFileKey,
      uuid,
      name: f.name,
      status: 'idle',
      file: f,
    });
  };
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    handleUpload(Array.from(e.dataTransfer.files));
  };

  return (
    <FileUpload
      accept={candidateAttachmentRules.allowedMimeTypes.join(',')}
      onFilesChange={handleUpload}
    >
      <DropZone onDrop={handleDrop}>
        <Flex
          justify="between"
          align="center"
          css={{
            height: '100%',
            width: '100%',
          }}
        >
          <FileUpload.Trigger
            css={{
              // 상위에서 hover 및 active 스타일을 컨트롤하도록 함
              '&&:is(:hover, :active)::before': {
                opacity: 0,
              },
            }}
          >
            {file ? (
              <Flex align="center" css={{ gap: 10, width: '100%' }}>
                {file.status === 'loading' ? (
                  <Spinner size={22} />
                ) : (
                  <Avatar size="xsmall" color="presetGray">
                    <PdfIcon type="fill" />
                  </Avatar>
                )}
                <Typography
                  weight={500}
                  size={14}
                  color="grayDarker"
                  ellipsis
                  css={{ flex: 1 }}
                >
                  {`${file.name} `}
                  {file.file && (
                    <Typography size={11} color="grayLight">
                      {(file.file.size / 1024 / 1024).toFixed(1)}MB
                    </Typography>
                  )}
                </Typography>
              </Flex>
            ) : (
              <Typography weight={500} size={14} color="grayLight">
                <Translation
                  tKey="recruiting.attachment.file.document.max"
                  tOptions={{
                    values: {
                      sizeLimit:
                        data?.restriction.maxSizeBytes &&
                        getFormattedSizeBytes(data.restriction.maxSizeBytes),
                    },
                  }}
                />
              </Typography>
            )}
          </FileUpload.Trigger>
          {file && (
            <IconButton
              variant="ghost"
              size="small"
              icon={<XmarkCircleIcon type="fill" color="grayLighter" />}
              description={t('recruiting.attachment.file.delete')}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onFileChange?.();
              }}
              css={{
                flexShrink: 0,
              }}
            />
          )}
        </Flex>
      </DropZone>
    </FileUpload>
  );
}

const DropZone = styled(FileUpload.DropZone, {
  height: '100%',
});
