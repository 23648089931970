import { v4 as uuidV4 } from 'uuid';

import type { MediaBlockComponent } from '../../block-v1-legacy';
import type { MediaContent } from '../block-content';
import {
  generateDefaultMediaContent,
  migrateMediaContentV1,
  validateMediaContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  BlockComponentType,
  migrateBlockComponentV1,
  migrateSizeStyleV1,
  SizeStyle,
  validateBlockComponent,
} from './block-component';

const VALID_SIZE_STYLES = [
  SizeStyle.Small,
  SizeStyle.Default,
  SizeStyle.Large,
] as const;

type ValidSizeStyle = (typeof VALID_SIZE_STYLES)[number];

function validateSizeStyle(param: unknown): param is ValidSizeStyle {
  return VALID_SIZE_STYLES.includes(param as ValidSizeStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface GalleryBlockComponentContent {
  readonly medias: Array<MediaContent>; // 1개, 3개, 4개, 6개
}

export function updateGalleryBlockComponentContent(
  origin: GalleryBlockComponent,
  content: unknown
): GalleryBlockComponent;
export function updateGalleryBlockComponentContent(
  origin: GalleryBlockComponent,
  content: GalleryBlockComponentContent
): GalleryBlockComponent;
export function updateGalleryBlockComponentContent(
  origin: GalleryBlockComponent,
  content: unknown
): GalleryBlockComponent {
  if (!validateGalleryBlockComponentContent(content)) {
    throw new Error('[updateGalleryBlockComponentContent]: Invalid content');
  }

  const newContent: GalleryBlockComponentContent = {
    medias: content.medias.map(media => ({ ...media })),
  };

  return { ...origin, ...newContent };
}

export function validateGalleryBlockComponentContent(
  param: unknown
): param is GalleryBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has medias
    'medias' in param &&
    Array.isArray(param.medias) &&
    [1, 3, 4, 6].includes(param.medias.length) &&
    param.medias.every(validateMediaContent)
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface GalleryBlockComponentStyle {
  readonly size: ValidSizeStyle;
  readonly gap: boolean;
}

export function updateGalleryBlockComponentRandomStyle(
  origin: GalleryBlockComponent
): GalleryBlockComponent {
  const newStyle: GalleryBlockComponentStyle = {
    size: getRandomElementFromArray([...VALID_SIZE_STYLES], {
      defaultValue: origin.size,
      except: origin.size,
    }),
    gap: getRandomElementFromArray([true, false], {
      defaultValue: origin.gap,
      except: origin.gap,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateGalleryBlockComponentStyle(
  param: unknown
): param is GalleryBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has size
    'size' in param &&
    validateSizeStyle(param.size) &&
    // has gap
    'gap' in param &&
    typeof param.gap === 'boolean'
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface GalleryBlockComponent
  extends BlockComponent,
    GalleryBlockComponentContent,
    GalleryBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'Gallery'>;
}

export function generateDefaultGalleryBlockComponent(param: {
  mediaCount: 1 | 3 | 4 | 6;
  size: ValidSizeStyle;
  gap: boolean;
}): GalleryBlockComponent {
  const { mediaCount, size, gap } = param;

  return {
    componentType: BlockComponentType.Gallery,
    id: uuidV4(),
    medias: Array.from({ length: mediaCount }, () =>
      generateDefaultMediaContent()
    ),
    size,
    gap,
  };
}

export function migrateMediaBlockComponentV1(
  mediaBlockComponent: MediaBlockComponent
): GalleryBlockComponent {
  const { media, size } = mediaBlockComponent;

  const migratedSize = migrateSizeStyleV1(size);

  return {
    ...migrateBlockComponentV1(mediaBlockComponent),
    componentType: BlockComponentType.Gallery,
    medias: [migrateMediaContentV1(media)],
    size: validateSizeStyle(migratedSize) ? migratedSize : SizeStyle.Default,
    gap: false,
  };
}

export function validateGalleryBlockComponent(
  param: unknown
): param is GalleryBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Gallery &&
    validateGalleryBlockComponentContent(param) &&
    validateGalleryBlockComponentStyle(param)
  );
}
