import { v4 as uuidV4 } from 'uuid';

import type {
  BackgroundContent,
  BackgroundTypeValue,
  LinkContent,
  TextContent,
} from '../block-content';
import {
  generateDefaultBackgroundContent,
  generateDefaultLinkContent,
  generateDefaultTextContent,
  validateBackgroundContent,
  validateLinkContent,
  validateTextContent,
} from '../block-content';
import { getRandomElementFromArray } from '../logics';

import type {
  BlockComponent,
  BlockComponentTypeValue,
} from './block-component';
import {
  AlignStyle,
  BlockComponentType,
  validateBlockComponent,
} from './block-component';

const VALID_ALIGN_STYLES = [AlignStyle.Side, AlignStyle.Center] as const;

type ValidAlignStyle = (typeof VALID_ALIGN_STYLES)[number];

function validateAlginStyle(param: unknown): param is ValidAlignStyle {
  return VALID_ALIGN_STYLES.includes(param as ValidAlignStyle);
}

/* ------------- */
/* Block Content */
/* ------------- */

export interface BannerBlockComponentContent {
  readonly title: TextContent;
  readonly subText: TextContent | null;
  readonly link: LinkContent;
  readonly background: BackgroundContent | null;
}

export function updateBannerBlockComponentContent(
  origin: BannerBlockComponent,
  content: BannerBlockComponentContent
): BannerBlockComponent;
export function updateBannerBlockComponentContent(
  origin: BannerBlockComponent,
  content: unknown
): BannerBlockComponent;
export function updateBannerBlockComponentContent(
  origin: BannerBlockComponent,
  content: unknown
): BannerBlockComponent {
  if (!validateBannerBlockComponentContent(content)) {
    throw new Error('[updateBannerBlockComponentContent]: Invalid content');
  }

  const newContent: BannerBlockComponentContent = {
    title: { ...content.title },
    subText: content.subText && { ...content.subText },
    link: { ...content.link },
    background: content.background && { ...content.background },
  };

  return { ...origin, ...newContent };
}

export function validateBannerBlockComponentContent(
  param: unknown
): param is BannerBlockComponentContent {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has title
    'title' in param &&
    validateTextContent(param.title) &&
    // has subText
    'subText' in param &&
    (param.subText === null || validateTextContent(param.subText)) &&
    // has link
    'link' in param &&
    validateLinkContent(param.link) &&
    // has background
    'background' in param &&
    (param.background === null || validateBackgroundContent(param.background))
  );
}

/* ----------- */
/* Block Style */
/* ----------- */

export interface BannerBlockComponentStyle {
  readonly align: ValidAlignStyle;
}

export function updateBannerBlockComponentRandomStyle(
  origin: BannerBlockComponent
): BannerBlockComponent {
  const newStyle: BannerBlockComponentStyle = {
    align: getRandomElementFromArray([...VALID_ALIGN_STYLES], {
      defaultValue: origin.align,
      except: origin.align,
    }),
  };

  return { ...origin, ...newStyle };
}

export function validateBannerBlockComponentStyle(
  param: unknown
): param is BannerBlockComponentStyle {
  return (
    // is object
    typeof param === 'object' &&
    param !== null &&
    // has align
    'align' in param &&
    validateAlginStyle(param.align)
  );
}

/* --------------- */
/* Block Component */
/* --------------- */

export interface BannerBlockComponent
  extends BlockComponent,
    BannerBlockComponentContent,
    BannerBlockComponentStyle {
  readonly componentType: BlockComponentTypeValue<'Banner'>;
}

export function generateDefaultBannerBlockComponent(param: {
  align: ValidAlignStyle;
  hasSubText: boolean;
  backgroundType?: BackgroundTypeValue;
}): BannerBlockComponent {
  const { align, hasSubText, backgroundType } = param;

  return {
    componentType: BlockComponentType.Banner,
    id: uuidV4(),
    title: generateDefaultTextContent(),
    subText: hasSubText ? generateDefaultTextContent() : null,
    link: generateDefaultLinkContent(),
    background: backgroundType
      ? generateDefaultBackgroundContent({ backgroundType })
      : null,
    align,
  };
}

export function validateBannerBlockComponent(
  param: unknown
): param is BannerBlockComponent {
  return (
    validateBlockComponent(param) &&
    param.componentType === BlockComponentType.Banner &&
    validateBannerBlockComponentContent(param) &&
    validateBannerBlockComponentStyle(param)
  );
}
