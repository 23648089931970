import { values } from '@flex-packages/utils';

import type { BlockContent, BlockContentTypeValue } from './block-content';
import { BlockContentType, validateBlockContent } from './block-content';

export const MediaType = {
  None: 'None',
  Image: 'Image',
  Video: 'Video',
} as const;

export type MediaTypeKey = keyof typeof MediaType;
export type MediaTypeValue<Key extends MediaTypeKey = MediaTypeKey> =
  (typeof MediaType)[Key];

export interface MediaContent {
  readonly contentType: BlockContentTypeValue<'Media'>;

  readonly mediaType: MediaTypeValue;
  readonly src: string;
  readonly alt?: string;
}

export function validateMediaContent(param: unknown): param is BlockContent {
  return (
    // is block content
    validateBlockContent(param) &&
    param.contentType === BlockContentType.Media &&
    // has media type
    'mediaType' in param &&
    values(MediaType).includes(param.mediaType as MediaTypeValue) &&
    // has src
    'src' in param &&
    typeof param.src === 'string'
  );
}
