import { styled } from '@flex-design-system/stitches-react';
import type { ReactNode } from 'react';

interface CandidateBackgroundListItemContentProps {
  children: ReactNode;
}

function CandidateBackgroundListItemContent({
  children,
}: CandidateBackgroundListItemContentProps) {
  return <ItemContentRoot>{children}</ItemContentRoot>;
}

const ItemContentRoot = styled('div', {
  px: '14px',
  py: '16px',
});

export default CandidateBackgroundListItemContent;
