import { CandidateHandicapCase } from '@flex-apis/recruiting';
import type { Legacy_SelectProps } from '@flex-design-system/fx';
import { Legacy_Select } from '@flex-design-system/fx';
import { Translation } from '@flex-packages/i18n';

import { DISABLED_TYPE_CONTENT } from '../../base';

interface DisabledTypeSelectProps
  extends Legacy_SelectProps<CandidateHandicapCase> {
  disabledType?: CandidateHandicapCase;
  onDisabledTypeChange: (newDisabledType: CandidateHandicapCase) => void;
}

const DISABLED_TYPES: Array<CandidateHandicapCase> = [
  CandidateHandicapCase.Unknown,
  CandidateHandicapCase.Normal,
  CandidateHandicapCase.IndustrialAccident,
  CandidateHandicapCase.VeteransDisability,
  CandidateHandicapCase.Etc,
];

function DisabledTypeSelect({
  children,
  disabledType,
  onDisabledTypeChange,
  ...props
}: DisabledTypeSelectProps) {
  return (
    <Legacy_Select<CandidateHandicapCase>
      {...props}
      value={disabledType}
      onValueChange={onDisabledTypeChange}
    >
      <Legacy_Select.Trigger asChild>{children}</Legacy_Select.Trigger>
      <Legacy_Select.Content onCloseAutoFocus={e => e.preventDefault()}>
        {DISABLED_TYPES.map(type => (
          <Legacy_Select.Option key={type} value={type}>
            <Legacy_Select.OptionTitle>
              <Translation tKey={DISABLED_TYPE_CONTENT[type].i18n} />
            </Legacy_Select.OptionTitle>
          </Legacy_Select.Option>
        ))}
      </Legacy_Select.Content>
    </Legacy_Select>
  );
}

export default DisabledTypeSelect;
