import { isNil } from '@flex-packages/utils';

import { isMainPageComponent } from '../../../base';
import type { MainPageComponent, SiteDesignForm } from '../../../base';

export function isUninitializedHomePage(
  pages: SiteDesignForm['pages']
): boolean {
  const homePageIndex = pages.findIndex(isMainPageComponent);
  const homePage = pages[homePageIndex] as MainPageComponent;
  return isNil(homePage.blockComponentPageContent.content);
}

export function isVisiblePageInNavigation(
  page: SiteDesignForm['pages'][number]
): boolean {
  return Boolean(page.title && (page.href || page.urlPagePath) && page.enabled);
}
