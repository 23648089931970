import { Flex } from '@flex-design-system/fx';
import type { StitchesCSS } from '@flex-design-system/stitches-react';
import { styled } from '@flex-design-system/stitches-react';
import { createScopedContext } from '@flex-packages/shared-react';
import { Children, type PropsWithChildren } from 'react';

import type { ColumnsBlockComponent } from '../../../../../../base';
import { AlignStyle } from '../../../../../../base';
import { getMobileStyle } from '../../../../utils';

type ColumnsLayoutType = 'one_item' | 'two_items' | 'three_items';
interface ColumnsBlockComponentLayoutContext {
  columnsLayoutType: ColumnsLayoutType;
}

const ColumnsBlockComponentLayoutContext =
  createScopedContext<ColumnsBlockComponentLayoutContext>({
    rootComponentName: 'ColumnsBlockComponentLayout',
    displayName: 'ColumnsBlockComponentLayout',
  });

interface Props {}

export function ColumnsBlockComponentLayout({
  children,
}: PropsWithChildren<Props>) {
  const childArray = Children.toArray(children);

  return (
    <ColumnsBlockComponentLayoutContext.Provider
      value={{ columnsLayoutType: getColumnsLayoutType(childArray.length) }}
    >
      <ColumnsBlockComponentLayoutRoot>
        {children}
      </ColumnsBlockComponentLayoutRoot>
    </ColumnsBlockComponentLayoutContext.Provider>
  );
}

interface ItemProps {
  align: ColumnsBlockComponent['align'];
  title: JSX.Element | null;
  subText: JSX.Element;
}

function ColumnsBlockComponentLayoutItem({ align, title, subText }: ItemProps) {
  const { columnsLayoutType } =
    ColumnsBlockComponentLayoutContext.useContextValue();

  return (
    <ColumnsBlockComponentLayoutItemRoot
      align="stretch"
      css={getColumnsBlockComponentLayoutItemRootCSS({
        columnsLayoutType,
        align,
      })}
    >
      {title}
      {subText}
    </ColumnsBlockComponentLayoutItemRoot>
  );
}

const ColumnsBlockComponentLayoutItemRoot = styled(Flex, {
  flex: 1,

  ...getMobileStyle({
    '&&&': {
      flexDirection: 'column',
      gap: 24,
    },
  }),
});

const ColumnsBlockComponentLayoutRoot = styled(Flex, {
  '&&': {
    flexDirection: 'row',
    alignItems: 'start',
    gap: 40,
    py: 80,
  },

  ...getMobileStyle({
    '&&': {
      flexDirection: 'column',
      alignItems: 'stretch',
      py: 20,
    },
  }),
});

function getColumnsLayoutType(itemCount: number): ColumnsLayoutType {
  if (itemCount < 2) {
    return 'one_item';
  }
  if (itemCount < 3) {
    return 'two_items';
  }
  return 'three_items';
}

function getColumnsBlockComponentLayoutItemRootCSS(param: {
  columnsLayoutType: ColumnsLayoutType;
  align: ColumnsBlockComponent['align'];
}): StitchesCSS {
  const { columnsLayoutType, align } = param;

  if (columnsLayoutType === 'three_items') {
    return {
      '&&': { flexDirection: 'column', gap: 24 },
    };
  }
  if (columnsLayoutType === 'two_items') {
    return {
      '&&': { flexDirection: 'column', gap: 48 },
    };
  }

  if (align === AlignStyle.Center) {
    return {
      '&&': { flexDirection: 'column', gap: 40 },
    };
  }
  if (align === AlignStyle.Right) {
    return {
      '&&': {
        flexDirection: 'row-reverse',
        gap: 40,
        '& > *:nth-child(1)': {
          flex: 5,
        },
        '& > *:nth-child(2)': {
          flex: 8,
        },
      },
    };
  }
  return {
    '&&': {
      flexDirection: 'row',
      gap: 40,
      '& > *:nth-child(1)': {
        flex: 5,
      },
      '& > *:nth-child(2)': {
        flex: 8,
      },
    },
  };
}

ColumnsBlockComponentLayout.Item = ColumnsBlockComponentLayoutItem;
