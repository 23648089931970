import { TextArea, Typography } from '@flex-design-system/fx';
import { styled } from '@flex-design-system/stitches-react';
import { Translation, useTranslation } from '@flex-packages/i18n';
import type { CandidatePreQuestion } from '@flex-private-packages/recruiting-common/base';
import { Controller, useFormContext } from 'react-hook-form';

import type { JobDescriptionFormState } from '../models';

interface Props {
  preQuestion: CandidatePreQuestion;
  expectedMaxContentLength: number;
  index: number;
}

export function PreQuestionField({
  preQuestion,
  expectedMaxContentLength,
  index,
}: Props) {
  const { t } = useTranslation();
  const { control, clearErrors } = useFormContext<JobDescriptionFormState>();

  const { title, isRequired } = preQuestion;
  const name = `preQuestionAnswers.${index}` as `preQuestionAnswers.${number}`;

  return (
    <Controller
      key={name}
      control={control}
      name={name}
      rules={{
        required: isRequired
          ? t('recruiting.pre_question.answer.edit.helper.required', { title })
          : false,
        maxLength: expectedMaxContentLength,
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextArea
            {...field}
            required={isRequired}
            variant="solid"
            onChange={e => {
              field.onChange(e);
              clearErrors(name);
            }}
            id="name"
            placeholder={t('recruiting.pre_question.answer.edit.placeholder')}
            state={error !== undefined ? 'error' : 'default'}
            label={<Label>{title}</Label>}
            helperText={
              error &&
              (error.type === 'maxLength' ? (
                <TextArea.HelperText>
                  <Translation tKey="recruiting.pre_question.answer.edit.helper.mex_length" />
                </TextArea.HelperText>
              ) : (
                <TextArea.HelperText>{error.message}</TextArea.HelperText>
              ))
            }
          >
            <WordCountArea>
              <Typography
                color={error ? 'orange' : 'blackAlpha032'}
                size={12}
                weight={400}
              >
                ({field.value?.length ?? 0}/{expectedMaxContentLength})
              </Typography>
            </WordCountArea>
          </TextArea>
        );
      }}
    />
  );
}

const WordCountArea = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: 13,
});

const Label = styled(TextArea.Label_Legacy, {
  fontSize: 16,
  fontWeight: 600,
  color: 'unset',
  marginBottom: 18,
  lineHeight: 1.5,
});
